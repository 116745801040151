import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { GetRecords } from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import DisplayTable from "../../Inputs/DisplayTables";
import { ICompanyCurrency } from "./currency.interface";
import { DEFAULT_DATE, MISSING_DATE } from "./comapany.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
    table: {
      minWidth: 375,
    },
  })
);

interface CompanyCurrencyTableProps {
  token: string;
  setToken: Function;
}

export default function CompanyCurrencyTable(props: CompanyCurrencyTableProps) {
  const classes = useStyles();
  const [aircraftCurrency, setAircraftCurrency] = useState([]);
  const getCurrencyReport = () => {
    const response = (res: any) => setAircraftCurrency(res.result);
    GetRecords(
      Endpoints.companyCalculator.aircraftCurrency,
      props.token,
      response,
      props.setToken
    );
  };
  const rowHeaders = [
    "Name",
    "Last Flight",
    "UnCurrent Date",
    "Days Remaining",
  ];
  useEffect(() => {
    getCurrencyReport();
  }, []);

  const displayTable = () => {
    if (aircraftCurrency.length > 0) {
      return aircraftCurrency?.map((row: ICompanyCurrency, index: number) => (
        <TableRow key={"Flight" + index}>
          <TableCell component="th" scope="row">
            {row?.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.lastFlight === DEFAULT_DATE ? MISSING_DATE : row.lastFlight}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.lastFlight === DEFAULT_DATE
              ? MISSING_DATE
              : row.flightUncurrentDate}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.daysRemaining}
          </TableCell>
        </TableRow>
      ));
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4">Aircraft Currency</Typography>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {rowHeaders.map((header, index) => (
                  <TableCell key={"header" + index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{aircraftCurrency ? displayTable() : <></>}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}
