import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  GetRecords,
  LocalStorageInformation,
} from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import {
  ISemiAnnualTotals,
  ICurrency,
  ICurrencyNVG,
  ICurrencyNVS,
  PersonalJSON,
} from "../../SystemSettings/ProjectInterfaces";
import DisplayTable from "../../Inputs/DisplayTables";

export interface TotalsProps {
  token: string;
  setToken: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);

const SemiAnnualTotals: React.SFC<TotalsProps> = (props) => {
  const classes = useStyles();

  // Aircraft Totals
  const [semiAnnualTotals, setSemiAnnualTotals] = useState<ISemiAnnualTotals>();
  const [requirementsAllAC, setRequirementsAllAC] = useState<any[]>();
  const [requirementHeadersAllAC, setRequirementHeadersAllAC] =
    useState<any[]>();
  // Apache Specific Settings
  const [requirementsApache, setRequirementsApache] = useState<any[]>();
  const [requirementHeadersApache, setRequirementHeadersApache] =
    useState<any[]>();
  // Aircraft Currency
  const [currency, setCurrency] = useState<ICurrency[]>();
  const [currencyHeaders, setCurrencyHeaders] = useState<any[]>();
  const [currencyTableData, setCurrencyTableData] = useState<string[][]>();
  // NVG Currency
  const [nvgCurrency, setNVGcurrency] = useState<ICurrencyNVG[]>();
  const [nvgCurrencyHeaders, setNvgCurrencyHeaders] = useState<any[]>();
  const [nvgCurrencyTableData, setNvgCurrencyTableData] =
    useState<string[][]>();

  //NVS Currency
  const [nvsCurrency, setNVScurrency] = useState<ICurrencyNVS>();
  const [nvsCurrencyHeaders, setNVSCurrencyHeaders] = useState<any[]>();
  const [nvsCurrencyTableData, setNVSCurrencyTableData] =
    useState<string[][]>();
  const [nvsMessageToUser, setNvsMessageToUser] = useState("");

  const [isApachePilot, setIsApachePilot] = useState(false);

  function getTotals() {
    GetRecords(
      Endpoints.totals.semiannuals,
      props.token,
      setSemiAnnualTotals,
      props.setToken
    );
  }

  function getCurrency() {
    GetRecords(
      Endpoints.totals.currency,
      props.token,
      setCurrency,
      props.setToken
    );
  }

  function getNVGCurrency() {
    GetRecords(
      Endpoints.totals.nvgcurrency,
      props.token,
      setNVGcurrency,
      props.setToken
    );
  }

  function getNVSCurrency() {
    GetRecords(
      Endpoints.totals.nvscurrency,
      props.token,
      nvsResponse,
      props.setToken
    );
    function nvsResponse(response: any) {
      if (response.hasOwnProperty("message")) {
      } else {
        setNVScurrency(response);
      }
    }
  }

  function apachedSpecificInformation() {
    return (
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h6">Apache Specific Information:</Typography>

        <p className={classes.paragraph}>Listed below is your NVS currency.</p>
        {nvsCurrencyHeaders !== undefined &&
        nvsCurrencyTableData !== undefined ? (
          <div>
            <DisplayTable
              headers={nvsCurrencyHeaders}
              data={nvsCurrencyTableData}
            />
            <p>{nvsMessageToUser}</p>
          </div>
        ) : (
          <></>
        )}
      </Paper>
    );
  }

  function apacheSpecificRequirementSection() {
    return (
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h6">Apache Specific Requirements:</Typography>

        <p className={classes.paragraph}>
          This section is for pilots of the Apache helicopter. If the seat or
          mode of flight requirements are off please update your personal
          profile and come back to this page.
        </p>
        {requirementsApache !== undefined &&
        requirementHeadersApache !== undefined ? (
          <DisplayTable
            headers={requirementHeadersApache}
            data={requirementsApache}
          />
        ) : (
          <></>
        )}
      </Paper>
    );
  }

  function displayRequired() {
    const hoursFlownAllAC = [
      "Hours Flown",
      semiAnnualTotals?.totals.FlightTotals,
      semiAnnualTotals?.totals.SimulatorTotals,
      semiAnnualTotals?.totals.WeatherTotals,
      semiAnnualTotals?.totals.NightTotals,
      semiAnnualTotals?.totals.NVGTotals,
    ];
    const requiredHours = [
      "Required Hours",
      semiAnnualTotals?.requiredTotals.requiredTotalHoursAirframe,
      semiAnnualTotals?.requiredTotals.requiredTotalHoursSimulator,
      semiAnnualTotals?.requiredTotals.requiredWeather,
      semiAnnualTotals?.requiredTotals.requiredNight,
      semiAnnualTotals?.requiredTotals.requiredNVG,
    ];

    const hoursRemaining = [
      "Hours Remaining",

      semiAnnualTotals?.remainingTotalsAircraft.remainingTotalHours,
      semiAnnualTotals?.remainingSimulator.remainingTotal,
      semiAnnualTotals?.remainingMiscTotals.remainingWeather,
      semiAnnualTotals?.remainingMiscTotals.remainingNight,
      semiAnnualTotals?.remainingMiscTotals.remainingNVG,
    ];
    const requirementArrayAllAC = [
      requiredHours,
      hoursFlownAllAC,
      hoursRemaining,
    ];

    const requirementHeadersAllACArray = [
      "",
      "Aircraft Hours",
      "Sim Hours",
      "Wx Hours",
      "Night Hours",
      "NVG Hours",
    ];

    setRequirementHeadersAllAC(requirementHeadersAllACArray);
    setRequirementsAllAC(requirementArrayAllAC);
    // setRequiredFlownHeaders(hoursFlownHeaders);
  }
  function displayCurrency() {
    const headers = [
      "Model",
      "Last Flight",
      "UnCurrent Date",
      "< 30 Days",
      "Expired",
    ];

    const currencyData =
      currency !== undefined
        ? currency.map((flight: ICurrency, index) => {
            return [
              flight.Model,
              flight.LastFlight,
              flight.UnCurrentDate,
              flight.LessThanThiryDays ? "Yes" : "No",
              flight.isExpired ? "Yes" : "No",
            ];
          })
        : [[]];
    setCurrencyHeaders(headers);
    setCurrencyTableData(currencyData);
  }
  function displayApacheSpecific() {
    const required = [
      "Hours Required",
      semiAnnualTotals?.requiredTotals.requiredFrontSeatAirframe,
      semiAnnualTotals?.requiredTotals.requiredBackSeatAirframe,
      semiAnnualTotals?.requiredTotals.requiredFrontSeatSimulator,
      semiAnnualTotals?.requiredTotals.requiredBackSeatSimulator,
      semiAnnualTotals?.requiredTotals.requiredNVS,
      semiAnnualTotals?.requiredTotals.requiredNVD,
    ];
    const flown = [
      "Hours Flown",
      semiAnnualTotals?.totals.FrontSeatFlightTotals,
      semiAnnualTotals?.totals.BackSeatFlightTotals,
      semiAnnualTotals?.totals.SimulatorFrontSeatTotals,
      semiAnnualTotals?.totals.SimulatorBackSeatTotals,
      semiAnnualTotals?.totals.NVSTotals,
      semiAnnualTotals?.totals.NVGandNVSTotals,
    ];

    const remaining = [
      "Hours Remaining",

      semiAnnualTotals?.remainingTotalsAircraft.remainingFrontSeat,
      semiAnnualTotals?.remainingTotalsAircraft.remainingBackSeat,
      semiAnnualTotals?.remainingSimulator.remainingFrontSeat,
      semiAnnualTotals?.remainingSimulator.remainingBackSeat,
      semiAnnualTotals?.remainingMiscTotals.remainingNVS,
      semiAnnualTotals?.remainingMiscTotals.remainingNVD,
    ];
    const apacheSpecificRequirements = [required, flown, remaining];
    const apacheSpecificRequirementsHeaders = [
      "",
      "FS AC",
      "BS AC",
      "FS Sim",
      "BS Sim",
      "NVS",
      "NVD",
    ];

    setRequirementHeadersApache(apacheSpecificRequirementsHeaders);
    setRequirementsApache(apacheSpecificRequirements);
  }
  // NVG Table Data
  function displayNVG() {
    const headers = ["Last Flight", "UnCurrent Date", "< 30 Days", "Expired"];

    const currencyData =
      nvgCurrency !== undefined && nvgCurrency[0].LastFlight != undefined
        ? nvgCurrency.map((flight: ICurrencyNVG) => {
            return [
              flight.LastFlight,
              flight.UnCurrentDate,
              flight.LessThanThiryDays ? "Yes" : "No",
              flight.isExpired ? "Yes" : "No",
            ];
          })
        : [["no data", "no data", "no data", "no data"]];
    setNvgCurrencyHeaders(headers);
    setNvgCurrencyTableData(currencyData);
  }
  //NVS Table Data
  function displayNVS() {
    const headers = [
      "",
      "Last Flight",
      "UnCurrent Date",
      "< 30 Days",
      "Expired",
    ];

    if (nvsCurrency !== undefined) {
      if (nvsCurrency.MessageToUser === "Not Apache Helicopter") {
        return;
      }
      let currencyData = [
        [
          "Aircraft",
          nvsCurrency.Aircraft.LastFlight,
          nvsCurrency.Aircraft.UnCurrentDate,
          nvsCurrency.Aircraft.LessThanThiryDays ? "Yes" : "No",
          nvsCurrency.Aircraft.isExpired ? "Yes" : "No",
        ],
        [
          "Simulator",
          nvsCurrency.Simulator.LastFlight,
          nvsCurrency.Simulator.UnCurrentDate,
          nvsCurrency.Simulator.LessThanThiryDays ? "Yes" : "No",
          nvsCurrency.Simulator.isExpired ? "Yes" : "No",
        ],
      ];
      setNVSCurrencyTableData(currencyData);
      setNvsMessageToUser(nvsCurrency.MessageToUser);
    }
    setNVSCurrencyHeaders(headers);
  }

  useEffect(() => {
    displayRequired();
    displayApacheSpecific();
  }, [semiAnnualTotals]);

  useEffect(() => {
    displayCurrency();
  }, [currency]);

  useEffect(() => {
    displayNVG();
  }, [nvgCurrency]);
  useEffect(() => {
    displayNVS();
  }, [nvsCurrency]);
  useEffect(() => {
    getTotals();
    getCurrency();
    getNVGCurrency();

    getNVSCurrency();

    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile informaiton in the local stroage
    if (flightPageInfo.personalProfile) {
      const profile = JSON.parse(
        flightPageInfo.personalProfile
      ) as PersonalJSON;
      setIsApachePilot(profile?.primary_ac === 1 ? true : false);
    }
  }, []);
  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">
              Aircraft and Simulator Requirements
            </Typography>

            <p className={classes.paragraph}>
              Listed are the required hours that you are responsible for flying
              based on your profile setup.
            </p>
            {requirementsAllAC != undefined &&
            requirementHeadersAllAC != undefined ? (
              <DisplayTable
                headers={requirementHeadersAllAC}
                data={requirementsAllAC}
              />
            ) : (
              <></>
            )}
          </Paper>
          {nvsCurrency != undefined && isApachePilot ? (
            apacheSpecificRequirementSection()
          ) : (
            <></>
          )}

          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">About</Typography>

            <p className={classes.paragraph}>
              All data that you see on this page is sourced from your profile
              setup page. To properly account for your requirements please make
              sure that those are up to date. Also note any proration
              requirements in the profile section.
            </p>
            <ul>
              <li>
                Dates Range - comes from your semiannual start and end dates in
                the profile section
              </li>
              <li>
                Primary Aircraft - you selected the primary aircraft in the
                profile section
              </li>
              <li>Aircraft Currencies are pulled from AR95-1*</li>
              <li>System Resets (NVG or NVS) are pulled from ATM*</li>
            </ul>
            <h6>*If any of these values need changed please contact us. </h6>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Currency Information:</Typography>

            <p className={classes.paragraph}>
              Listed below is your aircraft currency.
            </p>
            {currencyHeaders !== undefined &&
            currencyTableData !== undefined ? (
              <DisplayTable
                headers={currencyHeaders}
                data={currencyTableData}
              />
            ) : (
              <></>
            )}
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">NVG Currency Information:</Typography>

            <p className={classes.paragraph}>
              Listed below is your NVG currency.
            </p>
            {nvgCurrencyHeaders !== undefined &&
            nvgCurrencyTableData !== undefined ? (
              <DisplayTable
                headers={nvgCurrencyHeaders}
                data={nvgCurrencyTableData}
              />
            ) : (
              <></>
            )}
          </Paper>
          {nvsCurrency && isApachePilot ? apachedSpecificInformation() : <></>}
        </Grid>
      </Grid>
    </div>
  );
};

export default SemiAnnualTotals;
