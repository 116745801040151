import React from "react";
import ReactDOM from "react-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PUBLIC_SHAREABLE_KEY } from "./Helpers/environment";
import { BrowserRouter as Router } from "react-router-dom";
const stripePromise = loadStripe(PUBLIC_SHAREABLE_KEY);
ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Router>
        <App />
      </Router>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
