import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { FltInputField, FltInputFieldPassword } from "../../Inputs/InputFields";
import { useState } from "react";
import ClickButton from "../../Inputs/PageButtons";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import { UpdateRecord } from "../../SystemSettings/CommonLib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },
    appBar: {
      position: "relative",
      background: theme.palette.secondary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);
type Anchor = "top" | "left" | "bottom" | "right";
export interface PasswordSectionProps {
  token: string;
}

export default function PasswordDrawer(props: PasswordSectionProps) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const displayDrawer = (anchor: Anchor) => {
    function onSubmit() {
      if (password != password2) {
        alert("Passwords do not match");
        return;
      }
      const data = {
        data: { Passphrase: password },
      };

      UpdateRecord(
        Endpoints.tables.personalProfile.password,
        props.token,
        data,
        updateComplete
      );

      function updateComplete(json: any) {
        setState({ ...state, ["top"]: false });
      }
    }
    return (
      <div role="presentation">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleDrawer("top", false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              UPDATE PASSWORD
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          justify="space-around"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Grid item xs={8} sm={6}>
            <Typography> Please enter your new password:</Typography>
            <FltInputFieldPassword
              fieldName="Enter New Password"
              valueSetter={setPassword}
              currentValue={password}
              fullWidth={true}
            />
            <Typography> Please re-enter your new password:</Typography>
            <FltInputFieldPassword
              fieldName="Re-Enter New Password"
              valueSetter={setPassword2}
              currentValue={password2}
              fullWidth={true}
            />
            <br />
            <ClickButton clickFunction={onSubmit} title="Save" />{" "}
            <ClickButton
              clickFunction={toggleDrawer("top", false)}
              title="Cancel/Close"
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {(["top"] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>Change Password</Button>
          <SwipeableDrawer
            anchor={"top"}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {displayDrawer(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
