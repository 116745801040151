import { useEffect, useState } from "react";

import React from "react";
import {
  isJwtExpired,
  LocalStorageInformation,
} from "../../SystemSettings/CommonLib";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import UAVCurrencyReport from "./UAVReports/UAVCurrencyReport";
import UAVSemiAnnualReport from "./UAVReports/UAVSemiAnnualReport";
import { PersonalJSON } from "../../SystemSettings/ProjectInterfaces";
import { isUAVAircraft } from "../../../constants/aircraft-list";

type Props = {
  token: string;
  setToken: (token: string) => void;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "auto",
      minHeight: "100vh",
      overflow: "auto",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function ReportsPage({ token, setToken }: Props) {
  const classes = useStyles();
  const [primaryAircraft, setPrimaryAircraft] = useState<number>(0);

  useEffect(() => {
    if (token === "") return;
    if (isJwtExpired(token)) {
      setToken("");
      return;
    }
  });

  useEffect(() => {
    if (token === "") return;
    const lsi = new LocalStorageInformation(token);
    if (lsi.personalProfile) {
      const personalProfile: PersonalJSON = JSON.parse(lsi.personalProfile);
      setPrimaryAircraft(personalProfile.primary_ac);
    }
  }, [token]);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ borderRadius: "0", backgroundColor: "lightgray" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Reports
            </Typography>
            <p>
              Below are some common reports that pilots would need to view.{" "}
            </p>
          </Grid>
        </Grid>
      </Paper>

      {/* UAV Reporting Only */}
      {isUAVAircraft(primaryAircraft) && (
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <UAVCurrencyReport token={token} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <UAVSemiAnnualReport token={token} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
