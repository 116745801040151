import React from "react";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
} from "@material-ui/core";
import PilotInformation from "./PilotProfile/PilotInformationMain";
import FlightInformation from "./FlightBook/FlightInformationMain";
import ProjectUpdate from "./ProjectUpdate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
  })
);

export default function Docs(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
      <Typography variant="h6" component="span">
        Army Flight Logbook's Documentation
      </Typography>
      <PilotInformation />
      <FlightInformation />
      <ProjectUpdate />
      {/* </Paper> */}
    </div>
  );
}
