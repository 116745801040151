import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PAGE_INFORMATION } from "../UserPages/FlightLogbook/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "10px",
      flexGrow: 1,
      color: theme.palette.text.secondary,
      background: "white",
    },
    card: {
      maxWidth: 345,
    },
    media: {
      maxWidth: "100%",
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface UploadProps {}

export default function Upload(props: UploadProps) {
  // const [fullName, setFullName] = React.useState<string>('Jerome Flaherty');
  const classes = useStyles();

  useEffect(() => {
    document.title = PAGE_INFORMATION.UPLOAD_PAGE.title;
    let metaDescription = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    metaDescription.content = PAGE_INFORMATION.UPLOAD_PAGE.description;
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper} elevation={3}>
            {/* <Photo photo={RadioPhoto} /> */}
            <iframe
              //   scrolling="no"
              title="contact-form"
              style={{ border: "none", width: "100%" }}
              height="1250px"
              src="https://opnform.com/forms/cafers-upload-portal-xkqabk"
            ></iframe>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width="100%" />;
};
