import React, { useState, useEffect } from "react";
import Hidden from "@material-ui/core/Hidden";

import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Fab,
  Button,
} from "@material-ui/core";
import {
  PersonalJSON,
  ISimulatorFlight,
  IFlightSearchDateRequest,
  ITrackingPreferences,
} from "../../SystemSettings/ProjectInterfaces";
import {
  LocalStorageInformation,
  GetRecords,
  PostRequest,
  isJwtExpired,
} from "../../SystemSettings/CommonLib";
import { Endpoints, Aircraft } from "../../SystemSettings/SystemSettings";
import ClickButton from "../../Inputs/PageButtons";
import DatePicker from "../../Inputs/DateFields";
import PieChart from "../../Inputs/PieChart";
import SimulatorTable from "./SimulatorTableView";

import AddIcon from "@material-ui/icons/Add";
import AddSimulatorLogModal from "./AddSimLogModal";
import SimulatorCards from "./SimulatorCards";
import { CSVLink } from "react-csv";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SimulatorPDFDownloader from "./SimulatorPDFDownloader";
import ExtraReportingSection from "../FlightLogbook/currency-section/ExtraReportingSection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface SimulatorPageProps {
  token: string;
  setToken: any;
}

export default function SimulatorPage(props: SimulatorPageProps) {
  const chartInfo = `Data represented in the chart is gathered from your search query (get
    all flights, get current semiannual, or search based on date)`;
  const classes = useStyles();
  const [endSearchDate, setEndSearchDate] = useState("");
  const [flightData, setFlightData] = useState([]);
  const [isEditFlight, setisEditFlight] = useState(false);
  const [personalProfile, setPersonalProfile] = useState<PersonalJSON>();
  const [showAddFlightModel, setShowAddFlightModel] = useState(false);
  const [startSearchDate, setStartSearchDate] = useState("");
  const [totalTimeData, setTotalTimeData] = useState<any>();
  const [resetTablePageNumber, setResetTablePageNumber] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [trackingPreferences, setTrackingPreferences] =
    useState<ITrackingPreferences>();
  useEffect(() => {
    if (isJwtExpired(props.token)) {
      props.setToken("");
      return;
    }

    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile information in the local storage
    if (flightPageInfo.personalProfile) {
      let personalProfile: PersonalJSON = JSON.parse(
        flightPageInfo.personalProfile
      );
      setData(personalProfile);
      if (personalProfile.tracking_preferences) {
        setTrackingPreferences(
          JSON.parse(personalProfile.tracking_preferences)
        );
      }
    } else {
      flightPageInfo.updatingPersonalProfile(setData, props.setToken);
    }
    function setData(json: any) {
      setPersonalProfile(json);
      if (json.tracking_preferences) {
        setTrackingPreferences(JSON.parse(json.tracking_preferences));
      }
    }
    getAllFlights();
  }, []);
  // Pie Chart Data Creator - runs every time flightdata changes in the table.
  useEffect(() => {
    if (!flightData) {
      return;
    }
    let totalDayHours = 0;
    let totalNightHours = 0;
    let totalNvgHours = 0;
    let totalNvsHours = 0;
    let totalHoodHours = 0;
    let totalWeatherHours = 0;

    flightData.map((record: ISimulatorFlight) => {
      totalDayHours += +record.Day;
      totalNightHours += +record.nite;
      totalNvgHours += +record.lctNVG;
      totalNvsHours += +record.lctNVS;
      totalHoodHours += +record.lctHood;
      totalWeatherHours += +record.lctWeather;
    });
    function fixRounding(number: number) {
      return Math.floor(number * 10) / 10;
    }
    console.log(totalDayHours);
    setTotalTimeData({
      Day: fixRounding(totalDayHours),
      Night: fixRounding(totalNightHours),
      NVG: fixRounding(totalNvgHours),
      NVS: fixRounding(totalNvsHours),
      Hood: fixRounding(totalHoodHours),
      Wx: fixRounding(totalWeatherHours),
    });
  }, [flightData]);
  // Fetching all Flights

  function getAllFlights() {
    GetRecords(
      Endpoints.tables.simulator.getall,
      props.token,
      setFlightData,
      props.setToken
    );
    setResetTablePageNumber(true);
  }
  //Fetching based on start and end date
  function dateSearchFilter() {
    if (!startSearchDate && !endSearchDate) {
      return;
    }
    const data: IFlightSearchDateRequest = {
      data: {
        startDate: startSearchDate,
        endDate: endSearchDate,
      },
    };
    PostRequest(
      Endpoints.tables.simulator.searchdate,
      props.token,
      data,
      setFlightData
    );
    setResetTablePageNumber(true);
  }
  // Current Semiannual Period
  function getCurrentSemiAnnual() {
    GetRecords(
      Endpoints.tables.simulator.getcurrent,
      props.token,
      setFlightData,
      props.setToken
    );
    setResetTablePageNumber(true);
  }

  const refreshTableGrid = {
    allFlights: getAllFlights,
  };

  function closeAddFlightModel() {
    setShowAddFlightModel(false);
  }

  function openAddFlightModel() {
    setShowAddFlightModel(true);
  }
  const headers = [
    { label: "ID", key: "Flightid" },
    { label: "Date", key: "lctDate" },
    { label: "Seat", key: "lctSeat" },
    { label: "Day", key: "Day" },
    { label: "Night", key: "nite" },
    { label: "NVG", key: "lctNVG" },
    { label: "NVS", key: "lctNVS" },
    { label: "Hood", key: "lctHood" },
    { label: "Weather", key: "lctWeather" },
    { label: "Remarks", key: "lctRemarks" },
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12}>
          <Typography variant="h4" style={{ padding: "15px" }}>
            Simulator Logbook:{" "}
            {Aircraft != undefined && personalProfile != undefined ? (
              Aircraft.filter((x) => x.value == personalProfile?.primary_ac)[0]
                .airframe_name
            ) : (
              <></>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Filter Options </Typography>

            <p className={classes.paragraph}>
              Choose Dates youself or choose the presets buttons. Easily swap
              between Current Semiannual or all flights.
            </p>
            <ClickButton
              title="Get All Flights"
              clickFunction={() => getAllFlights()}
              fullWidth={true}
            />
            <ClickButton
              title="Get Current SemiAnnual"
              clickFunction={() => getCurrentSemiAnnual()}
              fullWidth={true}
            />
            <p className={classes.paragraph}>
              Choose a Start Date and an End Date to assist with your search.
            </p>
            <DatePicker
              dateValue={startSearchDate}
              title="Start Date"
              setDateValue={setStartSearchDate}
            />
            <DatePicker
              dateValue={endSearchDate}
              title="Start Date"
              setDateValue={setEndSearchDate}
            />
            <ClickButton
              title="Search Based On Date"
              clickFunction={() => dateSearchFilter()}
              fullWidth={true}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Simulator Flight Data </Typography>
            {totalTimeData ? (
              <div className="chart-container">
                <PieChart dataToDisplay={totalTimeData} chartInfo={chartInfo} />
              </div>
            ) : (
              <></>
            )}
            <p>{chartInfo}</p>
            {/* Extra Currency */}

            {trackingPreferences ? (
              <ExtraReportingSection
                trackingPreferences={trackingPreferences}
                flightRecords={flightData}
                pageSource="simulator"
              />
            ) : (
              <></>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          <Paper className={classes.paper} elevation={3}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Fab size="small" color="secondary" aria-label="add">
                <AddIcon onClick={() => openAddFlightModel()} />
              </Fab>
            </Grid>
            <Typography variant="h6">Simulator Logbook </Typography>

            <p className={classes.paragraph}>
              This is the Simulator section where you can add, view, update, or
              delete your simulator flights. Click the view more details about
              the flight.
            </p>
            <Hidden only={["sm", "xs"]}>
              <SimulatorTable
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                resetTablePageNumber={resetTablePageNumber}
                setResetTablePageNumber={setResetTablePageNumber}
                setToken={props.setToken}
              />
              <CSVLink
                filename="Simulator Logbook Download"
                data={flightData}
                headers={headers}
              >
                <Button
                  variant="contained"
                  color={"primary"}
                  style={{ margin: "5px" }}
                  fullWidth={true}
                >
                  Download Report ({flightData.length})
                </Button>
              </CSVLink>
              {isDownloadReady ? (
                <PDFDownloadLink
                  document={<SimulatorPDFDownloader flightData={flightData} />}
                  fileName="ArmyFlightLogbook-Report-Simulator.pdf"
                  style={{ textAlign: "center" }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download now!"
                  }
                </PDFDownloadLink>
              ) : (
                <ClickButton
                  clickFunction={() => setIsDownloadReady(true)}
                  title={"Generate PDF"}
                  fullWidth={true}
                />
              )}
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <SimulatorCards
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                setToken={props.setToken}
              />
            </Hidden>
          </Paper>
        </Grid>
      </Grid>
      <AddSimulatorLogModal
        token={props.token}
        modelOpen={showAddFlightModel}
        setModelOpen={closeAddFlightModel}
        isEditFlight={false}
        setToken={props.setToken}
        setFlightData={flightData}
      />
    </div>
  );
}
