import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Snackbar,
  Button,
} from "@material-ui/core";
import CustomAccordian from "../../Inputs/CustomAccordian";
import {
  GetRecords,
  CreateRecord,
  UpdateRecord,
  isJwtExpired,
} from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import CompanyAdminTable from "./CompanyAdminTable";
import { FltInputField } from "../../Inputs/InputFields";
import SimpleSelect from "../../Inputs/SelectOptions";
import ClickButton from "../../Inputs/PageButtons";
import { Alert } from "@material-ui/lab";
import { accessLevel, companyAdminFaqData } from "./constants";

export interface Slots {
  slots?: number;
}
export interface CreateRecord {
  data: AviatorTable;
}

export interface AviatorTable {
  Name: string;
  UserAccess: string;
  Username: string;
  Email: string;
}
export interface Aviator {
  id: number;
  name: string;
  userAccess: string;
  username: string;
}

export interface GetAllCompanyAviatorsResponse {
  slots: Slots;
  aviators: Aviator[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: "white",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
  })
);
export interface CompanyAdminProps {
  token: string;
  setToken: any;
}

export default function CompanyAdmin(props: CompanyAdminProps) {
  const classes = useStyles();
  const companyTableHeaders = ["id", "Name", "Username", "User Type"];
  const data = [];

  const [companyTableData, setCompanyTableData] = useState<Aviator[]>();
  const [availableSlots, setAvailableSlots] = useState<Slots>();
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userAccessSelected, setUserAccessSelected] = useState("3");
  const [editUserSelected, setEditUserSelected] = useState(false);
  const [editUserId, setEditUserId] = useState<number>();
  const [getAviatorResponse, setgetAviatorResponse] =
    useState<GetAllCompanyAviatorsResponse>();

  const [onSaveAlertVisible, setOnSaveAlertVisible] = useState(false);
  const [onErrorAlertVisible, setOnErrorAlertVisible] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState("");

  useEffect(() => {
    if (errorAlertText != "") {
      setOnErrorAlertVisible(true);
    } else {
      setOnErrorAlertVisible(false);
    }
  }, [errorAlertText]);

  // Resetting the Form
  function resetStateForm() {
    setUserEmail("");
    setUserName("");
    setUserAccessSelected("3");
    setEditUserSelected(false);
  }

  // Refresh List
  function refreshList() {
    GetRecords(
      Endpoints.tables.companyAdmin.getall,
      props.token,
      setgetAviatorResponse,
      props.setToken
    );
    setOnSaveAlertVisible(true);
    resetStateForm();
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOnSaveAlertVisible(false);
    setOnErrorAlertVisible(false);
  };

  // Form Validator
  function formValidate(): boolean {
    console.log(userName, userEmail);
    if (userName == "") {
      setErrorAlertText("Name is blank");
      setTimeout(() => {
        setErrorAlertText("");
        setOnErrorAlertVisible(false);
      }, 3000);
      return false;
    }

    if (userEmail == "") {
      setErrorAlertText("Email is blank");
      setTimeout(() => {
        setErrorAlertText("");
        setOnErrorAlertVisible(false);
      }, 3000);

      return false;
    }

    if (userEmail.indexOf("@") == -1) {
      setErrorAlertText("Not a valid email");
      setTimeout(() => {
        setErrorAlertText("");
        setOnErrorAlertVisible(false);
      }, 3000);

      return false;
    }

    if (userAccessSelected == "") {
      setErrorAlertText("Select user type");
      return false;
    }

    return true;
  }

  // Update User
  function updateAviator() {
    const isFormValidated = formValidate();
    if (!isFormValidated) {
      return;
    }
    const data: CreateRecord = {
      data: {
        UserAccess: userAccessSelected,
        Email: userEmail,
        Username: userEmail,
        Name: userName,
      },
    };

    UpdateRecord(
      Endpoints.tables.companyAdmin.update,
      props.token,
      data,
      refreshList,
      editUserId
    );
  }
  // Adding New Aviator to Company
  function addAviator() {
    const isFormValidated = formValidate();
    if (!isFormValidated) {
      return;
    }
    const data: CreateRecord = {
      data: {
        UserAccess: userAccessSelected,
        Email: userEmail,
        Username: userEmail,
        Name: userName,
      },
    };
    CreateRecord(
      Endpoints.tables.companyAdmin.add,
      props.token,
      data,
      refreshList
    );
  }

  useEffect(() => {
    if (isJwtExpired(props.token)) {
      props.setToken("");
      return;
    }

    GetRecords(
      Endpoints.tables.companyAdmin.authorized,
      props.token,
      isAuthenticated,
      props.setToken
    );

    function isAuthenticated(response: { message: boolean }) {
      if (response.message) {
        GetRecords(
          Endpoints.tables.companyAdmin.getall,
          props.token,
          setgetAviatorResponse,
          props.setToken
        );
      }
    }
  }, []);

  useEffect(() => {
    if (getAviatorResponse != undefined) {
      if (getAviatorResponse.slots != null) {
        setAvailableSlots(getAviatorResponse.slots);
      }
      setCompanyTableData(getAviatorResponse.aviators);
    }
  }, [getAviatorResponse]);

  function addOrDisableAdd() {
    if (availableSlots != undefined && companyTableData != undefined) {
      if (Number(availableSlots) - companyTableData?.length <= 0) {
        return (
          <Button
            variant="contained"
            disabled
            color="secondary"
            style={{ margin: "5px" }}
          >
            Add
          </Button>
        );
      }
      return <ClickButton title={"Add"} clickFunction={addAviator} />;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} sm={12} md={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Company Tracked Aviators</Typography>
            <p>
              Listed below are aviators that you are currently tracking in your
              company.
            </p>
            <p>
              According to your company profile you are alloted {availableSlots}
              slots for aviators. You have{" "}
              {availableSlots != undefined && companyTableData != undefined
                ? Number(availableSlots) - companyTableData?.length
                : "0"}{" "}
              remaining.
            </p>

            {companyTableData != undefined ? (
              <CompanyAdminTable
                token={props.token}
                refreshList={refreshList}
                headers={companyTableHeaders}
                data={companyTableData}
                setEditUserSelected={setEditUserSelected}
                setEmail={setUserEmail}
                setName={setUserName}
                setUserAccessSelected={setUserAccessSelected}
                setEditUserId={setEditUserId}
              />
            ) : (
              <p>No Aviators to Display.</p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Edit and Add Users</Typography>
            <p>
              We also offer a company level flight logbook pass. This gives the
              flight company the ability to track their aviator's progress,
              currency, and verifying that aviators are meeting their minimums.
            </p>
            <FltInputField
              valueSetter={setUserName}
              currentValue={userName}
              fieldName={"Last Name, First Name"}
            />
            <FltInputField
              valueSetter={setUserEmail}
              currentValue={userEmail}
              fieldName={"Email"}
            />
            <SimpleSelect
              optionSets={accessLevel}
              setOptionValue={setUserAccessSelected}
              labelTitle={"UserAccess Level"}
              optionValue={userAccessSelected}
            />
          </Paper>
          {editUserSelected ? (
            <>
              <ClickButton
                title={"Update/Save"}
                clickFunction={updateAviator}
              />{" "}
              <ClickButton
                title={"Reset"}
                clickFunction={() => resetStateForm()}
                color="secondary"
              />
              <Typography variant="caption">
                {" "}
                Click Reset to go back to adding Aviators
              </Typography>
            </>
          ) : (
            addOrDisableAdd()
          )}
          <Snackbar
            open={onSaveAlertVisible}
            autoHideDuration={7000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity="success" variant="filled">
              User Added/Updated
            </Alert>
          </Snackbar>

          <Snackbar
            open={onErrorAlertVisible}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity="warning" variant="filled">
              {errorAlertText}
            </Alert>
          </Snackbar>
          <Paper className={classes.paper}>
            <CustomAccordian data={companyAdminFaqData} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
