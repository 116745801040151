export const getPrice = (plan) => {
  let price;
  switch (plan) {
    case "stickbuddy":
      price = 40;
      break;
    case "student":
      price = 45;
      break;
    case "discount":
      price = 25;
      break;
    default:
      price = 59;
    // code block
  }

  return price;
};
