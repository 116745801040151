import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CardMedia,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/apache-specific.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  })
);

export default function ApacheSpecific() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Apache Specific</Typography>

      <p className={classes.paragraph}>
        This section will only appear if you are an AH64 Pilot.
      </p>
      <Grid container>
        <Grid item xs={12}>
          <CardMedia
            className={classes.media}
            image={PrimaryPhoto}
            title="Photo"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <Photo photo={PrimaryPhoto} /> */}

          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="Aircraft/Simulator Back Seat"
                secondary="Backseat requirements that are made available to you from your ATP requirements. This is required to be filled out if you want the flight logbook to keep track of counting down from your requirements."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Aircraft/Simulator Front Seat"
                secondary="Frontseat requirements that are made available to you from your ATP requirements. This is required to be filled out if you want the flight logbook to keep track of counting down from your requirements."
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Simulator Total"
                secondary="Represents how many hours are you required to fly in the simulator during your semiannual period."
              />
            </ListItem>
          </List>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} sm={6}>
          <List>
            <ListItem>
              <ListItemText
                primary="NVS"
                secondary="Night Vision System hours required for your semiannual period."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="NVD"
                secondary="Night Vision Devices usually Night Vision Goggles and Night Vision System combined total that is required."
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            Don't forget to adjust these numbers for any proration during your
            semiannual period.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} height={"auto"} />;
};
