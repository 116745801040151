import { FormatDate } from "../../../SystemSettings/CommonLib";
import {
  IFlight,
  ISimulatorFlight,
  ITrackingItem,
  ITrackingPreferences,
} from "../../../SystemSettings/ProjectInterfaces";

export const getLatestFlights = (
  flights: IFlight[] | ISimulatorFlight[],
  trackingPreferences: ITrackingPreferences,
  pageSource: "flight" | "simulator"
) => {
  // Loop through tracking preferences and find the latest flight based on the tracking preferences
  let statusTracker = [];

  if (pageSource === "flight") {
    if (
      trackingPreferences.hoist.reportStatus === true &&
      trackingPreferences.hoist.currencyRequirement != ""
    ) {
      //This will be IFlight[] based on page source
      flights = flights as IFlight[];
      let lastHoistedFlight = flights.find(
        (flight) => flight?.hoist === "TRUE"
      ) as IFlight;
      console.log(lastHoistedFlight?.fltDate);
      if (lastHoistedFlight) {
        statusTracker.push(
          currencyBuilder(
            lastHoistedFlight.fltDate,
            "Hoist",
            trackingPreferences.hoist.currencyRequirement
          )
        );
      }
    }

    if (
      trackingPreferences.cefs.reportStatus === true &&
      trackingPreferences.cefs.currencyRequirement != ""
    ) {
      //This will be IFlight[] based on page source
      flights = flights as IFlight[];
      let lastCEFSFlight = flights.find(
        (flight) => flight?.CEFS === "TRUE"
      ) as IFlight;
      console.log(lastCEFSFlight?.fltDate);
      if (lastCEFSFlight) {
        statusTracker.push(
          currencyBuilder(
            lastCEFSFlight.fltDate,
            "Extended Fuel System (CEFS)",
            trackingPreferences.cefs.currencyRequirement
          )
        );
      }
    }
  }

  if (pageSource === "simulator") {
    if (
      trackingPreferences.cbrn.reportStatus === true &&
      trackingPreferences.cbrn.currencyRequirement != ""
    ) {
      //This will be IFlight[] based on page source
      flights = flights as ISimulatorFlight[];
      let lastChemicalFlight = flights.find(
        (flight) => flight?.lct_cbrn === "TRUE"
      ) as ISimulatorFlight;
      console.log(lastChemicalFlight?.lctDate);
      if (lastChemicalFlight) {
        statusTracker.push(
          currencyBuilder(
            lastChemicalFlight.lctDate,
            "CBRN",
            trackingPreferences.cbrn.currencyRequirement
          )
        );
      }
    }
  }
  return statusTracker;
};

function checkExpiration(date: string, days: number) {
  let flightDate = new Date(date);
  // Expired date is flightDate + days
  let expiredDate = new Date(flightDate.setDate(flightDate.getDate() + days));
  let today = new Date();
  // debugger;
  return {
    expiredDate: FormatDate(expiredDate.toISOString()),
    isExpired: today > expiredDate,
  };
}

function currencyBuilder(
  flightDate: string,
  categoryTitle: string,
  currencyRequirement: number
) {
  const { isExpired, expiredDate } = checkExpiration(
    flightDate,
    currencyRequirement
  );
  return {
    date: flightDate,
    message: `Last ${categoryTitle}  Flight was on ${FormatDate(
      flightDate
    )} and the currency requirement is ${currencyRequirement} days. The currency expiration date is ${expiredDate}. The your currency for this would be considered ${
      isExpired ? "expired" : "current"
    }.`,
  };
}
