import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/mobile-view.png";

export interface AdminInfoProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function LogbookMobileFormat() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Flight Logbook Mobile View</Typography>

      <p className={classes.paragraph}>
        If you are on a mobile vs desktop computer/tablet you will get a
        different view. This module you will see the mobile view. This view is
        more condensed but you do get a lot more information displayed.
      </p>
      <Grid container xs={12} spacing={3}>
        <Photo photo={PrimaryPhoto} />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="1. Date, Duty, Seat"
                secondary="You will see that this is broken down into readable dashes. Giving you the ability to quickly view the date, duty, and seat position"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="2. Flight Hours"
                secondary="We tried to cram a lot of data in here most of these modes should be known to aviators but here they are. D = Day, N = Night, NS= Night System, H = Hood, NG = Night Vision Goggles, WX = Weather"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="3. Remarks"
                secondary="Any flight remarks that you wrote will display here"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="4. Mission Data"
                secondary="Anything that you checkmarked in the mission data will show up as badges or pills. That way you can quickly see at a glance what triaing you executed."
              />
            </ListItem>
          </List>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} sm={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="5. Edit"
                secondary="That will allow you to edit that flight row of data. The popup menu will appear allowing you to enter/update the data."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="6. Delete"
                secondary="This will delete the record from the database."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="7. Record Id"
                secondary="Not really of any importance to you but think of this like a check registry. This is your flight strip id number."
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            Once you delete a record there is not a way to bring it back. All
            deltes are final.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width="100%" />;
};
