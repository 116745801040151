import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CardBoxes from "../Inputs/CardBoxes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "10px",
      flexGrow: 1,
      color: theme.palette.text.secondary,
      background: "white",
    },
    card: {
      maxWidth: 345,
    },
    media: {
      maxWidth: "100%",
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface NeoPersonalProps {}

export default function Screenshots(props: NeoPersonalProps) {
  // const [fullName, setFullName] = React.useState<string>('Jerome Flaherty');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <CardBoxes
              cardText={`Pilot information is where all where you can edit all your
                    attributes aircraft and simulator minimums. This is also
                    where you let the Army Flight Logbook know when your
                    semiannual period starts. This helps the Flight Logbook
                    calculate when you have met your minimums.`}
              cardImage={"/static/images/pilot-info.png"}
              cardTitle={"Pilot Information"}
            />
          </Paper>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper} elevation={3}>
              <CardBoxes
                cardText={`The Logbook is broken up into 4 main sections. This allows you to view your flights in an easy organized way and informed on your semiannual requirements and currency. Sure beats keeping a paper logbook. You will see charts that will represent your flights.`}
                cardImage={"/static/images/flight-log-info.png"}
                cardTitle={"Logbook Information"}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper} elevation={3}>
              <CardBoxes
                cardText={`Do you need to review your total time? No problem the bottom left Career Summary has you covered. You can break it down by Airframe - Model - Duty Position (PI-PC-IP..etc) - Mode (Day-Night-NVG-WX..etc)`}
                bottomImage={"/static/images/career-total-info.png"}
                cardTitle={"Career Summary"}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid xs={12} sm={5}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">
              Keeping Track of Semiannual Hours
            </Typography>
            <p className={classes.paragraph}>
              There may be times where you think you made your minimums and
              other times where you will blow through them. Army Flight Logbook
              will make sure that the hours you are logging are tracked
              properly.
            </p>
            <p className={classes.paragraph}>
              Visually see when you meet your ATP requirements. This is your
              semi annual snapshot that shows you how many hours you have left
              based on each category. It breaks it down for Simulator and
              Aircraft. This should help you focus on the hours you need to log
              in order to met your ATP requirements.
            </p>
            <img
              className={classes.media}
              src="/static/images/semi-annual-info.png"
              alt="semiannual"
            />
          </Paper>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6">Adding Remarks</Typography>
              <p className={classes.paragraph}>
                Every time you add your flight you have the opportunity to add
                remarks that you would like to save about your flight. If you
                see a "YES" in the remarks column simply click that row and you
                will see your remarks displayed in the Remarks Box.
              </p>
            </Paper>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6">Viewing Flights</Typography>
              <p className={classes.paragraph}>
                You can choose to view your entire logbook, filter by date, or
                choose to view all flights in your given semiannual period.
              </p>
              <Grid container>
                <Grid item xs={12} sm={12}></Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
