import React from "react";
import {
  CircularProgress,
  Box,
  CircularProgressProps,
  Typography,
  Grid,
} from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

type Simulator = {
  Flightid: number;
  lctDate: string;
  Day: string;
  nite: string;
  lctNVS: string;
  lctHood: string;
  lctSeat: string;
  lctType: string;
  lctRemarks: string;
  Name: string;
  personid: number;
  airframe_id: number;
  tail_number?: number;
  recovery?: any;
  launch?: any;
  lctNVG: string;
  lctWeather: string;
  lct_contact: string;
  lct_tactics: string;
  lct_instruments: string;
  lct_evaluation: string;
  lct_io_refresh: string;
  lct_emergency: string;
  lct_hud: string;
  lct_shipboard: string;
  lct_mountain: string;
  lct_gunnery: string;
  lct_cbrn: string;
};

type PropTesting = {
  // flightData?: Simulator[],
  token: string;
  addTotal: any;
  grandTotal: number;
  // progressType: "dayCurrent" | "nightCurrent"
};

export default function SimulatorProgress(props: PropTesting) {
  const [flightHours, setFlightHours] = React.useState(0);
  const [currentDayHours, setCurrentDayHours] = React.useState(0);
  const [currentNightHours, setCurrentNightHours] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size="5em"
          thickness={5}
          aria-label="Flight Hours"
          variant="static"
          {...props}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const findPercent = (current: number) => {
    let temp = localStorage.getItem("required");
    return temp ? Math.round((current / parseInt(temp)) * 100) : 0;
  };

  const barData: object = {
    labels: ["Day", "Night"],
    datasets: [
      // {
      //     label: "First dataset",
      //     data: [33, 53, 85, 41, 44, 65],
      //     fill: true,
      //     backgroundColor: "rgba(75,192,192,0.2)",
      //     borderColor: "rgba(75,192,192,1)"
      // },
      {
        label: "Second dataset",
        data: [currentDayHours, currentNightHours],
        fill: true,
        // borderColor: "#742774"
        backgroundColor: ["#111344", "#52154e"],
      },
    ],
  };

  // legend to label the graph
  // this will change where and if the legend will display (?)
  const legend: object = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };

  const barOptions: object = {
    title: {
      display: true,
      text: "Example Circle",
    },
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          {showProgress ? (
            <React.Fragment>
              <CircularProgressWithLabel value={findPercent(flightHours)} />
              <br />
              <Typography variant="caption">Simulator Minimum Hours</Typography>
            </React.Fragment>
          ) : (
            <CircularProgress size="5em" thickness={5} />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {
            showProgress ?
              <React.Fragment>
                <CircularProgressWithLabel value={
                  findPercent(currentNightHours)
                } />
                <br />
                <Typography variant="caption">Night Flight Hours</Typography>
              </React.Fragment>
              :
              <CircularProgress size="5em" thickness={5} />
          }
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item xs>
          <Doughnut data={barData} legend={legend} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

{
  /* <div>

{
    props.flightData.map((item, index) => {
        return (
            <div key={index}>
                {/* {console.log(item)} */
}
{
  /* {item.fltDay} */
}
{
  /* {item.fltNight} */
}
{
  /* <Button onClick={addHours}>Add Hours</Button> */
}
{
  /* {


                    semiStartY && semiStartM && semiStartD && semiEndD && semiEndM && semiEndY
                        ?

                        start < new Date(parseInt(item.fltDate.substring(0, 4)), parseInt(item.fltDate.substring(6, 7)), parseInt(item.fltDate.substring(9, 10))) && new Date(parseInt(item.fltDate.substring(0, 4)), parseInt(item.fltDate.substring(6, 7)), parseInt(item.fltDate.substring(9, 10))) < end ?
                            item.fltDay
                        : null

                    :
                    null
                }
            </div>
        )
    })
}

</div> */
}
