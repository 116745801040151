import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(1),

      width: 200,
    },
  })
);
export interface DatePickerProps {
  dateValue: string;
  title: string;
  setDateValue: any;
  disabled?: boolean;
}
export default function DatePicker(props: DatePickerProps) {
  const classes = useStyles();

  return (
    <TextField
      id={"datetime-local" + Math.floor(Math.random() * 100) + 1}
      label={props.title}
      type="date"
      variant="outlined"
      value={props.dateValue}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => {
        props.setDateValue(e.target.value);
      }}
      disabled={props.disabled}
    />
  );
}

DatePicker.defaultProps = {
  disabled: false,
};
