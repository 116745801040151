export const FLIGHT_TABLE_NAME = "FlightLog";
export const FLIGHT_ID = "FlyID";
export const FLIGHT_DAY = "fltDay";
export const FLIGHT_NIGHT = "fltNight";
export const FLIGHT_NVS = "fltNVS";
export const FLIGHT_HOOD = "fltHood";
export const FLIGHT_TYPE = "fltType";
export const FLIGHT_SEAT = "fltSeat";
export const FLIGHT_NVG = "NVGflt";
export const FLIGHT_REMARKS = "fltRemarks";
export const FLIGHT_LOCATION = "location";
export const FLIGHT_DATE = "fltDate";
export const FLIGHT_MISSION = "mission";
export const FLIGHT_WEATHER = "fltweather";
export const FLIGHT_AIRFRAME_ID = "airframe_id";
export const FLIGHT_TERRAIN_FLIGHT = "terrain_flight";
export const FLIGHT_BAMBI_BUCKET = "bambi_bucket";
export const FLIGHT_SLING_LOAD = "sling_load";
export const FLIGHT_HOIST = "hoist";
export const FLIGHT_ASSAULT = "assault";
export const FLIGHT_GUNNERY = "gunnery";
export const FLIGHT_OVERWATER = "overwater";
export const FLIGHT_CMF = "cmf";
export const FLIGHT_CROSS_COUNTRY = "cross_country";
export const FLIGHT_ATM_FLIGHT = "atm_flight";
export const FLIGHT_MULTISHIP = "multiship";
export const FLIGHT_LEAD = "flight_lead";
export const FLIGHT_INSTRUMENT_APPROACH = "instrumentapproach";
export const FLIGHT_HOLDING_PATTERN = "holding_pattern";
export const FLIGHT_TAIL_NUMBER = "tail_number";
export const FLIGHT_AC_MODEL = "ac_model";
export const FLIGHT_TAKE_OFFS = "take_offs";
export const FLIGHT_LANDINGS = "landings";
export const FLIGHT_NIGHT_LANDINGS = "night_landings";
export const FLIGHT_INSTRUMENT_TAKE_OFFS = "instrument_take_offs";
export const FLIGHT_CROSS_COUNTRY_HRS = "cross_country_hrs";
export const FLIGHT_AIRPORT_FROM = "airport_from";
export const FLIGHT_AIRPORT_TO = "airport_to";
export const FLIGHT_CREW = "crew";
export const FLIGHT_FADEC = "fadec";
export const FLIGHT_SEF = "SEF";
export const FLIGHT_DOUBLEBAG = "doublebag";
export const FLIGHT_AMC = "AMC";
export const FLIGHT_CUSTOM_TAGS = "customTags";
export const FLIGHT_UAS_TAKEOFF = "uas_take_off";
export const FLIGHT_UAS_LANDING = "uas_landing";
export const FLIGHT_PERSON_ID = "personid";
