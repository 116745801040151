import React from "react";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
} from "@material-ui/core";
import CompanyCurrencyTable from "./CompanyCurrencyTable";
import CompanyNVGCurrencyTable from "./CompanyNVGCurrencyTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
  })
);

export default function CompanyReports(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4">Company Reports Page</Typography>
        <p>
          This page if you are a company admin or moderator will see a roster of
          the soldiers that you have in the company.
        </p>
        <CompanyCurrencyTable token={props.token} setToken={props.setToken} />
        <CompanyNVGCurrencyTable
          token={props.token}
          setToken={props.setToken}
        />
      </Paper>
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}
