import React from "react";
import "./ReactTable.css";

interface ReactTableProps {
  headers: string[];
  data: ReactTableData[];
}

export interface ReactTableData {
  row: (string | number)[];
  styles: (string | null)[];
}

const ReactTable: React.FC<ReactTableProps> = ({ headers, data }) => {
  return (
    <div className="custom-table">
      <div className="custom-table-header">
        {headers?.map((header, index) => (
          <div
            key={index}
            className="custom-table-cell custom-table-header-cell"
          >
            {header}
          </div>
        ))}
      </div>
      {data?.map((rowData, rowIndex) => (
        <div key={rowIndex} className="custom-table-row">
          {rowData.row.map((cellData, cellIndex) => (
            <div
              key={cellIndex}
              className={`custom-table-cell ${rowData.styles[cellIndex] || ""}`}
            >
              {cellData}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ReactTable;
