import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 190,
  },
  images: {
    textAlign: "center",
    paddingTop: "10px",
  },
});

export interface CardBoxesProps {
  cardText: string;
  cardImage?: string;
  cardTitle: string;
  bottomImage?: string;
}

export default function CardBoxes(props: CardBoxesProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {props.cardImage ? (
          <CardMedia
            className={classes.media}
            image={props.cardImage}
            title={props.cardTitle}
          />
        ) : (
          <></>
        )}

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.cardTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.cardText}
          </Typography>
          <div className={classes.images}>
            {props.bottomImage ? <img src={props.bottomImage} /> : <></>}
          </div>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}
