import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/main-header.png";
import TwoLogbooks from "./TwoLogbooks";
import FilteringFlights from "./FliteringFlights";
import PieChartInformation from "./PieChartInformation";
import LogbookTableFormat from "./LogbookTableFormat";
import LogbookMobileFormat from "./LogbookMobileFormat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function FlightInformation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ borderRadius: "0", backgroundColor: "lightgray" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">FlightLogbook Information</Typography>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Photo photo={PrimaryPhoto} />

                  <p className={classes.paragraph}>
                    This page is split up into several important sections.
                  </p>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        primary="Two Different Logbooks"
                        secondary="You can have one Primary Aircraft and another logbook that contains all other airframes."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Filtering Flights"
                        secondary="You can filter your logbook display by displaying every flight, semiannual period, or by date range."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Pie Chart"
                        secondary="Data driven pie charts so you can easily see how you have your time split."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Logbook in Table Format"
                        secondary="Displaying your data in nice rows for easy viewing. Giving you the ability to add edit or delete."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Logbook in Mobile Format"
                        secondary="We have a simple way of displaying the data that you may want to view in a small contained mobile screen"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TwoLogbooks />
            <Divider />
            <FilteringFlights />
            <Divider />
            <PieChartInformation />
            <Divider />
            <LogbookTableFormat />
            <Divider />
            <LogbookMobileFormat />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              THIS SECTION WAS INTENTIONALLY LEFT BLANK
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
