let APIURL = "";
let PUBLIC_SHAREABLE_KEY =
  "pk_test_51IzC2hCxAKEF6kzD2CaIZVc1l9LStXgkjdPJ27UAyI2CguXyqJiSleLVV4HP6DsXbuogCrkFsfVvESjhPOaqUDhz00JWmDE7mJ";
let websiteLocation = window.location.hostname;

if (websiteLocation === "localhost" || websiteLocation === "127.0.0.1") {
  APIURL = "http://localhost:5001/armyflightlogbook-release/us-central1/app/";
} else if (
  websiteLocation === "flight-material.herokuapp.com" ||
  websiteLocation === "avlogbook.herokuapp.com"
) {
  APIURL = "https://armyflightlogbook-a77f4.web.app/";
} else if (websiteLocation === "armyflightlogbook.com") {
  APIURL = "https://armyflightlogbook-release.web.app/";
  PUBLIC_SHAREABLE_KEY =
    "pk_live_51IzC2hCxAKEF6kzDZxBtD2OCz2uURQtC3m8TzWPhd7DHfW49MesOom0RYNflmwc2lgIJsTfwpUay2QK7yAmnjmco00OmTzgPJz";
} else {
  APIURL = "https://armyflightlogbook-release.web.app/";
  PUBLIC_SHAREABLE_KEY =
    "pk_live_51IzC2hCxAKEF6kzDZxBtD2OCz2uURQtC3m8TzWPhd7DHfW49MesOom0RYNflmwc2lgIJsTfwpUay2QK7yAmnjmco00OmTzgPJz";
}

APIURL = "https://armyflightlogbook-release.web.app/";

// switch (window.location.hostname) {

//   case "localhost" || "127.0.0.1":
//     APIURL = "http://localhost:5000/";
//     break;
//   // case 'flight-fe-dev.herokuapp.com' || 'flight-material.herokuapp.com':
//   case "flight-material.herokuapp.com":
//     APIURL = "https://armyflightlogbook-a77f4.web.app/";
//     break;
//   case "avlogbook.herokuapp.com":
//     APIURL = "https://armyflightlogbook-a77f4.web.app/";
//     break;
//   case "armyflightlogbook.com":
//     APIURL = "https://armyflightlogbook-release.firebaseapp.com/";
//     break;
//   default:
//     APIURL = "https://armyflightlogbook-a77f4.web.app/";
//     break;
// }
export { APIURL, PUBLIC_SHAREABLE_KEY };
