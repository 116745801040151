import React from "react";
import {
  CircularProgress,
  Button,
  Box,
  CircularProgressProps,
  Typography,
  Grid,
} from "@material-ui/core";
import { APIURL } from "../../Helpers/environment";
import { Doughnut } from "react-chartjs-2";

type Flight = {
  AMC: string;
  FlyID: number;
  NVGflt: string;
  Name: string;
  SEF: string;
  ac_model: number;
  airframe_id: string;
  airport_from: string;
  airport_to: string;
  assault: string;
  atm_flight: string;
  bambi_bucket: string;
  cmf: string;
  crew: string;
  cross_country: string;
  cross_country_hrs: number;
  doublebag: string;
  fadec: string;
  flight_lead: string;
  fltDate: string;
  fltDay: string;
  fltHood: string;
  fltNVS: string;
  fltNight: string;
  fltRemarks: string;
  fltSeat: string;
  fltType: string;
  fltweather: string;
  gunnery: string;
  hoist: string;
  holding_pattern: string;
  instrument_apporach: number;
  instrument_take_offs: number;
  instrumentapproach: string;
  landings: number;
  location: string;
  mission: string;
  multiship: string;
  night_landings: number;
  overwater: string;
  personid: number;
  sling_load: string;
  tail_number: string;
  take_offs: number;
  terrain_flight: string;
};

type PropTesting = {
  flightData: Flight[];
  token: string;
  addTotal: any;
  grandTotal: number;
  // progressType: "dayCurrent" | "nightCurrent"
};

export default function FlightProgress(props: PropTesting) {
  const [flightHours, setFlightHours] = React.useState(0);
  const [currentDayHours, setCurrentDayHours] = React.useState(0);
  const [currentNightHours, setCurrentNightHours] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size="5em"
          thickness={5}
          aria-label="Flight Hours"
          variant="static"
          {...props}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const findPercent = (current: number) => {
    let temp = localStorage.getItem("required");
    return temp ? Math.round((current / parseInt(temp)) * 100) : 0;
  };

  // React.useEffect(() => {
  //     fetch(`${APIURL}flightlog/getcurrent`, { headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': props.token }) })
  //         .then(res => res.json())
  //         .then(json => {
  //             findHours(json)
  //         })
  //         .catch(err => console.log(err))
  // }, [])

  let findHours = (list: Flight[]) => {
    let totalDay: number = 0;
    let totalNight: number = 0;

    console.log(list);

    list.forEach((item) => (totalDay += parseFloat(item.fltDay)));
    list.forEach((item) => (totalNight += parseFloat(item.fltNight)));

    setCurrentDayHours(totalDay);
    setCurrentNightHours(totalNight);
    setFlightHours(totalDay + totalNight);
    props.addTotal(totalDay + totalNight);
    setShowProgress(true);
  };

  const barData: object = {
    labels: ["Day", "Night"],
    datasets: [
      // {
      //     label: "First dataset",
      //     data: [33, 53, 85, 41, 44, 65],
      //     fill: true,
      //     backgroundColor: "rgba(75,192,192,0.2)",
      //     borderColor: "rgba(75,192,192,1)"
      // },
      {
        label: "Second dataset",
        data: [currentDayHours, currentNightHours],
        fill: true,
        // borderColor: "#742774"
        backgroundColor: ["#111344", "#52154e"],
      },
    ],
  };

  // legend to label the graph
  // this will change where and if the legend will display (?)
  const legend: object = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          {showProgress ? (
            <React.Fragment>
              <CircularProgressWithLabel
                value={findPercent(props.grandTotal)}
              />
              <br />
              <Typography variant="caption">Flight Minimum Hours</Typography>
            </React.Fragment>
          ) : (
            <CircularProgress size="5em" thickness={5} />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {
            showProgress ?
              <React.Fragment>
                <CircularProgressWithLabel value={
                  findPercent(currentNightHours)
                } />
                <br />
                <Typography variant="caption">Night Flight Hours</Typography>
              </React.Fragment>
              :
              <CircularProgress size="5em" thickness={5} />
          }
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item xs>
          <Doughnut data={barData} legend={legend} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

{
  /* <div>

{
    props.flightData.map((item, index) => {
        return (
            <div key={index}>
                {/* {console.log(item)} */
}
{
  /* {item.fltDay} */
}
{
  /* {item.fltNight} */
}
{
  /* <Button onClick={addHours}>Add Hours</Button> */
}
{
  /* {


                    semiStartY && semiStartM && semiStartD && semiEndD && semiEndM && semiEndY
                        ?

                        start < new Date(parseInt(item.fltDate.substring(0, 4)), parseInt(item.fltDate.substring(6, 7)), parseInt(item.fltDate.substring(9, 10))) && new Date(parseInt(item.fltDate.substring(0, 4)), parseInt(item.fltDate.substring(6, 7)), parseInt(item.fltDate.substring(9, 10))) < end ?
                            item.fltDay
                        : null

                    :
                    null
                }
            </div>
        )
    })
}

</div> */
}
