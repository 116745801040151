import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckOutForm, { CheckOutFormProps } from "./CheckOutForm";
import { getPrice } from "./getPrice";
import {
  makeStyles,
  Theme,
  createStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

export interface CheckoutDialogBoxProps {
  buttonColor: "primary" | "secondary";
  buttonTitle: string;
  program: "student" | "discount" | "normal" | "stickbuddy";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: { maxWidth: "400px" },
  })
);
export default function CheckoutDialogBox(
  props: Omit<CheckOutFormProps, "closeDialog"> & CheckoutDialogBoxProps
) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const price = getPrice(props.program);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color={props.buttonColor}
        fullWidth
        onClick={handleClickOpen}
      >
        {props.buttonTitle}
      </Button>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            paddingBottom: "10px",
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.info.light,
          }}
        >
          Purchase Aviator Access - {`$${price}`} USD
        </DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <CheckOutForm closeDialog={handleClose} program={props.program} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Default Props
CheckoutDialogBox.defaultProps = {
  buttonColor: "primary",
  buttonTitle: "Purchase Access",
};
