import React from "react";
import "./Footer.css";

export default function Footer(props: any) {
  const currentYear = new Date().getFullYear();
  return (
    <footer
      className="fixed-bottom"
      style={{
        background: "#675645",
        textAlign: "center",
        color: "white",
        paddingTop: "15px",
        paddingBottom: "15px",
        marginTop: "15px",
      }}
    >
      <p style={{ fontSize: "10px" }}>
        Reference herein to any specific commercial products, process, or
        service by trade name, trademark, manufacturer, or otherwise, does not
        constitute or imply its endorsement, recommendation, or favoring by the
        United States Government.
      </p>
      <p>© 2012-{currentYear} ArmyFlightLogbook.com</p>
    </footer>
  );
}
