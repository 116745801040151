import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);
export interface AccordianObject {
  title: string;
  description: any;
}
export interface CustomAccordianProps {
  data: AccordianObject[];
}

export default function CustomAccordian(props: CustomAccordianProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.data.map((results, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={index.toString()}
            >
              <Typography variant="button">{results.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{results.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
