import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
export interface CheckBoxFieldProps {
  name: string;
  valueSetter: any;
  currentValue: boolean;
  disabled?: boolean;
}
export default function CheckBoxField(props: CheckBoxFieldProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.valueSetter(event.target.checked);
  };

  return (
    <div>
      <Checkbox
        onChange={handleChange}
        checked={props.currentValue}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
        disabled={props.disabled}
      />
      {props.name}
    </div>
  );
}
CheckBoxField.defaultProps = {
  disabled: false,
};
