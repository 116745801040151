import { useState, useEffect } from "react";

import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import ReactTable, { ReactTableData } from "../../../Table/ReactTable";
import { api_uas_semi_annual_report } from "../../../../api/api-uav-reports";

type Props = {
  token: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100vh",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function UAVSemiAnnualReport({ token }: Props) {
  const title = "UAS Semi Annual Report";
  const headers = ["Category", "Hours Required", "Hours Remaining"];
  const description =
    "The UAV semi-annual calculations are based on your start and end date in your profile.";
  const classes = useStyles();

  const [uavCurrencyData, setUavCurrencyData] = useState<ReactTableData[]>();

  useEffect(() => {
    if (!token) return;
    async function getUAVSemiAnnual() {
      // API call to get UAV SemiAnnual Report
      const response = await api_uas_semi_annual_report(token);
      if (response) {
        setUavCurrencyData(response);
      }
    }

    getUAVSemiAnnual();
  }, [token]);

  return (
    <Paper
      className={classes.paper}
      style={{ borderRadius: "0", backgroundColor: "lightgray" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <p>{description}</p>
        </Grid>
        <Grid item xs={12}>
          {uavCurrencyData && (
            <ReactTable headers={headers} data={uavCurrencyData} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
