import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ClickButton from "../../Inputs/PageButtons";
import { ISimulatorFlight } from "../../SystemSettings/ProjectInterfaces";
import { DeleteRecord, FormatDate } from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import { TablePagination } from "@material-ui/core";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddSimulatorLogModal from "./AddSimLogModal";
import { FilterList } from "@material-ui/icons";
// import AddFlightLogModal from "./AddFlightLogModal";

const useStyles = makeStyles({
  table: {
    minWidth: 375,
  },
});

export interface IFlightTableProps {
  token: string;
  flightData: ISimulatorFlight[];
  refreshTableGrid: any;
  openFlightModel: any;
  setFlightData: any;
  resetTablePageNumber: any;
  setResetTablePageNumber: any;
  setToken: any;
}

export default function SimulatorTable(props: IFlightTableProps) {
  const classes = useStyles();
  const rowHeaders = [
    "Date",
    "D",
    "N",
    "NS",
    "NG",
    "H",
    "Wx",
    "Seat",
    "DS",
    "Edit/Delete",
  ];

  const [editRecordRowInformation, setEditRecordRowInformation] = useState<
    ISimulatorFlight
  >();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showEditFlightModel, setShowEditFlightModel] = useState(false);
  const [editArrayIndex, setEditArrayIndex] = useState(0);

  function deleteFlightRecord(id: number) {
    // Function called after deleting a row
    const filteredList = () => {
      props.setFlightData(
        props.flightData.filter((flightRecord) => flightRecord.Flightid != id)
      );
    };
    DeleteRecord(
      Endpoints.tables.simulator.delete,
      id,
      props.token,
      filteredList
    );
  }

  function closeEditFlightModel() {
    setShowEditFlightModel(false);
  }

  function editFlightRecord(
    flightRecord: ISimulatorFlight,
    arrayIndex: number
  ) {
    setShowEditFlightModel(true);
    setEditRecordRowInformation(flightRecord);
    setEditArrayIndex(arrayIndex);
  }
  // They clicked the button on page to either get all flights, search by date, or search by semiannual. We needed a way to reset the page number back to zero.
  useEffect(() => {
    if (props.resetTablePageNumber) {
      setPage(0);
      props.setResetTablePageNumber(false);
    }
  }, [props.resetTablePageNumber]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function displayTable() {
    if (props.flightData.length > 0) {
      return props.flightData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: ISimulatorFlight, index: number) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              {FormatDate(row.lctDate)}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.Day}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.nite}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctNVS}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctNVG}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctHood}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctWeather}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctSeat}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.lctType}
            </TableCell>
            <TableCell align="right">
              <ClickButton
                title={<BorderColorOutlinedIcon />}
                clickFunction={() =>
                  row.Flightid !== undefined
                    ? editFlightRecord(row, page * rowsPerPage + index)
                    : null
                }
              />
              <ClickButton
                title={<DeleteForeverOutlinedIcon />}
                clickFunction={() =>
                  row.Flightid !== undefined
                    ? deleteFlightRecord(row.Flightid)
                    : null
                }
                color="primary"
              />
            </TableCell>
          </TableRow>
        ));
    } else {
      return <div></div>;
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {rowHeaders.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{props.flightData ? displayTable() : <></>}</TableBody>
      </Table>
      <TablePagination
        count={props.flightData ? props.flightData.length : 0}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <AddSimulatorLogModal
        token={props.token}
        modelOpen={showEditFlightModel}
        setModelOpen={closeEditFlightModel}
        isEditFlight={true}
        recordInformation={editRecordRowInformation}
        title="Edit Flight Details"
        flightData={props.flightData}
        setFlightData={props.setFlightData}
        editArrayIndex={editArrayIndex}
        setToken={props.setToken}
      />
    </TableContainer>
  );
}
