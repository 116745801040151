import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export interface RadioButtonGroupProps {
  buttonLabels: string[];
  buttonValues: string[];
  setCurrentValue: any;
}
export default function RadioButtonGroup(props: RadioButtonGroupProps) {
  function displayButtons() {
    return props.buttonLabels.map((button, index: number) => {
      return (
        <FormControlLabel
          key={index}
          value={props.buttonValues[index]}
          control={<Radio color="primary" />}
          label={button}
          labelPlacement="end"
        />
      );
    });
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue={-1}
        onChange={(e) => props.setCurrentValue(e.target.value)}
      >
        {displayButtons()}
      </RadioGroup>
    </FormControl>
  );
}
