import * as React from "react";
import { useEffect } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core/";
import { Link } from "react-router-dom";

import PlanePhoto from "../../Assets/history-photo.jpg";
import MobilePhoto from "../../Assets/mobile-image.png";
import CockPitPhoto from "../../Assets/cockpit-image.jpeg";
import ChinookPic from "../../Assets/chinook-welcome.jpeg";
import SemiAnnualBreakdownPhoto from "../../Assets/semi-annual-breakdown.png";
import DesignedByAviatorsPhoto from "../../Assets/designed-by-aviators.png";
import Footer from "../Footer/Footer";
import FlightIcons from "../Material-Icon/Ledgend";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function CenteredGrid(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ borderRadius: "0", backgroundColor: "lightgray" }}
      >
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Paper className={classes.paper}>
              <Photo photo={PlanePhoto} />
            </Paper>
          </Grid>
          <Grid item sm={6}>
            <h3>Start Tracking Your Flight Hours.</h3>
            <p className={classes.paragraph}>
              Losing your logbook would be pretty devastating. Not so bad if you
              only have 30 hours and you can grab them all maybe from a DA Form
              759 or a -12 printout... So where would you even start to get all
              that back? It would be a nightmare!
            </p>
            <p className={classes.paragraph}>
              Sure you can make scans of all your pages, or take a photo of each
              one... With a digital logbook, it’s as simple as opening an app on
              your smartphone and taking 1 minute to input the flight and you're
              done. Your flight will be saved until you decide to update it. The
              Army Flight Logbook takes your flight data and presents it to you
              in a clean format and provides you with valuable information
              concerning your currency and aircraft minimums that you need to
              obtain to stay an Army Aviator.
            </p>
            <p className={classes.paragraph}>
              Online logbooks have the benefit of storing your data at an
              external server, which means you can access it from any device. So
              if one of those devices accidentally gets submerged in water or
              your computer crashes, none of your flight hours were lost since
              nothing was saved physically on your device. All is saved in the
              logbook cloud. This digital logbook program is more than an excel
              spreadsheet.
            </p>
            <Link to="/login">
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "10px" }}
              >
                Login
              </Button>
            </Link>
            <Link to="/purchase">
              <Button variant="contained" color="secondary">
                Signup
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Photo photo={DesignedByAviatorsPhoto} />
              <h2>Finally a Logbook For Army Aviators</h2>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        primary="Stay up to date"
                        secondary="A great way to make sure that CAFRS is up to
                  date."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Tracking"
                        secondary="Track Minimums and Currency Requirements"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Multiple Logbooks"
                        secondary="Add Flights and Simulator times from different Airframes"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Safe and Secure"
                        secondary="We do regular backups of all flight records daily."
                      />
                    </ListItem>
                  </List>
                </Grid>
                {/* Second Column */}
                <Grid item xs={12} sm={6}>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        primary="Setup for Prorated Hours"
                        secondary="Set your profile up to match with your ATP requirements. Set a start and end date of your semiannual period."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Company Tracking"
                        secondary="Know who needs to fly and when they go un current. Set up your crew mix based on experience."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Downloadable Reports"
                        secondary="If you need to compare your logbook next to your -12 print out you are able to download your log based on date."
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Photo photo={SemiAnnualBreakdownPhoto} />

              <h2>Visually See Your Monthly Break Down</h2>
              <p className={classes.paragraph}>
                We have visual representations of your flight hours so you know
                how many hours you flew. How close you are to meeting your
                minimums. And you can see at a glance when you will be going un
                current.
              </p>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <h4>Flight Mode</h4>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText primary="Day Flight Hours" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Night Flight Hours" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Weather/Hood Flight Hours" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="NVS and NVG Flight Hours" />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h4>Flight Position</h4>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText primary="Pilot" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Pilot in Command" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Unit Trainer" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Instructor, Standards, Maintenance Pilot and more..." />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Photo photo={MobilePhoto} />

              <h2>Get Our Mobile Version of the Logbook</h2>
              <p className={classes.paragraph}>
                It utilizes the <b>same database</b> as the web version. You can
                log your flights on the dedicated mobile app and then see them
                appear on the web application. So you can log your flights on
                the go by having a dedicated app on your phone.
              </p>
              <p>
                Cellular data or access to wifi would be required for logging
                and viewing flights in your Mobile device.{" "}
              </p>
              <Grid container style={{ gap: 10, justifyContent: "center" }}>
                {/* Buttons for ios and android */}
                <Grid item xs={12} sm={5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/flight-logbook-army/id6469672888",
                        "_blank"
                      );
                    }}
                  >
                    iPhone
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.frontlinedesigns.armylogbook",
                        "_blank"
                      );
                    }}
                  >
                    Android
                  </Button>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <h4>Available for iOS and Android Devices</h4>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText primary="Flight Logbook for Flights" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Flight Logbook for Simulator Flights" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Log flights for multiple airframes" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Review Reports" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              Created by Army Pilots for Army Pilots
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
