import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
export interface ISelectionOptions {
  value: any;
  text: string;
}

export interface SimpleSelectProps {
  optionSets: ISelectionOptions[];
  setOptionValue: any;
  optionValue: any;
  labelTitle: string;
  labelWidth?: number | string;
  disabled?: boolean;
}
export default function SimpleSelect(props: SimpleSelectProps) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.setOptionValue(event.target.value as string);
    console.log(event.target.value);
  };

  const createOptionSets = () => {
    return props.optionSets.map((option: ISelectionOptions, index: number) => (
      <MenuItem key={index} value={option.value}>
        {option.text}
      </MenuItem>
    ));
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={{ minWidth: props.labelWidth ? props.labelWidth : 200 }}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {props.labelTitle}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id={"demo-simple-select-outlined" + Math.floor(Math.random() * 100) + 1}
        value={props.optionValue}
        onChange={handleChange}
        label={props.labelTitle}
        disabled={props.disabled}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {createOptionSets()}
      </Select>
    </FormControl>
  );
}
SimpleSelect.defaultProps = {
  disabled: false,
};
