import React from "react";
import { Link } from "@material-ui/core";
import { AccordianObject } from "../Inputs/CustomAccordian";
import { APIURL } from "../../Helpers/environment";

export const contactFaqData: AccordianObject[] = [
  {
    title: "How can I cancel my subscription?",
    description: [
      <p>
        You can view{" "}
        <Link
          underline="always"
          color="secondary"
          href="https://www.paypal.com/li/smarthelp/article/how-do-i-cancel-a-recurring-payment,-subscription,-or-automatic-billing-agreement-i-have-with-a-merchant-faq1067"
        >
          Paypal's documentation
        </Link>{" "}
        on how to do this. It does change from time to time which is why we are
        directing you to their site.{" "}
      </p>,
    ],
  },
  {
    title: "I forgot my password can you reset it?",
    description: [
      <p>
        On the login screen you will see a forgot password tab. Just fill out
        your email and it will be sent to you.{" "}
        <Link
          underline="always"
          color="secondary"
          href={`${window.location.origin}/login`}
        >
          Login Screen
        </Link>{" "}
      </p>,
    ],
  },
  {
    title:
      "If I do not have an active account with you - can I download my records? ",
    description:
      "Only active account memebers can download their records. If you need temporary access we can grant that.",
  },
];
