import { APIURL } from "../../Helpers/environment";
import { IFlight, PersonalJSON } from "./ProjectInterfaces";
import { Endpoints } from "./SystemSettings";
import jwtDecode from "jwt-decode";

export function RenameKey(object: any, key: string, newKey: any) {
  const clone = (obj: any) => Object.assign({}, obj);
  const clonedObj = clone(object);

  const targetKey = clonedObj[key];

  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;
}

export function FormatDate(dateTime: string) {
  let date = dateTime.split("T");

  return date[0];
}

export function DeleteRecord(
  endpoint: string,
  id: number,
  token: string,
  callback: () => any
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    // redirect: 'follow'
  };

  fetch(`${APIURL}${endpoint}${id}`, requestOptions)
    .then((response: any) => response.json())
    .then((json: any) => {
      callback();
      console.log(json);
    })
    .catch((error: any) => console.log("error", error));
}

export async function GetRecords(
  endpoint: string,
  token: string,
  callback: any,
  setToken: any
): Promise<any> {
  let localToken = localStorage.getItem("token");
  if (token == "" && localToken != null) {
    token = localToken;
  }
  let myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    // redirect: 'follow'
  };

  fetch(`${APIURL}${endpoint}`, requestOptions)
    .then((response: any) => response.json())
    .then((json: any) => {
      if (json.hasOwnProperty("error")) {
        // localStorage.clear();
        // setToken("");
      } else {
        callback(json);
        console.log(json);
      }
    })
    .catch((error: any) => console.log("error", error));
}

export function CreateRecord(
  endpoint: string,
  token: string,
  body: any,
  callback: any
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),

    // redirect: 'follow'
  };

  fetch(`${APIURL}${endpoint}`, requestOptions)
    .then((response: any) => response.json())
    .then((json: any) => {
      callback(json);
      console.log(json);
    })
    .catch((error: any) => console.log("error", error));
}

export function PostRequest(
  endpoint: string,
  token: string,
  body: any,
  callback: any
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),

    // redirect: 'follow'
  };

  fetch(`${APIURL}${endpoint}`, requestOptions)
    .then((response: any) => response.json())
    .then((json: any) => {
      callback(json);
      console.log(json);
    })
    .catch((error: any) => console.log("error", error));
}

export function UpdateRecord(
  endpoint: string,
  token: string,
  body: any,
  callback: any,
  id: number | undefined = undefined
) {
  console.log(id);
  let myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(body),

    // redirect: 'follow'
  };
  let baseURL;
  if (id) {
    baseURL = `${APIURL}${endpoint}/${id}`;
  } else {
    baseURL = `${APIURL}${endpoint}`;
  }
  fetch(baseURL, requestOptions)
    .then((response: any) => response.json())
    .then((json: any) => {
      callback(json);
    })
    .catch((error: any) => console.log("error", error));
}
export class LocalStorageInformation {
  token: string;
  dutyPosition: any;
  personalProfile: string | null;

  constructor(token: string) {
    this.token = token;
    this.dutyPosition = localStorage.getItem("dutypositions");
    this.personalProfile = localStorage.getItem("personal");
  }
  updatingPersonalProfile(callbackFunction: any, setToken: any) {
    GetRecords(
      Endpoints.tables.personalProfile.getRecord,
      this.token,
      setLocalStorage,
      setToken
    );
    function setLocalStorage(json: any) {
      localStorage.setItem("personal", JSON.stringify(json[0]));
      callbackFunction(json[0]);
    }
  }

  updatingDutyPosition(callbackFunction: any, setToken: any) {
    let dutyposition = this.dutyPosition;
    if (!dutyposition) {
      GetRecords(Endpoints.lists.dutyTable, this.token, setData, setToken);
    } else {
      dutyposition = JSON.parse(this.dutyPosition);
    }
    function setData(data: any) {
      localStorage.setItem("dutypositions", JSON.stringify(data));
      dutyposition = data;
    }
    let correctedDutyPosition = dutyposition.map((res: any) => {
      res["value"] = res.title.slice(0, 2);
      return RenameKey(res, "title", "text");
    });
    return callbackFunction(correctedDutyPosition);
  }
}

export const isJwtExpired = (token: string) => {
  let isJwtExpired = false;
  const { exp } = jwtDecode<{ exp: number }>(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
};
