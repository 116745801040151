import { AccordianObject } from "../Inputs/CustomAccordian";

export const faqData: AccordianObject[] = [
  {
    title: "Am I able to add all of my airframes?",
    description:
      "Yes- we basically give you two logbooks for your aircraft. You will have your primary aircraft logbook and a book that carries everything. That way you can keep track of everything.",
  },
  {
    title: "If I don't see my airframe listed can I get it added?",
    description: "Yes- we made it simple to add airframes that are not listed",
  },
  {
    title: "Does the logbook keep track of NVD, NVS, NVG requirements?",
    description:
      "Yes we keep track of your currencies for NVG and NVS. We combine totals for NVD time.",
  },
  {
    title: "How does pro-rated hours work?",
    description:
      "When you sent up your profile you actually set up what dates you want to be included in the calculations of your Semiannual Period. You will also be able to give ArmyFlightLogbook your prorated minimum hours",
  },
];
