import React, { useState, useEffect } from "react";
import Hidden from "@material-ui/core/Hidden";
import { CSVLink } from "react-csv";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Fab,
  Button,
  CircularProgress,
} from "@material-ui/core";
import FlightTable from "./FlightTableView";
import {
  GetRecords,
  isJwtExpired,
  LocalStorageInformation,
  PostRequest,
} from "../../SystemSettings/CommonLib";
import { Endpoints, Aircraft } from "../../SystemSettings/SystemSettings";
import ClickButton from "../../Inputs/PageButtons";
import DatePicker from "../../Inputs/DateFields";
import {
  PersonalJSON,
  IFlightSearchDateRequest,
  IFlight,
  INVSResponse,
  ICurrency,
  ITrackingPreferences,
} from "../../SystemSettings/ProjectInterfaces";
import AddFlightLogModal from "./AddFlightLogModal";
import AddIcon from "@material-ui/icons/Add";
import FlightCards from "./FlightCards";
import PieChart from "../../Inputs/PieChart";
import { Alert } from "@material-ui/lab";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDownloader from "./PDFDownloader";
import { FLIGHTHEADERS } from "./constants";
import ExtraReportingSection from "./currency-section/ExtraReportingSection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface FlightPageProps {
  token: string;
  setToken: any;
}

export default function FlightPage(props: FlightPageProps) {
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const chartInfo = `Data represented in the chart is gathered from your search query (get
    all flights, get current semiannual, or search based on date)`;
  const classes = useStyles();
  const [endSearchDate, setEndSearchDate] = useState("");
  const [flightData, setFlightData] = useState([]);
  const [isEditFlight, setisEditFlight] = useState(false);
  const [personalProfile, setPersonalProfile] = useState<PersonalJSON>();
  const [showAddFlightModel, setShowAddFlightModel] = useState(false);
  const [startSearchDate, setStartSearchDate] = useState("");
  const [totalTimeData, setTotalTimeData] = useState<any>();
  const [resetTablePageNumber, setResetTablePageNumber] = useState(false);
  const [displayAlerts, setDisplayAlerts] = useState([<></>]);
  const [nvsCurrencyResponse, setnvsCurrencyResponse] =
    useState<INVSResponse>();
  const [nvgCurrencyResponse, setNvgCurrencyResponse] = useState<ICurrency[]>();
  const [aircraftCurrency, setAircraftCurrency] = useState<ICurrency[]>();
  const [trackingPreferences, setTrackingPreferences] =
    useState<ITrackingPreferences>();
  const [allFlightData, setAllFlightData] = useState<IFlight[]>([]);

  useEffect(() => {
    if (isJwtExpired(props.token)) {
      props.setToken("");
      return;
    }

    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile informaiton in the local stroage
    if (flightPageInfo.personalProfile) {
      let personalProfile: PersonalJSON = JSON.parse(
        flightPageInfo.personalProfile
      );
      setData(personalProfile);
      if (personalProfile.tracking_preferences) {
        setTrackingPreferences(
          JSON.parse(personalProfile.tracking_preferences)
        );
      }
    } else {
      flightPageInfo.updatingPersonalProfile(setData, props.setToken);
    }
    function setData(json: any) {
      setPersonalProfile(json);
      if (json.tracking_preferences) {
        setTrackingPreferences(JSON.parse(json.tracking_preferences));
      }
    }
    getAllFlights();
  }, []);
  // Pie Chart Data Creator - runs every time flightdata changes in the table.
  useEffect(() => {
    if (!flightData) {
      return;
    }
    let totalDayHours = 0;
    let totalNightHours = 0;
    let totalNvgHours = 0;
    let totalNvsHours = 0;
    let totalHoodHours = 0;
    let totalWeatherHours = 0;

    flightData.map((record: IFlight) => {
      totalDayHours += +record.fltDay;
      totalNightHours += +record.fltNight;
      totalNvgHours += +record.NVGflt;
      totalNvsHours += +record.fltNVS;
      totalHoodHours += +record.fltHood;
      totalWeatherHours += +record.fltweather;
    });
    function fixRounding(number: number) {
      return Math.floor(number * 10) / 10;
    }
    setTotalTimeData({
      Day: fixRounding(totalDayHours),
      Night: fixRounding(totalNightHours),
      NVG: fixRounding(totalNvgHours),
      NVS: fixRounding(totalNvsHours),
      Hood: fixRounding(totalHoodHours),
      Wx: fixRounding(totalWeatherHours),
    });

    displayAlerts();

    function displayAlerts() {
      let errorDates = flightData.filter((flight: IFlight) =>
        flight.fltDate.includes("0000-00-00")
      );
      if (errorDates.length > 0) {
        setDisplayAlerts([
          <Alert severity="error">
            Automatic System Audit has detected you have Flight Dates that need
            addressing. Please scroll to the last page of the table display to
            view these dates. You are looking for dates that are set to 1970.
          </Alert>,
        ]);
      } else {
        setDisplayAlerts([<></>]);
      }
    }
    getNVSCurrency();
    getNVGCurrency();
    getAircraftCurrency();
  }, [flightData]);

  useEffect(() => {
    getNVSCurrency();
    getNVGCurrency();
    getAircraftCurrency();
  }, [showAddFlightModel]);
  //Fetching NVS Currency
  function getNVSCurrency() {
    GetRecords(
      Endpoints.totals.nvscurrency,
      props.token,
      setnvsCurrencyResponse,
      props.setToken
    );
  }

  //Fetching NVG Currency
  function getNVGCurrency() {
    GetRecords(
      Endpoints.totals.nvgcurrency,
      props.token,
      setNvgCurrencyResponse,
      props.setToken
    );
  }
  //Fetching AC Currency
  function getAircraftCurrency() {
    GetRecords(
      Endpoints.totals.currency,
      props.token,
      setAircraftCurrency,
      props.setToken
    );
  }
  // Fetching all Flights

  function getAllFlights() {
    const applyFlightData = (data: any) => {
      setFlightData(data);
      setAllFlightData(data);
    };
    setIsDownloadReady(false);
    GetRecords(
      Endpoints.tables.flightLog.getall,
      props.token,
      applyFlightData,
      props.setToken
    );
    setResetTablePageNumber(true);
  }
  //Fetching based on start and end date
  function dateSearchFilter() {
    if (!startSearchDate && !endSearchDate) {
      return;
    }
    const data: IFlightSearchDateRequest = {
      data: {
        startDate: startSearchDate,
        endDate: endSearchDate,
      },
    };

    setIsDownloadReady(false);

    PostRequest(
      Endpoints.tables.flightLog.searchdate,
      props.token,
      data,
      setFlightData
    );
    setResetTablePageNumber(true);
  }
  // Current Semiannual Period
  function getCurrentSemiAnnual() {
    GetRecords(
      Endpoints.tables.flightLog.getcurrent,
      props.token,
      setFlightData,
      props.setToken
    );
    setResetTablePageNumber(true);
  }
  function displayCurrency() {
    if (aircraftCurrency && aircraftCurrency[0].LastFlight) {
      return aircraftCurrency.map((acInfo) => {
        let sentence = [
          `Based on the last flight information you flew the aircraft ${
            acInfo.Model
          } on ${acInfo.LastFlight}. That puts your uncurrent date to ${
            acInfo.UnCurrentDate
          }. Your status is ${acInfo.isExpired ? "Expired" : "Not Expired"}`,
          <hr />,
        ];
        return sentence;
      });
    }
  }
  const refreshTableGrid = {
    allFlights: getAllFlights,
  };

  function closeAddFlightModel() {
    setShowAddFlightModel(false);
  }

  function openAddFlightModel() {
    setShowAddFlightModel(true);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12}>
          <Typography variant="h4" style={{ padding: "15px" }}>
            FlightLogbook:{" "}
            {Aircraft != undefined && personalProfile != undefined ? (
              Aircraft.filter((x) => x.value == personalProfile?.primary_ac)[0]
                .airframe_name
            ) : (
              <></>
            )}
          </Typography>
          {displayAlerts}
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Filter Options </Typography>

            <p className={classes.paragraph}>
              Choose Dates youself or choose the presets buttons. Easily swap
              between Current Semiannual or all flights.
            </p>
            <ClickButton
              title="Get All Flights"
              clickFunction={() => getAllFlights()}
              fullWidth={true}
            />
            <ClickButton
              title="Get Current SemiAnnual"
              clickFunction={() => getCurrentSemiAnnual()}
              fullWidth={true}
            />
            <p className={classes.paragraph}>
              Choose a Start Date and an End Date to assist with your search.
            </p>
            <DatePicker
              dateValue={startSearchDate}
              title="Start Date"
              setDateValue={setStartSearchDate}
            />
            <DatePicker
              dateValue={endSearchDate}
              title="Start Date"
              setDateValue={setEndSearchDate}
            />
            <ClickButton
              title="Search Based On Date"
              clickFunction={() => dateSearchFilter()}
              fullWidth={true}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Flight Data </Typography>
            {totalTimeData ? (
              <div className="chart-container">
                <PieChart dataToDisplay={totalTimeData} chartInfo={chartInfo} />
              </div>
            ) : (
              <></>
            )}
            <p>{chartInfo}</p>
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Aircraft Currency </Typography>
            {displayCurrency()}
            <p>
              {nvsCurrencyResponse &&
              nvsCurrencyResponse.MessageToUser != "Not Apache Helicopter" ? (
                <div>
                  {" "}
                  <Typography variant="h6"> NVS </Typography>
                  {nvsCurrencyResponse.MessageToUser}
                </div>
              ) : null}
              <Typography variant="h6"> NVG </Typography>

              {nvgCurrencyResponse &&
              nvgCurrencyResponse[0]?.LastFlight &&
              nvgCurrencyResponse[0]?.UnCurrentDate
                ? `Based on the last flight information for NG you flew on ${
                    nvgCurrencyResponse[0]?.LastFlight
                  }. That puts your uncurrent date to ${
                    nvgCurrencyResponse[0]?.UnCurrentDate
                  }. Your status is ${
                    nvgCurrencyResponse[0]?.isExpired
                      ? "Expired"
                      : "Not Expired"
                  } `
                : null}
            </p>
            <hr />
            {trackingPreferences && (
              <ExtraReportingSection
                trackingPreferences={trackingPreferences}
                flightRecords={allFlightData}
                pageSource={"flight"}
              />
            )}

            <ClickButton
              title="refresh currency checks"
              fullWidth={true}
              clickFunction={() => {
                getAircraftCurrency();
                getNVGCurrency();
                getNVSCurrency();
                getAllFlights();
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          <Paper className={classes.paper} elevation={3}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Fab size="small" color="secondary" aria-label="add">
                <AddIcon onClick={() => openAddFlightModel()} />
              </Fab>
            </Grid>
            <Typography variant="h6">Flight Logbook </Typography>

            <p className={classes.paragraph}>
              This is the FlightLogbook section where you can add, view, update,
              or delete your flights. Click the view more details about the
              flight.
            </p>
            <Hidden only={["sm", "xs"]}>
              <FlightTable
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                resetTablePageNumber={resetTablePageNumber}
                setResetTablePageNumber={setResetTablePageNumber}
                setToken={props.setToken}
              />
              <CSVLink
                filename="Flight Logbook Download"
                data={flightData}
                headers={FLIGHTHEADERS}
              >
                <Button
                  variant="contained"
                  color={"primary"}
                  style={{ margin: "5px" }}
                  fullWidth={true}
                >
                  Download Report CSV({flightData.length})
                </Button>
              </CSVLink>
              {isDownloadReady ? (
                <PDFDownloadLink
                  document={<PDFDownloader flightData={flightData} />}
                  fileName="ArmyFlightLogbook-Report.pdf"
                  style={{ textAlign: "center" }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download now!"
                  }
                </PDFDownloadLink>
              ) : (
                <ClickButton
                  clickFunction={() => setIsDownloadReady(true)}
                  title={"Generate PDF"}
                  fullWidth={true}
                />
              )}
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <FlightCards
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                setToken={props.setToken}
              />
            </Hidden>
          </Paper>
        </Grid>
      </Grid>
      <AddFlightLogModal
        token={props.token}
        modelOpen={showAddFlightModel}
        setModelOpen={closeAddFlightModel}
        isEditFlight={false}
        setToken={props.setToken}
        setFlightData={setFlightData}
      />
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}

// firebase serve --only hosting
