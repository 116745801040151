import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import FlightDetails from "./FlightDetails";
import { IFlight } from "../../SystemSettings/ProjectInterfaces";
import { GetRecords } from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },
    appBar: {
      position: "relative",
      background: theme.palette.secondary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);
export interface IAddFlightLogModalProps {
  modelOpen: boolean;
  token: string;
  setModelOpen: any;
  isEditFlight: boolean;
  recordInformation?: IFlight;
  title?: string;
  updateOnEditSaveFunction?: any;
  flightData?: IFlight[];
  setFlightData?: any;
  editArrayIndex?: number;
  setToken: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddFlightLogModal(props: IAddFlightLogModalProps) {
  const classes = useStyles();
  const [title, setTitle] = useState("Add a Flight");
  const handleClose = () => {
    props.setModelOpen();
    if (!props.isEditFlight) {
      GetRecords(
        Endpoints.tables.flightLog.getall,
        props.token,
        props.setFlightData,
        props.setToken
      );
    }
  };
  useEffect(() => {
    if (props.isEditFlight) {
      setTitle("Edit Flight");
    }
  }, []);

  return (
    <div>
      <Dialog
        fullScreen
        open={props.modelOpen}
        onClose={props.setModelOpen}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <FlightDetails
          token={props.token}
          isEditFlight={props.isEditFlight}
          recordInformation={props.recordInformation}
          updateOnEditSaveFunction={props.updateOnEditSaveFunction}
          flightData={props.flightData}
          setFlightData={props.setFlightData}
          editArrayIndex={props.editArrayIndex}
          setToken={props.setToken}
        />
      </Dialog>
    </div>
  );
}
AddFlightLogModal.defaultProps = {
  title: "Adding A Flight Record",
};
