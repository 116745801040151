export const AIRCRAFT_LIST = [
  {
    value: 0,
    airframe_name: "Unknown",
    text: "Unknown",
    isUAV: false,
  },

  {
    value: 11,
    airframe_name: "A139",
    text: "A139",
    isSimulator: false,
    isUAV: false,
  },
  {
    value: 1,
    airframe_name: "AH64",
    text: "AH64",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 10,
    airframe_name: "BELL 407",
    text: "BELL 407",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 14,
    airframe_name: "Cessna 172",
    text: "Cessna 172",
    isSimulator: false,
    isUAV: false,
  },
  {
    value: 4,
    airframe_name: "CH-47",
    text: "CH-47",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 13,
    airframe_name: "EH-60",
    text: "EH-60",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 15,
    airframe_name: "MH-6",
    text: "MH-6",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 8,
    airframe_name: "MQ-5B",
    text: "MQ-5B",
    isSimulator: false,
    isUAV: false,
  },
  {
    value: 3,
    airframe_name: "OH58",
    text: "OH58",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 9,
    airframe_name: "RQ-7B",
    text: "RQ-7B",
    isSimulator: true,
    isUAV: true,
  },
  {
    value: 7,
    airframe_name: "TH-67",
    text: "TH-67",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 2,
    airframe_name: "UH-60",
    text: "UH-60",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 12,
    airframe_name: "UH-72",
    text: "UH-72",
    isSimulator: true,
    isUAV: false,
  },
  {
    value: 18,
    airframe_name: "MQ-1",
    text: "MQ-1",
    isSimulator: true,
    isUAV: true,
  },
].sort((a, b) => (a.airframe_name > b.airframe_name ? 1 : -1));

export const AIRCRAFT_LIST_SIMULATORS = AIRCRAFT_LIST.filter(
  (item) => item.isSimulator
);

export const AIRCRAFT_LIST_UAVS = AIRCRAFT_LIST.filter((item) => item.isUAV);

export const isUAVAircraft = (airframeId: number) => {
  if (!airframeId) return false;

  const uavAircraftFound = AIRCRAFT_LIST.find(
    (aircraft) => aircraft.value == airframeId
  );

  return !uavAircraftFound ? false : uavAircraftFound.isUAV;
};
