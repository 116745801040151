import React, { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Snackbar,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FltInputField, FltTextAreaField } from "../Inputs/InputFields";
import SimpleSelect from "../Inputs/SelectOptions";
import ClickButton from "../Inputs/PageButtons";
import RadioPhoto from "../../Assets/contact-image.jpg";
import { Endpoints } from "../SystemSettings/SystemSettings";
import { PostRequest } from "../SystemSettings/CommonLib";
import { Alert } from "@material-ui/lab";
import CustomAccordian, { AccordianObject } from "../Inputs/CustomAccordian";
import { contactFaqData } from "./contact-faq";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "10px",
      flexGrow: 1,
      color: theme.palette.text.secondary,
      background: "white",
    },
    card: {
      maxWidth: 345,
    },
    media: {
      maxWidth: "100%",
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface ContactProps {}

export default function Contact(props: ContactProps) {
  // const [fullName, setFullName] = React.useState<string>('Jerome Flaherty');
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const [onSaveAlertVisible, setOnSaveAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const subjectOptionMenu = [
    { value: "Login Help", text: "Login Help" },
    { value: "Sales Question", text: "Sales Question" },
    { value: "Bug Report", text: "Bug Report" },
  ];

  function handleSubmit() {
    setIsLoading(true);
    const body = {
      name: name,
      emailBody: emailBody,
      emailAddress: email,
      subject: subject,
    };
    PostRequest(Endpoints.email.support, "", body, success);

    // hit the mailing endpoint and do validation
  }
  function success() {
    setOnSaveAlertVisible(true);
    const cleanup = [setName, setEmail, setSubject, setEmailBody];

    cleanup.forEach((clean) => clean(""));
    setIsLoading(false);
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOnSaveAlertVisible(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper} elevation={3}>
            <Photo photo={RadioPhoto} />
            <Typography variant="h6">Contact Us</Typography>

            <p>
              If you are having a problem with our system please fill out the
              form so we can get the problem solved.
            </p>
            <FltInputField
              fieldName={"Name"}
              valueSetter={setName}
              currentValue={name}
            />
            <FltInputField
              fieldName={"Email"}
              valueSetter={setEmail}
              currentValue={email}
            />
            <SimpleSelect
              labelTitle={"Choose Subject"}
              optionSets={subjectOptionMenu}
              setOptionValue={setSubject}
              optionValue={subject}
              labelWidth={"100%"}
            />
            <FltTextAreaField
              fieldName={"Message"}
              valueSetter={setEmailBody}
              currentValue={emailBody}
            />
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <ClickButton title="Submit" clickFunction={handleSubmit} />
            )}
          </Paper>
          <CustomAccordian data={contactFaqData} />
        </Grid>
        <Snackbar
          open={onSaveAlertVisible}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success" variant="filled">
            Message Sent{" "}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width="100%" />;
};
