import React from "react";
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  SwipeableDrawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightIcon from "@material-ui/icons/Flight";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import StoreIcon from "@material-ui/icons/Store";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { Speed } from "@material-ui/icons";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

// const useStyles = makeStyles({
//     list: {
//         width: 250,
//     },
//     fullList: {
//         width: 'auto',
//     },
// });

interface PropLayout {
  setToken: any;
}
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
// type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SwipeableTemporaryDrawer(props: PropLayout) {
  // const classes = useStyles();
  const title = localStorage.getItem("token") ? "Logbook" : "Login";
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [stickNav, setStickNav] = React.useState<boolean>(true);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(!isOpen);
    };

  window.onscroll = function (ev: any) {
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //   // you're at the bottom of the page
    //   alert('you scrolled, yo!')
    // }
    // console.log(window.scrollY)
    if (window.scrollY >= 1) setStickNav(true);
    else setStickNav(false);
  };

  function loggedInMenu() {
    return (
      <>
        <ListItem
          button
          component={Link}
          to="/companyadmin"
          title="companyadmin"
        >
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Company Admin" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/companyadmin/reports"
          title="companyadmin"
        >
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Company Admin Reports" />
        </ListItem>
        <ListItem button component={Link} to="/flight" title="flight">
          <ListItemIcon>
            <FlightIcon />
          </ListItemIcon>
          <ListItemText primary="Flights" />
        </ListItem>
        <ListItem button component={Link} to="/simulator" title="simulator">
          <ListItemIcon>
            <FlightTakeoffIcon />
          </ListItemIcon>
          <ListItemText primary="Simulator" />
        </ListItem>
        <ListItem button component={Link} to="/semiannual" title="semiannual">
          <ListItemIcon>
            <Speed />
          </ListItemIcon>
          <ListItemText primary="Semi Annual" />
        </ListItem>
        <ListItem button component={Link} to="/totals" title="totals">
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          <ListItemText primary="Career Totals" />
        </ListItem>
        <ListItem button component={Link} to="/upload" title="Upload CAFERS">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Upload CAFERS" />
        </ListItem>
        {/* <ListItem button component={Link} to="/reports" title="Reports">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem> */}
      </>
    );
  }

  return (
    <React.Fragment>
      {/* get rid of position static to have it sticky top */}
      <HideOnScroll {...props}>
        <AppBar position={stickNav ? "sticky" : "static"} color="secondary">
          <Toolbar>
            <Typography variant="h6">Army Flight Logbook</Typography>
            <Button style={{ marginLeft: "auto" }} onClick={toggleDrawer()}>
              <MenuIcon />
            </Button>
            <SwipeableDrawer
              anchor={"right"}
              open={isOpen}
              onClose={toggleDrawer()}
              onOpen={toggleDrawer()}
              //this will make the swipe to open disables but allows the side of the page (with the pagination on the tables) to be accessible
              disableSwipeToOpen={true}
            >
              <div
                className="right"
                role="presentation"
                onClick={toggleDrawer()}
                onKeyDown={toggleDrawer()}
              >
                <List>
                  <ListItem button component={Link} to="/" title="home">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/screenshots"
                    title="screenshot"
                  >
                    <ListItemIcon>
                      <CameraAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Screenshots" />
                  </ListItem>
                  <ListItem button component={Link} to="/docs" title="docs">
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Docs" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/purchase"
                    title="purchase"
                  >
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Purchase" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/contact"
                    title="contact"
                  >
                    <ListItemIcon>
                      <ContactSupportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact" />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  {/* <MailIcon /> */}
                  <ListItem button component={Link} to="/login">
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItem>
                  {localStorage.getItem("token") ? (
                    <ListItem
                      button
                      component={Link}
                      to="/"
                      title="logout"
                      onClick={() => {
                        localStorage.clear();
                        props.setToken("");
                      }}
                    >
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  ) : null}
                  {localStorage.getItem("token") ? loggedInMenu() : null}
                  <ListItem button onClick={toggleDrawer()}>
                    <ListItemIcon>{<CloseIcon />}</ListItemIcon>
                    <ListItemText primary="Exit" />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}
