import { AccordianObject } from "../../Inputs/CustomAccordian";

export const accessLevel = [
  { text: "Normal User", value: "3" },
  { text: "Company Modarator", value: "2" },
  { text: "Company Organizer", value: "4" },
];

export const companyAdminFaqData: AccordianObject[] = [
  {
    title: "How many Pilots can I add?",
    description:
      "You will have to be mindful of how many Aviators you have on your account. You will see how many you currently are allowed and how many you have remaining. You may find the Add Button disabled if you have reached your max.",
  },
  {
    title:
      "WHAT IS THE DIFFERENCE IN COMPANY ORGANIZER, COMPANY MODERATOR, AND NORMAL USER?",
    description:
      "The company organizer can view reports and add aviators to your ATP. Company moderators can view reports only. Normal users will have a regular logbook account to track their hours.",
  },
  {
    title: "What happens if I need more aviators?",
    description:
      "Send us a support email and we can increase your allotment for a price or you can remove aviators from your ATP.",
  },
  {
    title: "What happens when you delete/remove an aviator?",
    description:
      "The flight hours will be preserved in the database but unless that aviator is paying for the service he or she will be locked out. If the next duty station of that aviator has a Company Purchased Plan their hours will not have to be re-entered. We simply move them to the new company that is using it.",
  },
];
