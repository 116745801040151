import React from "react";
import { Button } from "@material-ui/core";

export interface ClickButtonsProps {
  clickFunction: any;
  title: any;
  color?: "default" | "inherit" | "primary" | "secondary";
  fullWidth?: boolean;
  disabled?: boolean;
}

const ClickButton: React.SFC<ClickButtonsProps> = (props) => {
  return (
    <Button
      variant="contained"
      color={props.color}
      style={{ margin: "5px" }}
      onClick={() => {
        props.clickFunction();
      }}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
};

ClickButton.defaultProps = {
  color: "secondary",
  fullWidth: false,
  disabled: false,
};
export default ClickButton;
