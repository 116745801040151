import {
  FLIGHT_CREW,
  FLIGHT_LOCATION,
  FLIGHT_TAIL_NUMBER,
} from "../../../Tables/flight-table-names";

export const FLIGHTHEADERS = [
  { label: "ID", key: "FlyID" },
  { label: "Date", key: "fltDate" },
  { label: "Airframe Model", key: "model" },
  { label: "Duty", key: "fltType" },
  { label: "Seat", key: "fltSeat" },
  { label: "Day", key: "fltDay" },
  { label: "Night", key: "fltNight" },
  { label: "NVG", key: "NVGflt" },
  { label: "NVS", key: "fltNVS" },
  { label: "Hood", key: "fltHood" },
  { label: "Weather", key: "fltweather" },
  { label: "Mission", key: "mission" },
  { label: "Crew", key: FLIGHT_CREW },
  { label: "Location", key: FLIGHT_LOCATION },
  { label: "Tail Number", key: FLIGHT_TAIL_NUMBER },
  { label: "Remarks", key: "fltRemarks" },
];

export const PAGE_INFORMATION = {
  UPLOAD_PAGE: {
    title: "Army Flight Logbook CAFERS Upload Portal",
    description:
      "Upload your CAFERS report to the Army Flight Logbook. All of your flight data will be processed and added to your logbook.",
  },
};
