import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CardMedia,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/duty-position.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  })
);

export default function DutyPosition() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Duty Position</Typography>

      <p className={classes.paragraph}>
        This section is important for the fact that when you are logging flights
        as your primary or all airframe logbook you will be asked to pick a duty
        position. Since there are so many duty positions we allow you to create
        only the ones that you will be using/logging.
      </p>
      <Grid container>
        <Grid item xs={12}>
          <CardMedia
            className={classes.media}
            image={PrimaryPhoto}
            title="Photo"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="1. Select the Duty Position"
                secondary="This is where you are going to select what positions you are going to fly most. It will act as a filter later on when you go to add a flight."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="2. Add Button"
                secondary="This will add the Duty Position to your record. You will see the table below the dropdown fill in with positions you have previously added."
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="3. Delete"
                secondary="If you accidently added a position by mistake you can remove it."
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            You will only be able to add one duty position at a time. No repeats
            will be added.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} height={"auto"} />;
};
