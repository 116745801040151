import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CardMedia,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/semi-annual.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    media: {
      minHeight: "300px",
    },
  })
);

export default function SemiAnnual() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">SemiAnnual Section</Typography>

      <p className={classes.paragraph}>
        You will want to fill this section out as it will be required to get
        back proper calculations for your semiannual period.
      </p>
      <Grid container>
        <Grid item xs={12}>
          {/* <CardMedia
            className={classes.media}
            image={PrimaryPhoto}
            title="Photo"
          /> */}
          <Photo photo={PrimaryPhoto} />
        </Grid>
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="Start Date"
                secondary="This is the start date that you would like your flight logbook to start counting down the flight hours you have flown against your minimum requirements."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="End Date"
                secondary="This is the end date that you would like your flight logbook to stop counting the flight hours you have flown against your minimum requirements."
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Clear Dates"
                secondary="Clear the dates so you can enter new ones. This comes in handy if you need to adjust your begin and start dates because your current semiannual period is over."
              />
            </ListItem>
          </List>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} sm={6}>
          <p className={classes.paragraph}>
            <b>
              You will need to adjust these accordingly based on your RL status.
            </b>
          </p>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            After your semiannual period is over you will need to come back to
            this page and adjust it for the next 6 month period.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} height={"auto"} />;
};
