import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/flight-table.png";

export interface AdminInfoProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function LogbookTableFormat() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Flight Logbook Table View</Typography>

      <p className={classes.paragraph}>
        If you are on a mobile vs desktop computer/tablet you will get a
        different view. This module you will see the desktop/tablet view.
      </p>
      <Grid container xs={12} spacing={3}>
        <Photo photo={PrimaryPhoto} />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="1. Add A Flight"
                secondary="This button will throw a popup menu allowing you to enter flight information."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="2. Edit"
                secondary="That will allow you to edit that flight row of data. The popup menu will appear allowing you to enter/update the data."
              />
            </ListItem>
          </List>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} sm={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="3. Delete"
                secondary="This will delete the record from the database."
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            Once you delete a record there is not a way to bring it back. All
            deltes are final.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
