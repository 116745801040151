import {
  Paper,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DisplayTable from "../Inputs/DisplayTables";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    media: {
      minHeight: "300px",
    },
  })
);
export interface ProjectUpdateProps {}

const ProjectUpdate: React.SFC<ProjectUpdateProps> = () => {
  const classes = useStyles();
  const completedTasks = [
    {
      date: "20-JUN-2021",
      note: "Added Stipe Payment for Checkout",
      type: "Feature",
    },
    {
      date: "18-JUN-2021",
      note: "Fixed the display for showing you multiple types of models for currency requirements",
      type: "Fix",
    },
    {
      date: "12-MAR-2021",
      note: "Added a Currency Information Block in the Primary Aircraft Logbook section",
      type: "Feature",
    },
    {
      date: "10-MAR-2021",
      note: "Added PDF Download for -12 printout matching",
      type: "Feature",
    },
    {
      date: "24-OCT-2020",
      note: "Fixed Update Password - On the profile page",
      type: "Bug Fix",
    },
    {
      date: "20-OCT-2020",
      note: "You can now checkout your air time on the totals page. View total hours flown based on all airframes.",
      type: "Feature",
    },
    {
      date: "18-OCT-2020",
      note: "Fixed Navigation bar so that it disappears when you scroll. Giving you more space on mobile.",
      type: "Feature",
    },
    {
      date: "18-OCT-2020",
      note: "Added Date Checker on Flight Logbook to validate possible errors for aviators. (ex: blank dates)",
      type: "Feature",
    },

    {
      date: "18-OCT-2020",
      note: "Added forgot password to the login so that you can get your password automatically emailed to you.",
      type: "Feature",
    },
    {
      date: "11-OCT-2020",
      note: "Added CSV downloadable report for Logbook and Simulator Logbook",
      type: "Feature",
    },
    {
      date: "10-OCT-2020",
      note: "Fixed Contact Us page to email customer and support.",
      type: "Bug Fix",
    },
    {
      date: "07-OCT-2020",
      note: "Added requirements to personal profile to ensure that user is adding primary aircraft and duty positions for it to save.",
      type: "Bug Fix",
    },
    {
      date: "07-OCT-2020",
      note: "Added requirements to the fields for Date, Mode, Aircraft for logging flights.",
      type: "Bug Fix",
    },
    {
      date: "06-OCT-2020",
      note: "Launch of New Interface of Army Flight Logbook",
      type: "Initial Launch",
    },
  ];

  const futureTasks = [
    {
      progress: "Not Started",
      note: "Gunnery Tables Tracking",
    },
    {
      progress: "Not Started",
      note: "Canceled Flight Tracking",
    },
    {
      progress: "Not Started",
      note: "Increasing time on logging in",
    },
  ];

  const headers = ["Date", "Feature Added or Fixed", "Type"];
  const headers2 = ["Progress", "Features to Add"];

  const [taskFormated, setTaskFormated] = useState<string[][]>();
  const [futureTasksFormated, setFutureTasksFormated] = useState<string[][]>();
  useEffect(() => {
    let list: string[][] = [[""]];
    let futureList: string[][] = [[""]];
    completedTasks.forEach((task) =>
      list.push([task.date, task.note, task.type])
    );

    futureTasks.forEach((task) => futureList.push([task.progress, task.note]));
    setFutureTasksFormated(futureList);
    setTaskFormated(list);
  }, []);
  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Application History</Typography>

      <p className={classes.paragraph}>
        Below you will find what features were added and bugs that we fixed for
        the Army Flight Logbook.
      </p>
      <Grid container justify="space-around">
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={6}>
          {/* Column 1 */}
          <Typography variant="h6">Current</Typography>

          {taskFormated != undefined && (
            <DisplayTable headers={headers} data={taskFormated} />
          )}
        </Grid>
        {/* Column 2 */}

        <Grid item xs={12} md={5}>
          <Typography variant="h6">Planned Features</Typography>
          {futureTasksFormated != undefined && (
            <DisplayTable headers={headers2} data={futureTasksFormated} />
          )}{" "}
        </Grid>
        <Grid item xs={12}>
          {/* NOTES */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProjectUpdate;
