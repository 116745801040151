import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { GetRecords, isJwtExpired } from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";
import DisplayTable from "../../Inputs/DisplayTables";
import RadioButtonGroup from "../../Inputs/RadioButtonGroup";
import PieChart from "../../Inputs/PieChart";

export interface TotalsProps {
  token: string;
  setToken: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: "90vh",
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);

const NeoTotalPage: React.SFC<TotalsProps> = (props) => {
  const classes = useStyles();
  const dutyPositionHeaders = ["PI", "PC", "UT", "IP", "MP", "SP", "IE"];
  const primaryAircraftHeaders = [
    "",
    "Day",
    "Night",
    "NVG",
    "Hood",
    "Weather",
    "NVS",
  ];
  const apacheSpecificHeaders = ["Back Seat", "Front Seat"];

  const buttonGroupLabels = ["Airframe", "Model", "Duty", "Mode", "Mission"];

  //Primary Table
  const [primaryAircraftTableData, setPrimaryAircraftTableData] =
    useState<(number | string)[][]>();
  //Apache Backseat/Frontseat
  const [apacheSpecificTableData, setApacheSpecificTableData] =
    useState<(number | string)[][]>();
  // DutyTypes
  const [dutyPositionTableData, setDutyPositionTableData] =
    useState<(number | string)[][]>();

  // Totals from Fetch
  const [careerTotals, setCareerTotals] = useState<ICareerTotals>();

  //MissionTypes
  const [missionTypeResponse, setmissionTypeResponse] = useState<any>();
  const [missionTypeTableData, setMissionTypeTableData] =
    useState<(number | string)[][]>();
  const [missionTypeHeaders, setMissionTypeHeaders] = useState<string[]>();
  // Radio button group
  const [radioSelection, setRadioSelection] = useState<any>();
  const [radioTableData, setRadioTableData] = useState<(number | string)[][]>();
  const [radioTableHeaders, setRadioTableHeaders] = useState<string[]>();
  //Models
  const [modelTypeResponse, setModelTypeResponse] = useState<any>();
  const [modelTypeTableData, setModelTypeTableData] =
    useState<(number | string)[][]>();
  const [modelTypeHeaders, setModelTypeHeaders] = useState<string[]>();

  //Airframes
  const [airframesTypeResponse, setAirframesTypeResponse] = useState<any>();
  const [airframesTypeTableData, setAirframesTypeTableData] =
    useState<(number | string)[][]>();
  const [airframesTypeHeaders, setAirframesTypeHeaders] = useState<string[]>();
  const [careerTotalHoursFlown, setCareerTotalHoursFlown] = useState<number>(0);

  //Mode
  const [modeTypeResponse, setModeTypeResponse] = useState<any>();
  const [modeTypeTableData, setModeTypeTableData] =
    useState<(number | string)[][]>();
  const [modeTypeHeaders, setModeTypeHeaders] = useState<string[]>();

  //Duty Career Total
  const [careerTotalDutyTypeResponse, setCareerTotalDutyTypeResponse] =
    useState<any>();
  const [careerTotalDutyTypeTableData, setCareerTotalDutyTypeTableData] =
    useState<(number | string)[][]>();
  const [careerTotalDutyTypeHeaders, setCareerTotalDutyTypeHeaders] =
    useState<string[]>();

  function onload() {
    GetRecords(
      Endpoints.totals.careertotal,
      props.token,
      setCareerTotals,
      props.setToken
    );
    GetRecords(
      Endpoints.totals.missiontypes,
      props.token,
      setmissionTypeResponse,
      props.setToken
    );
    GetRecords(
      Endpoints.totals.model,
      props.token,
      setModelTypeResponse,
      props.setToken
    );
    GetRecords(
      Endpoints.totals.mode,
      props.token,
      setModeTypeResponse,
      props.setToken
    );
    GetRecords(
      Endpoints.totals.airframe,
      props.token,
      setAirframesTypeResponse,
      props.setToken
    );
    GetRecords(
      Endpoints.totals.duty,
      props.token,
      setCareerTotalDutyTypeResponse,
      props.setToken
    );
  }
  useEffect(() => {
    if (isJwtExpired(props.token)) {
      props.setToken("");
      return;
    }
    onload();
    radioSelectionSwitched();
  }, []);

  useEffect(() => {
    radioSelectionSwitched();
  }, [radioSelection]);

  useEffect(() => {
    if (careerTotals != undefined) {
      const { day, night, nvg, nvs, weather, hood } = careerTotals?.Flight;
      const simulator = careerTotals.Simulator;
      const primaryTableData = [
        "Aircraft",
        day,
        night,
        nvg,
        hood,
        weather,
        nvs,
      ];
      const simulatorTableData = [
        "Simulator",
        simulator.day,
        simulator.night,
        "",
        simulator.hood,
        simulator.weather,
        simulator.nvs,
      ];

      const combinedTableData = [
        [...primaryTableData],
        [...simulatorTableData],
      ];

      const apacheTableData = [
        [careerTotals.BackSeatTotals.total, careerTotals.FrontSeatTotals.total],
      ];

      const dutyPositionData = [
        [
          careerTotals.PImode.total,
          careerTotals.PCmode.total,
          careerTotals.UTmode.total,
          careerTotals.IPmode.total,
          careerTotals.MPmode.total,
          careerTotals.SPmode.total,
          careerTotals.IEMode.total,
        ],
      ];

      setPrimaryAircraftTableData(combinedTableData);
      setApacheSpecificTableData(apacheTableData);
      setDutyPositionTableData(dutyPositionData);
      console.log(primaryTableData);
    }
  }, [careerTotals]);

  useEffect(() => {
    if (missionTypeResponse === undefined) {
      return;
    }
    let { headers, tableData }: { headers: string[]; tableData: number[] } =
      parseResponse(missionTypeResponse);

    setMissionTypeHeaders(headers);
    setMissionTypeTableData([tableData]);
  }, [missionTypeResponse]);

  //Models
  useEffect(() => {
    if (modelTypeResponse == undefined) {
      return;
    }
    let { headers, tableData }: { headers: string[]; tableData: number[] } =
      parseResponse(modelTypeResponse);

    setModelTypeHeaders(headers);
    setModelTypeTableData([tableData]);
  }, [modelTypeResponse]);
  //Career Total Duty
  useEffect(() => {
    if (careerTotalDutyTypeResponse == undefined) {
      return;
    }
    let { headers, tableData }: { headers: string[]; tableData: number[] } =
      parseResponse(careerTotalDutyTypeResponse);

    setCareerTotalDutyTypeHeaders(headers);
    setCareerTotalDutyTypeTableData([tableData]);
  }, [careerTotalDutyTypeResponse]);
  //Airframes
  useEffect(() => {
    if (airframesTypeResponse == undefined) {
      return;
    }
    let { headers, tableData }: { headers: string[]; tableData: number[] } =
      parseResponse(airframesTypeResponse);
    let totalFlightTime = tableData.reduce((a, b) => a + b, 0);

    setAirframesTypeHeaders(headers);
    setAirframesTypeTableData([tableData]);
    setCareerTotalHoursFlown(totalFlightTime);
  }, [airframesTypeResponse]);

  //Mode
  useEffect(() => {
    if (modeTypeResponse == undefined) {
      return;
    }
    // Had to do it this way based on how the server sends the response.
    let headers: string[] = [];
    let tableData: number[] = [];
    const missionTypeObjectToArray = Object.keys(modeTypeResponse.CareerTotal);
    missionTypeObjectToArray.forEach((key) => {
      headers.push(key);
      tableData.push(modeTypeResponse.CareerTotal[key]);
    });

    setModeTypeHeaders(headers);
    setModeTypeTableData([tableData]);
  }, [modeTypeResponse]);

  function radioSelectionSwitched() {
    switch (radioSelection) {
      case "Duty":
        setRadioTableData(careerTotalDutyTypeTableData);
        setRadioTableHeaders(careerTotalDutyTypeHeaders);
        break;
      case "Mission":
        setRadioTableData(missionTypeTableData);
        setRadioTableHeaders(missionTypeHeaders);
        break;
      case "Model":
        setRadioTableData(modelTypeTableData);
        setRadioTableHeaders(modelTypeHeaders);
        break;
      case "Airframe":
        setRadioTableData(airframesTypeTableData);
        setRadioTableHeaders(airframesTypeHeaders);
        break;
      case "Mode":
        setRadioTableData(modeTypeTableData);
        setRadioTableHeaders(modeTypeHeaders);
        break;

      case undefined:
        setRadioTableData(undefined);
        break;

      default:
        setRadioTableData(undefined);
    }
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Primary Aircraft Calculations </Typography>

            <p className={classes.paragraph}>
              Listed below is based on your Primary Aircraft flight hours
            </p>

            {primaryAircraftHeaders !== undefined &&
            primaryAircraftTableData !== undefined ? (
              <DisplayTable
                headers={primaryAircraftHeaders}
                data={primaryAircraftTableData}
              />
            ) : (
              <></>
            )}
            <br />
            <Typography variant="h6">
              Career Totals based on Primary Aircraft
            </Typography>

            <p className={classes.paragraph}>
              Below is your career totals based on duty position and primary
              aircraft.
            </p>
            {dutyPositionHeaders !== undefined &&
            dutyPositionTableData !== undefined ? (
              <DisplayTable
                headers={dutyPositionHeaders}
                data={dutyPositionTableData}
              />
            ) : (
              <></>
            )}
          </Paper>

          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Totals Information</Typography>

            <p className={classes.paragraph}>
              If you feel that you are missing hours please try our Audit
              checker to see if there are any missing fields that could throw
              off the numbers.
            </p>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Career Totals (All Airframes)</Typography>

            <p className={classes.paragraph}>
              Here you can see your totals. The numbers are derived from your
              entire logbook all army airframes.
            </p>
            <p>
              The total hours that are captured in your logbook are:{" "}
              {careerTotalHoursFlown.toFixed(1)} hours. Which equates to{" "}
              {Math.floor(careerTotalHoursFlown / 24).toFixed(1)} days,{" "}
              {Math.floor(careerTotalHoursFlown % 24).toFixed(1)} hours, and{" "}
              {Math.floor(
                (careerTotalHoursFlown - Math.floor(careerTotalHoursFlown)) * 60
              ).toFixed(1)}{" "}
              minutes.
            </p>
            <p className={classes.paragraph}>Click to Select.</p>
            <RadioButtonGroup
              buttonLabels={buttonGroupLabels}
              buttonValues={buttonGroupLabels}
              setCurrentValue={setRadioSelection}
            />
            {radioTableHeaders !== undefined && radioTableData !== undefined ? (
              <DisplayTable headers={radioTableHeaders} data={radioTableData} />
            ) : (
              <></>
            )}
            {radioTableHeaders !== undefined && radioTableData !== undefined ? (
              <div className={"chart-container"}>
                <PieChart
                  dataArray={radioTableData[0]}
                  headerArray={radioTableHeaders}
                  chartInfo={
                    "Chart using data from your entire logbook on all airframes."
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Apache Specific Information:</Typography>

            <p className={classes.paragraph}>
              Here you will find seat history totals.
            </p>
            {apacheSpecificHeaders !== undefined &&
            apacheSpecificTableData !== undefined ? (
              <DisplayTable
                headers={apacheSpecificHeaders}
                data={apacheSpecificTableData}
              />
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default NeoTotalPage;

export interface Simulator {
  day: number;
  night: number;
  nvs: number;
  hood: number;
  weather: number;
}

export interface Flight {
  day: number;
  night: number;
  nvs: number;
  hood: number;
  nvg: number;
  weather: number;
}

export interface BackSeatTotals {
  total: number;
}

export interface FrontSeatTotals {
  total: number;
}

export interface LeftSeatTotals {
  total: number;
}

export interface RightSeatTotals {
  total: number;
}

export interface UTmode {
  total: number;
}

export interface PImode {
  total: number;
}

export interface PCmode {
  total: number;
}

export interface IPmode {
  total: number;
}

export interface MPmode {
  total: number;
}

export interface SPmode {
  total: number;
}

export interface IEMode {
  total: number;
}

export interface CareerTotals {
  total: number;
}

export interface ICareerTotals {
  Simulator: Simulator;
  Flight: Flight;
  BackSeatTotals: BackSeatTotals;
  FrontSeatTotals: FrontSeatTotals;
  LeftSeatTotals: LeftSeatTotals;
  RightSeatTotals: RightSeatTotals;
  UTmode: UTmode;
  PImode: PImode;
  PCmode: PCmode;
  IPmode: IPmode;
  MPmode: MPmode;
  SPmode: SPmode;
  IEMode: IEMode;
  CareerTotals: CareerTotals;
}
function parseResponse(responseToConvert: any) {
  let headers: string[] = [];
  let tableData: number[] = [];
  const missionTypeObjectToArray = Object.keys(responseToConvert);
  missionTypeObjectToArray.forEach((key) => {
    headers.push(key);
    tableData.push(responseToConvert[key].total);
  });
  return { headers, tableData };
}
