import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core/";

import AdminDataPhoto from "./images/admin-data.png";
import AdminInfo from "./AdminInfo";
import SemiAnnual from "./SemiAnnual";
import ApacheSpecific from "./ApacheSpecific";
import DutyPosition from "./DutyPosition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function PilotInformation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ borderRadius: "0", backgroundColor: "lightgray" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6">Pilots Admin Information</Typography>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <p className={classes.paragraph}>
                    This page is split up into several important sections.
                  </p>
                  <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        primary="Admin Data Section"
                        secondary="Where you can update your email, input your birth month, rank..etc"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Semiannual Start and End Dates"
                        secondary="For calculation purposes."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Aircraft Minimums"
                        secondary="Logbook needs to know this to properly calculate your logs ensuring that you are meeting your minimums."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Apache Specific Data"
                        secondary="If you fly the apache you will see more data that needs to be collected so it can mirror your ATP requirements."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Duty Positions"
                        secondary="Add your duty positions that you going to be flying."
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <AdminInfo />
            <Divider />
            <SemiAnnual />
            <Divider />
            <DutyPosition />
            <Divider />
            <ApacheSpecific />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              THIS SECTION WAS INTENTIONALLY LEFT BLANK
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
