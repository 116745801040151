import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/piechart.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function PieChartInformation() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Pie Chart Values</Typography>

      <p className={classes.paragraph}>
        The pie chart values are dynamic based on the logbook shown. If you are
        showing all your flights then your pie chart will be a representation of
        your entire logbook. If you are only seeing semiannual flights the pie
        chart will be a representation of your semiannual flights.
      </p>
      <Grid container xs={12} spacing={3}>
        <Photo photo={PrimaryPhoto} />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="Color Coded"
                secondary="There is a legend at the top of the piechart that will give you a visual color based on the mode of flight."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Hover Effect"
                secondary="You can hover your mouse over the pie chart and see the calculated total hours based on that mode."
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            Changing the data inside the logbook will update your pie chart
            automatically.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width="350px" height="auto" />;
};
