export function purchaseIndividual() {
  window.open(
    `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick-subscriptions&business=support%40armyflightlogbook.com&no_shipping=1&item_name=2+Year+Subscription+Logbook&item_number=logbook_twoyear&currency_code=USD&a3=49.00&p3=2&t3=Y&src=1&sra=1&return=https://www.armyflightlogbook.com/success.php&cancel_return=https://www.armyflightlogbook.com/purchase.php&notify_url=https://armyflightlogbook.com/slpw/pay_paypal/slpaypal.php`
  );
}
export function purchaseCompany() {
  window.open(
    `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick-subscriptions&business=support%40armyflightlogbook.com&no_shipping=1&item_name=1+Year+Company+Profile+&item_number=company_year&currency_code=USD&a3=199&p3=1&t3=Y&src=1&sra=1&notify_url=http://armyflightlogbook.com/slpw/pay_paypal/slpaypal.php`
  );
}

export function studentPurchase() {
  window.open(
    `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=support%40armyflightlogbook.com&no_shipping=1&item_name=Lifetime+ArmyFlightLogbook+-+Student+Edition&item_number=logbook_flightstudent&amount=45.00&currency_code=USD&return=https://www.armyflightlogbook.com/login&cancel_return=https://www.armyflightlogbook.com/purchase&notify_url=https://armyflightlogbook.com/slpw/pay_paypal/slpaypal.php`
  );
}
