import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Chip,
} from "@material-ui/core";
import { IFlight } from "../../SystemSettings/ProjectInterfaces";
import { DeleteRecord, FormatDate } from "../../SystemSettings/CommonLib";
import AddFlightLogModal from "./AddFlightLogModal";
import { Endpoints } from "../../SystemSettings/SystemSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
    table: {
      minWidth: 650,
    },
    bottom: {
      position: "sticky",
      bottom: "0",
    },
    centerCircle: {
      margin: "auto",
    },
    clearBG: {
      backgroundColor: "#111344",
      width: "100%",
    },
    cardSpacing: {
      padding: "1%",
    },
    buttonLineup: {
      marginLeft: "auto",
    },
    centerText: {
      textAlign: "left",
    },
    textColor: {
      color: theme.palette.text.secondary,
    },
  })
);

export interface IFlightCardProps {
  token: string;
  flightData: IFlight[];
  refreshTableGrid: any;
  openFlightModel: any;
  setFlightData: any;
  setToken: any;
}

type TableLogs = {
  id: number;
  Flight: string;
  Day: string;
  Night: string;
  Airframe: number;
};

export default function FlightCards(props: IFlightCardProps) {
  const [rawJson, setRawJson] = React.useState([]);
  const [simLogs, setSimLogs] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(45);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState();
  const [open, setOpen] = React.useState<boolean>(true);
  const [showEditFlightModel, setShowEditFlightModel] = React.useState(false);
  const [editArrayIndex, setEditArrayIndex] = useState(0);

  const [
    editRecordRowInformation,
    setEditRecordRowInformation,
  ] = React.useState<IFlight>();

  function closeEditFlightModel() {
    setShowEditFlightModel(false);
  }

  function editFlightRecord(flightRecord: IFlight, arrayIndex: number) {
    setShowEditFlightModel(true);
    setEditRecordRowInformation(flightRecord);
    setEditArrayIndex(arrayIndex);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // this has the typing for an event that happens on change in a react app (?)
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
    dense ? setRowsPerPage(15) : setRowsPerPage(25);
  };

  const editModal = (card: IFlight) => {
    setShowEdit(true);
  };

  const deleteModal = (log: number) => {
    setShowDelete(true);
    deleteFlightRecord(log);
  };

  function deleteFlightRecord(id: number) {
    const filteredList = () => {
      props.setFlightData(
        props.flightData.filter((flightRecord) => flightRecord.FlyID != id)
      );
    };
    DeleteRecord(
      Endpoints.tables.flightLog.delete,
      id,
      props.token,
      filteredList
    );
  }

  const closeModal = (modal: string) => {
    if (modal === "edit") setShowEdit(false);
    else if (modal === "delete") setShowDelete(false);
  };

  const classes = useStyles();
  const displayBadges = (card: IFlight) => {
    const {
      terrain_flight,
      bambi_bucket,
      sling_load,
      hoist,
      assault,
      gunnery,
      overwater,
      cmf,
      cross_country,
      atm_flight,
      multiship,
      flight_lead,
      instrumentapproach,
      holding_pattern,
    } = card;
    const cardArray = [
      { name: "terrain flight", value: terrain_flight },
      { name: "bambi bucket", value: bambi_bucket },
      { name: "slingload", value: sling_load },
      { name: "hoist", value: hoist },
      { name: "assault", value: assault },
      { name: "gunnery", value: gunnery },
      { name: "overwater", value: overwater },
      { name: "CMF", value: cmf },
      { name: "cross country", value: cross_country },
      { name: "atm flight", value: atm_flight },
      { name: "multiship", value: multiship },
      { name: "flight lead", value: flight_lead },
      { name: "instrument approach", value: instrumentapproach },
      { name: "holding patterns", value: holding_pattern },
    ];
    return cardArray
      .filter((list) => list.value == "TRUE")
      .map((card, index) => (
        <Chip
          size="small"
          label={card.name}
          color={index % 2 == 0 ? "primary" : "secondary"}
          style={{ marginRight: "10px" }}
        />
      ));
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.clearBG}>
        {props.flightData.map((card: IFlight, index: number) => (
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.cardSpacing}
            key={index}
          >
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography
                      variant="button"
                      component="h4"
                      className={classes.textColor}
                    >
                      Date/Duty/Seat
                    </Typography>
                    <Typography variant="h6" component="h3">
                      {FormatDate(card.fltDate)} - {card.fltType} -{" "}
                      {card.fltSeat}
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography align="right" variant="caption" component="h2">
                      ID: {card.FlyID}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      component="h4"
                      className={classes.textColor}
                    >
                      Flight Hours
                    </Typography>
                    <Typography variant="h6">
                      <Grid container>
                        <Grid item xs={6} className={classes.centerText}>
                          D: {card.fltDay}
                        </Grid>

                        <Grid item xs={6} className={classes.centerText}>
                          N: {card.fltNight}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          NS: {card.fltNVS}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          NG: {card.NVGflt}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          H: {card.fltHood}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          WX: {card.fltweather}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="button"
                          component="h4"
                          className={classes.textColor}
                        >
                          Remarks
                        </Typography>
                        {card.fltRemarks}
                        <br />
                        {displayBadges(card)}
                      </Grid>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}></Grid> */}
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  className={classes.buttonLineup}
                  variant="contained"
                  color="primary"
                  onClick={() => editFlightRecord(card, index)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    card.FlyID != undefined ? deleteModal(card.FlyID) : null
                  }
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AddFlightLogModal
        token={props.token}
        modelOpen={showEditFlightModel}
        setModelOpen={closeEditFlightModel}
        isEditFlight={true}
        recordInformation={editRecordRowInformation}
        title="Edit Flight Details"
        flightData={props.flightData}
        setFlightData={props.setFlightData}
        editArrayIndex={editArrayIndex}
        setToken={props.setToken}
      />
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}
