import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Chip,
} from "@material-ui/core";
import {
  IFlight,
  ISimulatorFlight,
} from "../../SystemSettings/ProjectInterfaces";
import { DeleteRecord, FormatDate } from "../../SystemSettings/CommonLib";
import AddFlightLogModal from "../FlightLogbook/AddFlightLogModal";
import AddSimulatorLogModal from "./AddSimLogModal";
import { Endpoints } from "../../SystemSettings/SystemSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
    table: {
      minWidth: 650,
    },
    bottom: {
      position: "sticky",
      bottom: "0",
    },
    centerCircle: {
      margin: "auto",
    },
    clearBG: {
      backgroundColor: "#111344",
      width: "100%",
    },
    cardSpacing: {
      padding: "1%",
    },
    buttonLineup: {
      marginLeft: "auto",
    },
    centerText: {
      textAlign: "left",
    },
    textColor: {
      color: theme.palette.text.secondary,
    },
  })
);

export interface ISimulatorCardProps {
  token: string;
  flightData: ISimulatorFlight[];
  refreshTableGrid: any;
  openFlightModel: any;
  setFlightData: any;
  setToken: any;
}

export default function SimulatorCards(props: ISimulatorCardProps) {
  const [rawJson, setRawJson] = React.useState([]);
  const [simLogs, setSimLogs] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(45);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState();
  const [open, setOpen] = React.useState<boolean>(true);
  const [showEditFlightModel, setShowEditFlightModel] = React.useState(false);
  const [editArrayIndex, setEditArrayIndex] = useState(0);

  const [
    editRecordRowInformation,
    setEditRecordRowInformation,
  ] = React.useState<ISimulatorFlight>();

  function closeEditFlightModel() {
    setShowEditFlightModel(false);
  }

  function editFlightRecord(
    flightRecord: ISimulatorFlight,
    arrayIndex: number
  ) {
    setShowEditFlightModel(true);
    setEditRecordRowInformation(flightRecord);
    setEditArrayIndex(arrayIndex);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const displayBadges = (card: ISimulatorFlight) => {
    const {
      lct_contact,
      lct_tactics,
      lct_instruments,
      lct_evaluation,
      lct_io_refresh,
      lct_emergency,
      lct_hud,
      lct_shipboard,
      lct_mountain,
      lct_gunnery,
      lct_cbrn,
    } = card;
    const cardArray = [
      { name: "contact", value: lct_contact },
      { name: "tactics", value: lct_tactics },
      { name: "instruments", value: lct_instruments },
      { name: "evaluation", value: lct_evaluation },
      { name: "io refresh", value: lct_io_refresh },
      { name: "emergency", value: lct_emergency },
      { name: "HUD", value: lct_hud },
      { name: "shipboard ops", value: lct_shipboard },
      { name: "mountain", value: lct_mountain },
      { name: "gunnery", value: lct_gunnery },
      { name: "cbrn", value: lct_cbrn },
    ];
    return cardArray
      .filter((list) => list.value == "TRUE")
      .map((card, index) => (
        <Chip
          size="small"
          label={card.name}
          color={index % 2 == 0 ? "primary" : "secondary"}
          style={{ marginRight: "10px" }}
        />
      ));
  };

  // this has the typing for an event that happens on change in a react app (?)
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
    dense ? setRowsPerPage(15) : setRowsPerPage(25);
  };

  const editModal = (card: IFlight) => {
    setShowEdit(true);
  };

  const deleteModal = (log: number) => {
    setShowDelete(true);
    deleteFlightRecord(log);
  };

  function deleteFlightRecord(id: number) {
    // Function called after deleting a row
    const filteredList = () => {
      props.setFlightData(
        props.flightData.filter((flightRecord) => flightRecord.Flightid != id)
      );
    };
    DeleteRecord(
      Endpoints.tables.simulator.delete,
      id,
      props.token,
      filteredList
    );
  }

  const closeModal = (modal: string) => {
    if (modal === "edit") setShowEdit(false);
    else if (modal === "delete") setShowDelete(false);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.clearBG}>
        {props.flightData.map((card: ISimulatorFlight, index: number) => (
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.cardSpacing}
            key={index}
          >
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography
                      variant="button"
                      component="h4"
                      className={classes.textColor}
                    >
                      Date/Duty/Seat
                    </Typography>
                    <Typography variant="h6" component="h3">
                      {FormatDate(card.lctDate)} - {card.lctType} -{" "}
                      {card.lctSeat}
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography align="right" variant="caption" component="h2">
                      ID: {card.Flightid}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="button"
                      component="h4"
                      className={classes.textColor}
                    >
                      Flight Hours
                    </Typography>
                    <Typography variant="h6">
                      <Grid container>
                        <Grid item xs={6} className={classes.centerText}>
                          D: {card.Day}
                        </Grid>

                        <Grid item xs={6} className={classes.centerText}>
                          N: {card.nite}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          NS: {card.lctNVS}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          NG: {card.lctNVG}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          H: {card.lctHood}
                        </Grid>
                        <Grid item xs={6} className={classes.centerText}>
                          WX: {card.lctWeather}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="button"
                          component="h4"
                          className={classes.textColor}
                        >
                          Remarks
                        </Typography>
                        {card.lctRemarks}
                        <br />
                        {displayBadges(card)}
                      </Grid>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}></Grid> */}
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  className={classes.buttonLineup}
                  variant="contained"
                  color="primary"
                  onClick={() => editFlightRecord(card, index)}
                >
                  Details
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    card.Flightid != undefined
                      ? deleteModal(card.Flightid)
                      : null
                  }
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AddSimulatorLogModal
        token={props.token}
        modelOpen={showEditFlightModel}
        setModelOpen={closeEditFlightModel}
        isEditFlight={true}
        recordInformation={editRecordRowInformation}
        title="Edit Flight Details"
        flightData={props.flightData}
        setFlightData={props.setFlightData}
        editArrayIndex={editArrayIndex}
        setToken={props.setToken}
      />
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}
