import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/two-logbooks.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function TwoLogbooks() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Selecting Your Logbook</Typography>

      <p className={classes.paragraph}>
        If you are wanting to focus on your primary airframe you can choose the
        primary aircraft book. If you are wanting to add flights to other
        airframes maybe because you are adding flights from earlier in your
        career you can go to the all airframe logbook.
      </p>
      <Grid container xs={12} spacing={3}>
        <Photo photo={PrimaryPhoto} />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="1. Primary Aircraft Logbook"
                secondary="This is an automatic filter of table to only show your flights from your primary airframe."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="2. All Airframes Logbook"
                secondary="Here you can add all airframes that you would like to include your primary one. That is the only difference between the logbooks."
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            One thing that you may notice when you go and add a flight from the
            All Airframe Logbook. Your dropdown menu for the Aircraft will be
            long. That is because we have to account for all airframes/models
            that the Army has in the inventory.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
