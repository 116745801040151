import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
});

export interface DisplayTableProps {
  data: any[];
  headers: any[];
}
// The data that can go in here can either be a single row of information [] or...
// it can be  set up in an array of an array [[col1,col2, col3], [col1, col2, col3]]
export default function DisplayTable(props: DisplayTableProps) {
  const classes = useStyles();

  function displayTable() {
    if (props.data.length > 0) {
      console.log(props.data);
      return props.data.map((row: any[], index: number) => (
        <TableRow key={index}>
          {row.map((column, index) => {
            return (
              <TableCell key={index} component="th" scope="row">
                {column}
              </TableCell>
            );
          })}
        </TableRow>
      ));
    } else {
      return <div></div>;
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {props.headers.map((header: React.ReactNode, index: number) => {
              return <TableCell key={index}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>{props.data ? displayTable() : <></>}</TableBody>
      </Table>
    </TableContainer>
  );
}
