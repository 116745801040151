import * as React from "react";
import { useState, useEffect } from "react";

import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
} from "@material-ui/core";
import CustomAccordian from "../Inputs/CustomAccordian";
import ClickButton from "../Inputs/PageButtons";
import { Alert } from "@material-ui/lab";
import CheckoutDialogBox from "../CheckOut/CheckOutDialogBox";
import { purchaseCompany } from "./paypalFunction";
import { faqData } from "./purchaseFaq";
import { useLocation } from "react-router-dom";
import MobilePhoto from "../../Assets/mobile-image.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: "white",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
  })
);

export default function Purchase(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const [showSpecialPricing, setShowSpecialPricing] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get("stickbuddy");
    const today = new Date();
    if (param === "true") {
      if (localStorage.getItem("stickbuddy")) {
        let stickbuddyDate = localStorage.getItem("stickbuddy");
        if (stickbuddyDate) {
          let stickDate = new Date(stickbuddyDate);
          let expirationDate = new Date(stickDate);
          expirationDate.setHours(expirationDate.getHours() + 3 * 24);

          if (today > expirationDate) {
            setShowSpecialPricing(false);
          } else {
            setShowSpecialPricing(true);
          }
        }
      } else {
        localStorage.setItem("stickbuddy", today.toString());
      }
      setShowSpecialPricing(true);
    } else {
      setShowSpecialPricing(false);
    }
  }, [location]);
  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={5}>
          <Paper className={classes.paper}>
            <img
              style={{ width: "100%" }}
              src="/static/images/flight-log-info.png"
              alt=""
            />
            <hr />
            <Typography variant="h6" align="center">
              Purchase Individual Flight Pass
            </Typography>
            <CheckoutDialogBox program="normal" buttonColor="primary" />

            <p>
              The online version of the flight logbook is not a subscription
              service. You’ll be charged $59. <br />
              The $59 will give you full access to the flight logbook service.
              Great price for a lot of tracking tools to assist you in your
              Aviation Career. You'll get constant access to your logbook and
              all the updates/upgrades we end up putting into this program. Upon
              completing a payment you must check your{" "}
              <b>email you used at checkout</b> for your username and password
              in order to start logging flights.
            </p>
            <ul>
              <li>Fast and Easy Setup</li>
              <li>Instant Access</li>
              <li>Great Support</li>
              <li>Designed by Vets</li>
              <li>Database Backups</li>
            </ul>
            <p>
              If you were a current customer who is on our subscription plan you
              can visit login to your paypal account and cancel the membership
              please visit the following link:{" "}
              <a
                style={{ textDecoration: "underline", color: "red" }}
                href="https://www.paypal.com/li/smarthelp/article/how-do-i-cancel-a-recurring-payment,-subscription,-or-automatic-billing-agreement-i-have-with-a-merchant-faq1067"
              >
                How to Change Subscription Plan
              </a>
              .
            </p>
            {showSpecialPricing ? (
              <Alert color="error">
                <Typography variant="h6">Stick Buddy Special</Typography>
                <p>
                  Welcome to the Stick Buddy Program - either you or your fellow
                  aviator shared this link with you to get special pricing. It's
                  only $40 as a one time fee. So grab this special deal. You
                  have 3 days to choose.
                </p>
                <CheckoutDialogBox
                  program="stickbuddy"
                  buttonColor="secondary"
                />
              </Alert>
            ) : (
              <Alert color="error">
                <Typography variant="h6">Flight Student Special</Typography>
                <p>
                  For the flight students out there that are just starting to
                  log their flights we have a very special offer for you. You
                  can pay a one time fee avoiding the annual subscription model.
                  It's just one time payment of $45 USD
                </p>
                <CheckoutDialogBox program="student" buttonColor="secondary" />
              </Alert>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Company Level Flight Pass</Typography>
            <p>
              We also offer a company level flight logbook pass. This gives the
              flight company the ability to track their aviator's progress,
              currency, and verifying that aviators are meeting their minimums.
            </p>
            <ul>
              <li>Make matching aircrews easy</li>
              <li>
                Keep track of the young PC's so you know when to send them on a
                track
              </li>
              <li>Avoid unnecessary PFE flights</li>
            </ul>
            <p>
              Purchase plan includes 20 aviator slots. If you need more send us
              a support message. $50 per 5 aviators in 5 aviator increments.
            </p>
            <ClickButton
              title={"Purchase Company Level Plan $199/YR for 20 Avaitors"}
              fullWidth={true}
              clickFunction={purchaseCompany}
            />
          </Paper>
          <Paper className={classes.paper}>
            <CustomAccordian data={faqData} />
          </Paper>
          <Alert
            severity="info"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h6">
              Do you own the mobile app already?
            </Typography>
            <p>
              If you already own the mobile app and are a user get the website
              version as an Addon. It's just one time payment of <b>$25 USD</b>.
              If you already have the website version and only want the mobile
              version you can get it for $19.99 in the app store.
            </p>
            <Photo photo={MobilePhoto} />
            <p>
              Benefits of the Web Version is you are able to see it on your
              desktop computer with a larger screen. The website also allows for
              printing/download reports. The web version also has a -12 like
              print off that makes it super easy to make sure that CAFERS is
              matching your logbook. The mobile version is great for those that
              want to log flights on the go and keeping track of your
              stats/currency.
            </p>
            <CheckoutDialogBox
              program="discount"
              buttonColor="secondary"
              buttonTitle="Purchase Web Version Addon for MOBILE ONLY APP USERS"
            />
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}
const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} />;
};
