import React from "react";
// import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import { Doughnut, Bar } from "react-chartjs-2";
// Line
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { APIURL } from "../../Helpers/environment";
import FlightProgress from "./FlightProgress";
import SimulatorProgress from "./SimulatorProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type Flight = {
  AMC: string;
  FlyID: number;
  NVGflt: string;
  Name: string;
  SEF: string;
  ac_model: number;
  airframe_id: string;
  airport_from: string;
  airport_to: string;
  assault: string;
  atm_flight: string;
  bambi_bucket: string;
  cmf: string;
  crew: string;
  cross_country: string;
  cross_country_hrs: number;
  doublebag: string;
  fadec: string;
  flight_lead: string;
  fltDate: string;
  fltDay: string;
  fltHood: string;
  fltNVS: string;
  fltNight: string;
  fltRemarks: string;
  fltSeat: string;
  fltType: string;
  fltweather: string;
  gunnery: string;
  hoist: string;
  holding_pattern: string;
  instrument_apporach: number;
  instrument_take_offs: number;
  instrumentapproach: string;
  landings: number;
  location: string;
  mission: string;
  multiship: string;
  night_landings: number;
  overwater: string;
  personid: number;
  sling_load: string;
  tail_number: string;
  take_offs: number;
  terrain_flight: string;
};

type Simulator = {
  Flightid: number;
  lctDate: string;
  Day: string;
  nite: string;
  lctNVS: string;
  lctHood: string;
  lctSeat: string;
  lctType: string;
  lctRemarks: string;
  Name: string;
  personid: number;
  airframe_id: number;
  tail_number?: number;
  recovery?: any;
  launch?: any;
  lctNVG: string;
  lctWeather: string;
  lct_contact: string;
  lct_tactics: string;
  lct_instruments: string;
  lct_evaluation: string;
  lct_io_refresh: string;
  lct_emergency: string;
  lct_hud: string;
  lct_shipboard: string;
  lct_mountain: string;
  lct_gunnery: string;
  lct_cbrn: string;
};

export default function DropdownTest(props: any) {
  const classes = useStyles();

  interface response {
    model: string;
    id: number;
    airframe_id: number;
  }

  const [result, setResult] = React.useState<response[]>();
  // const [selected, setSelected] = React.useState<number>();

  const [flightRecord, setFlightRecord] = React.useState<Flight[]>([]);

  const [grandTotal, setGrandTotal] = React.useState(0);
  const [totalChecker, setTotalCheck] = React.useState(0);

  const [showFlightWheel, setShowFlightWheel] = React.useState<boolean>(false);

  const addTotals = (newNum: number) => {
    let tempTotal = grandTotal;

    let tempCheck = totalChecker;
    if (tempCheck > 1) {
      setTotalCheck(0);
      setGrandTotal(0);
    } else {
      setGrandTotal(tempTotal + newNum);
      setTotalCheck(tempCheck + 1);
    }

    console.log(newNum);
    console.log(tempTotal);
    console.log("THIS IS THE ADDITION FUNCTION");
  };

  // const findValue = () => {
  //     let select: any = document.getElementById('craft')
  //     let val = select.value;

  // };

  const getDropdown = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", props.token);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      // redirect: 'follow'
    };

    fetch(`${APIURL}lists/airframe`, requestOptions)
      .then((response: any) => response.json())
      .then((json: any) => {
        setResult(json);
        console.log(json);
      })
      .catch((error: any) => console.log("error", error));
  };

  const getAll = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", props.token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      // redirect: 'follow'
    };

    fetch("http://localhost:5000/flightlog/getall", requestOptions)
      .then((response) => {
        console.log(response.status);
        return response.json();
      })
      .then((result) => setFlightRecord(result))
      .catch((error) => console.log("error", error));
  };

  const createTestData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", props.token);
    myHeaders.append("Content-Type", "application/json");

    let raw: object = {
      data: {
        fltDay: "2",
        fltNight: "3",
        fltNVS: "1",
        fltHood: "5",
        fltType: "PI",
        fltSeat: "Back Seat",
        NVGflt: "1",
        fltRemarks: "ns/NVG reset",
        location: "USA/WA",
        fltDate: "2019-10-08T04:00:00.000Z",
        mission: "T",
        fltweather: "0",
        airframe_id: 1,
        terrain_flight: "False",
        bambi_bucket: "False",
        sling_load: "False",
        hoist: "False",
        assault: "False",
        gunnery: "False",
        overwater: "False",
        cmf: "False",
        cross_country: "False",
        atm_flight: "False",
        multiship: "False",
        flight_lead: "False",
        instrumentapproach: "False",
        holding_pattern: "False",
        tail_number: "1703177",
        ac_model: 32,
        take_offs: 0,
        landings: 0,
        night_landings: 0,
        instrument_take_offs: 0,
        instrument_apporach: 0,
        cross_country_hrs: 0,
        airport_from: "KGRF",
        airport_to: "LOCAL",
        crew: "Almendarez",
        fadec: "False",
        SEF: "False",
        doublebag: "False",
        AMC: "False",
      },
    };

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      // redirect: 'follow'
    };

    // console.log(Object.entries(raw))

    fetch("http://localhost:5000/flightlog/add", requestOptions)
      .then((response) => {
        console.log(response.status);
        return response.json();
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  React.useEffect(() => {
    getDropdown();
    getAll();
  }, []);

  // data layout for use in the graph, this one seems to work for the line graph pretty well, though the doughnut will require some experimentation in order to get the correct format
  const lineData: object = {
    labels: ["Jan", "Feb", "March", "April", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Second dataset",
        data: [53, 79, 65, 45, 24, 85],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const barData: object = {
    labels: ["Yellow", "Green", "Red"],
    datasets: [
      // {
      //     label: "First dataset",
      //     data: [33, 53, 85, 41, 44, 65],
      //     fill: true,
      //     backgroundColor: "rgba(75,192,192,0.2)",
      //     borderColor: "rgba(75,192,192,1)"
      // },
      {
        label: "Second dataset",
        data: [33, 33, 33],
        fill: true,
        // borderColor: "#742774"
        backgroundColor: ["red", "yellow", "green"],
      },
    ],
  };

  // legend to label the graph
  // this will change where and if the legend will display (?)
  const legend: object = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };

  // misc options for the graph
  // there are probably more options here, try to find more
  const lineOptions: object = {
    title: {
      display: true,
      text: "Chart Title",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      ],
    },
  };

  const barOptions: object = {
    title: {
      display: true,
      text: "Example Circle",
    },
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          style={{ textAlign: "center", marginTop: "2em", marginBottom: "2em" }}
          item
          sm
          xs={12}
        >
          {showFlightWheel ? (
            <FlightProgress
              grandTotal={grandTotal}
              addTotal={addTotals}
              token={props.token}
              flightData={flightRecord}
            />
          ) : (
            <CircularProgress size="5em" thickness={5} />
          )}
        </Grid>
        <Grid
          style={{ textAlign: "center", marginTop: "2em", marginBottom: "2em" }}
          item
          sm
          xs={12}
        >
          <SimulatorProgress
            grandTotal={grandTotal}
            addTotal={addTotals}
            token={props.token}
          />
        </Grid>
        {/* BASIC */}
        {/* <Line data={data} /> */}

        {/* WITH OPTIONS, LEGEND, AND DATA */}
        {/* <Line data={data} legend={legend} options={options}/> */}
        {/* <Grid item xs={12} sm={6}>
                    <Bar data={lineData} legend={legend} options={lineOptions} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Doughnut data={barData} legend={legend} options={barOptions} />
                </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
