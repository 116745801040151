import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";

export interface inputFieldProps {
  fieldName: string;
  valueSetter: any;
  currentValue: string | number;
  disabled?: boolean;
  styles?: object;
  fullWidth?: boolean;
  onBlur?: any;
  style?: object;
}

export interface passwordFieldProps {
  fieldName: string;
  valueSetter: any;
  currentValue: string | number;
  fullWidth?: boolean;
  disabled?: boolean;
  styles?: object;
  style?: object;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "auto",
      },
    },
    flight: {
      margin: theme.spacing(1),
      width: 100,
    },
    textArea: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);

const passwordStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "auto",
      },
    },
    flight: {
      margin: theme.spacing(1),
      width: 100,
    },
    textArea: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);

function FltInputField(props: inputFieldProps) {
  const classes = useStyles();

  const fullWidth = props.fullWidth && { width: "100%" };

  return (
    // <Box style={{ ...props.styles }}>
    <TextField
      className={classes.root}
      id={"outlined-basic" + Math.floor(Math.random() * 100) + 1}
      label={props.fieldName}
      variant="outlined"
      value={props.currentValue}
      onChange={(e) => props.valueSetter(e.target.value)}
      fullWidth={props.fullWidth}
      style={{ ...fullWidth, ...props.style }}
      {...props}
    />
    // </Box>
  );
}

function FltNumbericField(props: inputFieldProps) {
  const classes = useStyles();
  function setValue(e: any) {
    props.valueSetter(
      e.target.value.length > 0 ? Math.floor(+e.target.value * 10) / 10 : ""
    );
  }
  return (
    <Fragment>
      <TextField
        className={classes.flight}
        type="number"
        id="outlined-basic"
        label={props.fieldName}
        variant="outlined"
        value={props.currentValue}
        onChange={(e) => setValue(e)}
        disabled={props.disabled}
      />
    </Fragment>
  );
}

function FltTextAreaField(props: inputFieldProps) {
  const classes = useStyles();
  function setValue(e: any) {
    props.valueSetter(e.target.value);
  }
  return (
    <Fragment>
      <TextField
        className={classes.textArea}
        id="outlined-basic"
        multiline
        label={props.fieldName}
        variant="outlined"
        rows={4}
        value={props.currentValue}
        onChange={(e) => setValue(e)}
        disabled={props.disabled}
      />
    </Fragment>
  );
}

function FltInputFieldPassword(props: passwordFieldProps) {
  const classes = passwordStyle();
  const fullWidth = props.fullWidth && { width: "100%" };

  return (
    <Fragment>
      <TextField
        className={classes.root}
        id={"outlined-basic" + Math.floor(Math.random() * 100) + 1}
        label={props.fieldName}
        variant="outlined"
        value={props.currentValue}
        onChange={(e) => props.valueSetter(e.target.value)}
        type="password"
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        style={{ ...fullWidth, ...props.style }}
      />
    </Fragment>
  );
}
FltInputFieldPassword.defaultProps = {
  color: "secondary",
  fullWidth: false,
  styles: {},
};

FltInputField.defaultProps = {
  disabled: false,
};

FltNumbericField.defaultProps = {
  disabled: false,
};

export {
  FltInputField,
  FltNumbericField,
  FltTextAreaField,
  FltInputFieldPassword,
};
