import React, { useState, useEffect } from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { IFlight } from "../../SystemSettings/ProjectInterfaces";
import { Aircraft } from "../../SystemSettings/SystemSettings";
import { FormatDate } from "../../SystemSettings/CommonLib";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 15;
const COLN_WIDTH = (100 - COL1_WIDTH) / 6;
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
export interface PDFDownloaderProps {
  flightData: IFlight[];
}
const PDFDownloader = (props: PDFDownloaderProps) => {
  function displayRows() {
    return props.flightData.map((flight: IFlight) => {
      const {
        fltDay,
        fltHood,
        fltNVS,
        fltNight,
        fltSeat,
        fltDate,
        fltweather,
        airframe_id,
        NVGflt,
        mission,
        fltType,
      } = flight;
      let flightArray: any[] = [];
      let modeColumns = [
        "fltDay",
        "fltHood",
        "fltNVS",
        "fltNight",
        "fltweather",
        "NVGflt",
      ];
      type K = keyof typeof flight;
      Object.keys(flight).forEach((key) => {
        if (flight[key as K] !== "0" && flight[key as K] !== undefined) {
          let modeName = "";

          if (modeColumns.includes(key)) {
            let airframeMatch = Aircraft.filter(
              (airframe) => airframe.value == airframe_id
            );
            if (key == "fltDay") modeName = "D";
            if (key == "fltHood") modeName = "H";
            if (key == "fltNVS") modeName = "NS";
            if (key == "fltNight") modeName = "N";
            if (key == "NVGflt") modeName = "NG";
            if (key == "fltweather") modeName = "W";
            flightArray.push(
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{FormatDate(fltDate)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {airframeMatch[0].airframe_name}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{fltSeat}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{mission}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{fltType}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{modeName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{flight[key as K]}</Text>
                </View>
              </View>
            );
          }
        }
      });
      return flightArray;
    });
  }
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          ~ ArmyFlightLogbook.com ~
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1Header}>
              <Text style={styles.tableCellHeader}>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Airframe</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Seat</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Mission</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Type</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Mode</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Hours</Text>
            </View>
          </View>
          {displayRows()}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDFDownloader;
// ReactPDF.render(<PDFDownloader />, `${__dirname}/example.pdf`);
