import React, { useEffect, useState } from "react";

import { Pie } from "react-chartjs-2";

export interface PieChartProps {
  dataToDisplay?: any;
  dataArray?: (number | string)[];
  headerArray?: string[];
  chartInfo: string;
}

const PieChart: React.SFC<PieChartProps> = (props) => {
  const [dataLabels, setDataLabels] = useState([""]);
  const [dataNumbers, setDataNumbers] = useState<(number | string)[]>();

  useEffect(() => {
    if (props.dataToDisplay != undefined) {
      let keys = [];
      let values: number[] = [];
      let arrayObject: [string, number][] = Object.entries(props.dataToDisplay);

      console.log(props.dataToDisplay);
      for (const [key, value] of arrayObject) {
        keys.push(key);
        values.push(value);
      }
      setDataLabels(keys);
      setDataNumbers(values);
    } else {
      setDataLabels(props.headerArray ? props.headerArray : [""]);
      setDataNumbers(props.dataArray);
    }
  }, [props.dataToDisplay, props.headerArray, props.dataArray]);
  const data = {
    labels: dataLabels,
    datasets: [
      {
        data: dataNumbers,
        backgroundColor: [
          "#4d784e",
          "#6ea171",
          "#e1d798",
          "#675645",
          "#424756",
        ],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  return (
    <>
      <Pie
        data={data}
        height={100}
        width={50}
        options={{ maintainAspectRatio: false, aspectRatio: 4 }}
      />
    </>
  );
};

export default PieChart;
