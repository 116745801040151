import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ClickButton from "../../Inputs/PageButtons";
import { IFlight } from "../../SystemSettings/ProjectInterfaces";
import { DeleteRecord, FormatDate } from "../../SystemSettings/CommonLib";
import { AirFrameModels, Endpoints } from "../../SystemSettings/SystemSettings";
import { TablePagination } from "@material-ui/core";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddFlightLogModal from "./AddFlightLogModal";

const useStyles = makeStyles({
  table: {
    minWidth: 375,
  },
});

export interface IFlightTableProps {
  token: string;
  flightData: IFlight[];
  refreshTableGrid: any;
  openFlightModel: any;
  setFlightData: any;
  resetTablePageNumber: any;
  setResetTablePageNumber: any;
  setToken: any;
}

export default function FlightTable(props: IFlightTableProps) {
  const classes = useStyles();
  const rowHeaders = [
    "Date",
    "D",
    "N",
    "NS",
    "NG",
    "H",
    "Wx",
    "Seat",
    "DS",
    "Airframe",
    "Edit/Delete",
  ];

  const [editRecordRowInformation, setEditRecordRowInformation] = useState<
    IFlight
  >();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showEditFlightModel, setShowEditFlightModel] = useState(false);
  const [editArrayIndex, setEditArrayIndex] = useState(0);

  function deleteFlightRecord(id: number) {
    const filteredList = () => {
      props.setFlightData(
        props.flightData.filter((flightRecord) => flightRecord.FlyID != id)
      );
    };
    DeleteRecord(
      Endpoints.tables.flightLog.delete,
      id,
      props.token,
      filteredList
    );
  }
  useEffect(() => {
    console.log(props.flightData);
  }, [props.flightData]);

  function closeEditFlightModel() {
    setShowEditFlightModel(false);
  }

  function editFlightRecord(flightRecord: IFlight, arrayIndex: number) {
    setShowEditFlightModel(true);
    setEditRecordRowInformation(flightRecord);
    setEditArrayIndex(arrayIndex);
  }
  // They clicked the button on page to either get all flights, search by date, or search by semiannual. We needed a way to reset the page number back to zero.
  useEffect(() => {
    if (props.resetTablePageNumber) {
      setPage(0);
      props.setResetTablePageNumber(false);
    }
  }, [props.resetTablePageNumber]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function getAirframe(airframeID: number) {
    const filteredAirframe = AirFrameModels.filter(
      (res) => Number(res.value) == airframeID
    );

    return filteredAirframe[0].text ? filteredAirframe[0].text : "";
  }
  function displayTable() {
    if (props.flightData.length > 0) {
      return props.flightData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: IFlight, index: number) => (
          <TableRow key={"Flight" + index}>
            <TableCell component="th" scope="row">
              {FormatDate(row.fltDate)}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltDay}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltNight}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltNVS}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.NVGflt}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltHood}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltweather}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltSeat}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.fltType}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.ac_model > 0 ? getAirframe(+row.ac_model) : ""}
            </TableCell>
            <TableCell align="right">
              <ClickButton
                title={<BorderColorOutlinedIcon />}
                clickFunction={() =>
                  row.FlyID !== undefined
                    ? editFlightRecord(row, page * rowsPerPage + index)
                    : alert("No FlyID")
                }
              />
              <ClickButton
                title={<DeleteForeverOutlinedIcon />}
                clickFunction={() =>
                  row.FlyID !== undefined ? deleteFlightRecord(row.FlyID) : null
                }
                color="primary"
              />
            </TableCell>
          </TableRow>
        ));
    } else {
      return <></>;
    }
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {rowHeaders.map((header, index) => (
                <TableCell key={"header" + index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{props.flightData ? displayTable() : <></>}</TableBody>
        </Table>
        <TablePagination
          count={props.flightData ? props.flightData.length : 0}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <AddFlightLogModal
        token={props.token}
        modelOpen={showEditFlightModel}
        setModelOpen={closeEditFlightModel}
        isEditFlight={true}
        recordInformation={editRecordRowInformation}
        title="Edit Flight Details"
        flightData={props.flightData}
        setFlightData={props.setFlightData}
        editArrayIndex={editArrayIndex}
        setToken={props.setToken}
      />
    </div>
  );
}
