import { APIURL as API_URL } from "../Helpers/environment";

import axios, { AxiosError } from "axios";
import { IUAVCalculationResponse } from "./uavcalculation.interface";
import { IUAVSemiannualResponse } from "./uavsemiannual.interface";

export const REPORTS_API = {
  nvs_currency: "calculations/nvscurrency",
  semi_annual_report: "calculations/semi-annual",
  aircraft_currency: "calculations/currency-mobile",
  uas_calculation: "uas-calculations/get-data",
  uas_semi_annual: "uas-calculations/semi-annual",
};

export async function api_aircraft_currency_report(token: string) {
  try {
    let url = API_URL + REPORTS_API.aircraft_currency;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    // console.log(url);
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.log("error from api_aircraft_currency_report", error);
  }
}

export async function api_uas_semi_annual_report(token: string) {
  let url = API_URL + REPORTS_API.uas_semi_annual;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  // console.log(url);
  try {
    const response = await axios.get(url, { headers });

    if (response.data === null || !response.data?.reports) return [];
    return formatUAVSemiannualResponse(response.data.reports);
  } catch (error: any) {
    console.log("error from api_uas_calculation", error);
    return formatErrorMessage(error);
  }
}

export async function api_uas_calculation(token: string) {
  const NO_FLIGHT_DATA = [
    {
      row: ["No Data Available"],
      styles: [null, null],
    },
  ];
  try {
    let url = API_URL + REPORTS_API.uas_calculation;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    // console.log(url);
    const response = await axios.get(url, { headers });
    if (response.data === null) return NO_FLIGHT_DATA;
    return formatUAVResponse(response.data);
  } catch (error: any) {
    console.log("error from api_uas_calculation", error);
    return formatErrorMessage(error);
  }
}

function formatUAVResponse(data: IUAVCalculationResponse) {
  // Loop through data object and extract keys into an array.
  const dataKeys = Object.keys(data);

  let response = dataKeys.map((key) => {
    let highlight = null;
    if (data[key].daysRemaining < 15) {
      highlight = "error";
    } else if (data[key].daysRemaining < 30) {
      highlight = "warn";
    }
    return {
      row: [data[key].title, data[key].date, data[key].daysRemaining],
      styles: [null, null, highlight],
    };
  });

  return response;
}

export const formatUAVSemiannualResponse = (data: IUAVSemiannualResponse[]) => {
  // Loop through data object and extract keys into an array.

  let response = data.map((report) => {
    let highlight = null;

    if (report.totalRemaining == "0") {
      highlight = "success";
    }

    return {
      row: [report.title, report.totalRequired, report.totalRemaining],
      styles: [null, null, highlight],
    };
  });

  return response;
};

function formatErrorMessage(error: AxiosError) {
  return [
    {
      row: ["Error in Reading Data - Contact Support"],
      styles: [null, null],
    },
    {
      row: ["Message from Server: " + error.message],
      styles: [null, null],
    },
  ];
}
