import React, { useState } from "react";
import "./Login.css";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { APIURL } from "../../Helpers/environment";
import loginLogo from "../../Assets/login-logo.png";
import { PostRequest } from "../SystemSettings/CommonLib";
import { Endpoints } from "../SystemSettings/SystemSettings";
import { FltInputField, FltInputFieldPassword } from "../Inputs/InputFields";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10%",
  },
  card: {
    maxWidth: 400,
    minWidth: 300,
    textAlign: "center",
    marginBottom: "100%",
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "",
    maxWidth: 400,
  },
  inputStyle: {
    fontSize: "16pt",
  },
  appBar: {
    minWidth: 300,
    maxWidth: 400,
  },
}));

export default function Login(props: any) {
  const classes = useStyles();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let signin = () => {
    setIsLoading(true);
    fetch(`${APIURL}user/signin`, {
      method: "POST",
      body: JSON.stringify({
        user: { username: username.toLowerCase(), password: password },
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.hasOwnProperty("error")) {
          setIsLoading(false);
          alert(json.error);
        } else {
          tokenExtraction(json);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Error: " + err);
        console.error("Error: " + err);
      });
  };

  function emailPassword() {
    if (!username) {
      return;
    }
    const body = { email: username };
    setIsLoading(true);

    PostRequest(Endpoints.email.passwordReset, "", body, complete);

    function complete(response: any) {
      if (response.hasOwnProperty("error")) {
        alert(response.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      alert("Email Sent");
    }
  }
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    signin();
  }

  let tokenExtraction = (account: any) => {
    if (account.sessionToken) {
      props.setToken(account.sessionToken);
      createCookie(account.user);
      console.log(account.user.SemiMins);
    } else {
      console.log(account);
    }
  };

  let createCookie = (user: any) => {
    // let date = new Date();
    // console.log(user)
    let today = Date.now();
    let week = 5 * 1000; //5 seconds testing
    if (user) {
      document.cookie = `Name=${user.Name}; expires=${today + week}`;
      document.cookie = `id=${user.id}; expires=${today + week}`;
      // document.cookie = `Name=${user.id}`
    }
  };

  return (
    // <Container>
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={10} sm={5} md={4} lg={3}>
          <AppBar position="static" color="primary" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <Tab label="Sign In" {...a11yProps(0)} />
              <Tab label="Forgot Password" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={0} justify="space-around">
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={3}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt="Remove Before Flight"
                      height="100"
                      image={loginLogo}
                      title="Remove Before Flight"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="span">
                        Sign In
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        gutterBottom
                      >
                        Sign in to access your flight logbook.
                      </Typography>
                      <form onSubmit={handleSubmit}>
                        <FltInputField
                          fieldName="Email/Username"
                          valueSetter={setUsername}
                          currentValue={username}
                          fullWidth={true}
                        />

                        <br />
                        <FltInputFieldPassword
                          fieldName="Password"
                          valueSetter={setPassword}
                          currentValue={password}
                          fullWidth={true}
                        />

                        <br />
                        {isLoading ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          <Button color="primary" onClick={() => signin()}>
                            SIGN IN
                          </Button>
                        )}
                      </form>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={0} justify="space-around">
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="span">
                        Email Password
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        gutterBottom
                      >
                        Fill in email address and submit. You will get an email
                        with your password.
                      </Typography>
                      <hr />
                      <form onSubmit={handleSubmit}>
                        <TextField
                          label="Email/Username"
                          type="email"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          fullWidth={true}
                        />

                        {isLoading ? (
                          <CircularProgress color="secondary" />
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => emailPassword()}
                          >
                            Email my password
                          </Button>
                        )}
                      </form>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </div>

    //
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
