import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CardMedia,
} from "@material-ui/core/";

import AdminDataPhoto from "./images/admin-data.png";

export interface AdminInfoProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  })
);

const AdminInfo: React.SFC<AdminInfoProps> = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Pilots Admin Information</Typography>

      <p className={classes.paragraph}>
        Some of these fields will be required for you to fill in while others
        are more important if you are involved in a Company Tracking system for
        reporting purposes.
      </p>
      <Grid container>
        <CardMedia
          className={classes.media}
          image={AdminDataPhoto}
          title="Photo"
        />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="Name"
                secondary="Make sure you have this as last name, first name. This is important when it comes to company rosters and the reporting that they get."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Email"
                secondary="This is what will be used for logging in each time. Only one email per account."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Rank"
                secondary="This field is more for reporting purposes on a company report system."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="FAC Level"
                secondary="Select the FAC level (FAC 1, FAC 2, FAC 3). This field is more for reporting purposes on a company report system."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Birth Month"
                secondary="Select the month that you were born. More for reporting purposes on a company report system."
              />
            </ListItem>
          </List>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} sm={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="Primary Aircraft"
                secondary="The logbook allows only one primary aircraft per logbook. Select the one you fly and when you go to log your flights - it will prompt you with aircraft models that fit your airframe."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="RL Status"
                secondary="More for reporting purposes on a company report system."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Save/Update Button"
                secondary="This will commit all saves to the database."
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>
          <p className={classes.paragraph}>
            Please make sure that you are choosing a primary aircraft before you
            start logging flights.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AdminInfo;

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width={"100%"} height={"auto"} />;
};
