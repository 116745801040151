import * as React from "react";
import { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
  CardNumberElement,
  CardCvcElement,
  PaymentRequestButtonElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import ClickButton from "../Inputs/PageButtons";
import axios, { AxiosError } from "axios";
import { APIURL } from "../../Helpers/environment";
import {
  PaymentMethodCreateParams,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
} from "@stripe/stripe-js";
import { getPrice } from "./getPrice";

export interface CheckOutFormProps {
  closeDialog: Function;
  program: "student" | "discount" | "normal" | "stickbuddy";
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: "0",
    },
  })
);

const iframeStyles = {
  base: {
    margin: "15px",
    color: "black",
    fontSize: "16px",
    iconColor: "black",
    "::placeholder": {
      color: "#B5B5B5",
    },
  },
  invalid: {
    iconColor: "red",
    color: "red",
  },
  complete: {
    iconColor: "#cbf4c9",
    color: "green",
  },
};

const cardElementOpts: StripeCardElementOptions = {
  iconStyle: "solid",
  style: iframeStyles,
};

const CheckOutForm: React.SFC<CheckOutFormProps> = (props) => {
  const [formStatus, setFormStatus] = useState({
    card: false,
    cvc: false,
    expiration: false,
  });
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [formError, setFormError] = useState({ name: false, email: false });
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const price = getPrice(props.program);

  const handleCardDetailsChange = (ev: StripeCardElementChangeEvent) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError("");
  };

  const handleFormSubmit = async () => {
    setProcessingTo(true);
    const cardElement = elements?.getElement("cardNumber");
    const { card, cvc, expiration } = formStatus;
    try {
      if (!fullName || !email) throw new Error("Email or Full Name is Blank");
      if (!card) throw new Error("Please enter card number");
      if (!cvc) throw new Error("Please enter cvc");
      if (!expiration) throw new Error("Please enter expiration date of card");

      const billingDetails: PaymentMethodCreateParams.BillingDetails = {
        name: fullName,
        email,
      };
      if (stripe && cardElement) {
        const { data } = await axios.post(`${APIURL}payment/student-purchase`, {
          email,
          key: props.program,
        });
        const clientSecret = data.clientSecret;
        if (clientSecret) {
          const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          });

          if (paymentMethodReq.error && paymentMethodReq.error.message) {
            setCheckoutError(paymentMethodReq.error.message);
            setProcessingTo(false);
            return;
          }
          if (
            paymentMethodReq.paymentMethod &&
            paymentMethodReq.paymentMethod.id
          ) {
            const { error } = await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethodReq.paymentMethod.id,
            });
            if (error) {
              console.log(error);
              setCheckoutError(error.message ?? "");
              setProcessingTo(false);
              return;
            }
          }
        } else {
          throw new Error("No Payment ID");
        }

        onSuccessfulCheckout(email, fullName, props.closeDialog);
      }
    } catch (err: any) {
      console.log(err);
      setCheckoutError(err.message ?? "");
      setProcessingTo(false);
    }
  };
  return (
    <div className={classes.root}>
      <Typography variant="caption">Enter Email:</Typography>
      <br />

      <TextField
        InputProps={{ disableUnderline: true }}
        style={{ margin: "5px", width: "300px" }}
        id="email"
        type="email"
        label=""
        value={email}
        placeholder="JonnyChopper@pilot.com"
        required
        error={formError.email}
        onBlur={(e) =>
          setFormError(
            email
              ? { ...formError, email: false }
              : { ...formError, email: true }
          )
        }
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <Typography variant="caption">Enter Full Name:</Typography>
      <br />
      <TextField
        InputProps={{ disableUnderline: true }}
        style={{ margin: "5px", width: "300px" }}
        id="name"
        type="input"
        label=""
        value={fullName}
        placeholder="Jon Chopper"
        required
        onBlur={(e) =>
          setFormError(
            fullName
              ? { ...formError, name: false }
              : { ...formError, name: true }
          )
        }
        error={formError.name}
        onChange={(e) => setFullName(e.target.value)}
      />

      <div>
        <Typography variant="caption">Credit Card Number</Typography>
        <CardNumberElement
          options={cardElementOpts}
          onChange={(e) => {
            if (e.complete) {
              setFormStatus({ ...formStatus, card: true });
            }
          }}
        />
        <Typography variant="caption">Enter Card Expiration</Typography>
        <CardExpiryElement
          options={cardElementOpts}
          onChange={(e) => {
            if (e.complete) {
              setFormStatus({ ...formStatus, expiration: true });
            }
          }}
        />
        <Typography variant="caption">Enter CVC Number</Typography>
        <CardCvcElement
          options={cardElementOpts}
          onChange={(e) => {
            if (e.complete) {
              setFormStatus({ ...formStatus, cvc: true });
            }
          }}
        />

        {/* <CardElement
          options={cardElementOpts}
          onChange={handleCardDetailsChange}
        /> */}
      </div>
      {/* <CardNumberElement
        options={cardElementOpts}
        onChange={handleCardDetailsChange}
      /> */}

      {/* TIP always disable your submit button while processing payments */}
      <ClickButton
        clickFunction={handleFormSubmit}
        disabled={isProcessing || !stripe}
        title={isProcessing ? "Processing..." : `Pay $${price}`}
      />
      {checkoutError ? <p style={{ color: "red" }}>{checkoutError}</p> : null}
    </div>
  );
};

export default CheckOutForm;

async function onSuccessfulCheckout(
  email: string,
  fullName: string,
  closeDialog: Function
) {
  const body = {
    Username: email.toLocaleLowerCase(),
    Name: fullName.toUpperCase(),
    Email: email.toLowerCase(),
  };
  const { data } = await axios.post(`${APIURL}payment/confirm`, { data: body });

  console.log(data);
  alert(data.message);
  closeDialog();
}
