import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FltInputField } from "../../Inputs/InputFields";
import { APIURL } from "../../../Helpers/environment";
import SimpleSelect from "../../Inputs/SelectOptions";
import Alert from "@material-ui/lab/Alert";

import {
  RankNames,
  FAC,
  Months,
  Aircraft,
  RLStatus,
  Endpoints,
} from "../../SystemSettings/SystemSettings";
import {
  PersonalJSON,
  BodyAviatorPositionCreate,
  DutyPositions,
  UpdatingPersonalRecord,
  ITrackingPreferences,
} from "../../SystemSettings/ProjectInterfaces";
import {
  RenameKey,
  FormatDate,
  GetRecords,
  CreateRecord,
  UpdateRecord,
  isJwtExpired,
} from "../../SystemSettings/CommonLib";
import DatePicker from "../../Inputs/DateFields";
import DutyTable from "./DutyTable";
import ClickButton from "../../Inputs/PageButtons";
import PasswordDrawer from "./PasswordSection";
import CheckBoxField from "../../Inputs/CheckBoxField";
import { isUAVAircraft } from "../../../constants/aircraft-list";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface NeoPersonalProps {
  token: string;
  setToken: any;
}

export default function NeoPersonal(props: NeoPersonalProps) {
  // const [fullName, setFullName] = React.useState<string>('Jerome Flaherty');
  const [dutyPositions, setDutyPositions] = useState<DutyPositions[]>([]); // Displaying for Table
  const [dutyPositionsList, setDutyPositionsList] = useState([]); // DutyPosition Dropdown Menu
  const [selectDutyPosition, setSelectDutyPosition] = useState(0); // Selection of the DutyPosition
  const [showAlertDutyAdd, setShowAlertDutyAdd] = useState(false); // Alert showing on the duty section
  const [showAlertSaved, setshowAlertSaved] = useState(false); // Alert for saving data

  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rank, setRank] = useState("");
  const [fac, setFac] = useState("");
  const [birthMonth, setbirthMonth] = useState("");
  const [airframe, setAirframe] = useState(0);
  const [readinessStatus, setReadinessStatus] = useState("");
  const [weather, setWeather] = useState("");
  const [night, setNight] = useState("");
  const [nvg, setNvg] = useState("");
  const [nvs, setNvs] = useState("");
  const [nvdTotal, setNvdTotal] = useState("");
  const [frontSeat, setFrontSeat] = useState("");
  const [backSeat, setBackSeat] = useState("");
  const [semiAnnStart, setSemiAnnStart] = useState("");
  const [semiAnnEnd, setSemiAnnEnd] = useState("");
  const [lctFront, setLctFront] = useState("");
  const [lctBack, setLctBack] = useState("");
  const [lctTotals, setLctTotals] = useState("");
  const [semiMins, setSemiMins] = useState("");
  const [trackingPreferences, setTrackingPreferences] =
    useState<ITrackingPreferences>({
      cbrn: { reportStatus: false, currencyRequirement: 90 },
      hoist: { reportStatus: false, currencyRequirement: 90 },
      cefs: { reportStatus: false, currencyRequirement: 90 },
    });

  const [uasAcOperatorHoursRequired, setUasAcOperatorHoursRequired] =
    useState("");
  const [uasPayloadOperatorHoursRequired, setUasPayloadOperatorHoursRequired] =
    useState("");
  const [uasHoursRequiredBothStations, setUasHoursRequiredBothStations] =
    useState("");
  const [uasSimHoursRequiredBothStations, setUasSimHoursRequiredBothStations] =
    useState("");
  const [
    uasSimPayloadOperatorHoursRequired,
    setUasSimPayloadOperatorHoursRequired,
  ] = useState("");
  const [uasSimAcOperatorHoursRequired, setUasSimAcOperatorHoursRequired] =
    useState("");

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem("dutypositions", JSON.stringify(dutyPositions));
  }, [dutyPositions]);
  useEffect(() => {
    const data: UpdatingPersonalRecord = {
      data: {
        name: name,
        email: email,
        rank: rank,
        FAC: fac,
        birthMonth: birthMonth,
        primary_ac: airframe,
        RL: readinessStatus,
        HoodMin: weather,
        NightMin: night,
        NVGHRS: nvg,
        NVSTOTALHRS: nvs,
        PrimarySeatMin: backSeat,
        AlternateSeatMin: frontSeat,
        NVSHRS: nvs,
        SemiAnnStart: semiAnnStart,
        SemiAnnEnd: semiAnnEnd,
        LCTBack: lctBack,
        LCTFront: lctFront,
        LCTtotals: lctTotals,
        SemiMins: semiMins,
        uas_ac_operator_hours_required: uasAcOperatorHoursRequired,
        uas_payload_operator_hours_required: uasPayloadOperatorHoursRequired,
        uas_hours_required_both_stations: uasHoursRequiredBothStations,
        uas_sim_hours_required_both_stations: uasSimHoursRequiredBothStations,
        uas_sim_payload_operator_hours_required:
          uasSimPayloadOperatorHoursRequired,
        uas_sim_ac_operator_hours_required: uasSimAcOperatorHoursRequired,

        tracking_preferences: JSON.stringify(trackingPreferences),
      },
    };
    localStorage.setItem("personal", JSON.stringify(data.data));
  }, [
    name,
    email,
    rank,
    fac,
    birthMonth,
    airframe,
    readinessStatus,
    weather,
    night,
    nvg,
    nvs,
    backSeat,
    frontSeat,
    semiAnnStart,
    semiAnnEnd,
    lctBack,
    lctFront,
    lctTotals,
    semiMins,
    trackingPreferences,
  ]);
  function getCurrentData() {
    GetRecords(
      Endpoints.tables.personalProfile.getRecord,
      props.token,
      setData,
      props.setToken
    );
  }

  function setData(json: any) {
    setCurrentData(json[0]);
  }

  function getDutyPositionTableInfo() {
    GetRecords(
      Endpoints.lists.dutyTable,
      props.token,
      checkLength,
      props.setToken
    );
    function checkLength(data: any) {
      if (data.length > 0) {
        setDutyPositions(data);
      } else if (data.length == 0) {
        setDutyPositions(data);
        setOpen(true);
      }
    }
  }

  function populateDutyPositionList() {
    GetRecords(
      Endpoints.lists.dutyPositionList,
      props.token,
      setDutyPositionsList,
      props.setToken
    );
  }
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function saveUpdateProfile() {
    const data: UpdatingPersonalRecord = {
      data: {
        name: name,
        email: email,
        rank: rank,
        FAC: fac,
        birthMonth: birthMonth,
        primary_ac: airframe,
        RL: readinessStatus,
        HoodMin: weather,
        NightMin: night,
        NVGHRS: nvg,
        NVSTOTALHRS: nvdTotal,
        PrimarySeatMin: backSeat,
        AlternateSeatMin: frontSeat,
        NVSHRS: nvs,
        SemiAnnStart: semiAnnStart,
        SemiAnnEnd: semiAnnEnd,
        LCTBack: lctBack,
        LCTFront: lctFront,
        LCTtotals: lctTotals,
        SemiMins: semiMins,
        uas_ac_operator_hours_required: uasAcOperatorHoursRequired,
        uas_payload_operator_hours_required: uasPayloadOperatorHoursRequired,
        uas_hours_required_both_stations: uasHoursRequiredBothStations,
        uas_sim_hours_required_both_stations: uasSimHoursRequiredBothStations,
        uas_sim_payload_operator_hours_required:
          uasSimPayloadOperatorHoursRequired,
        uas_sim_ac_operator_hours_required: uasSimAcOperatorHoursRequired,
        tracking_preferences: JSON.stringify(trackingPreferences),
      },
    };

    UpdateRecord(
      Endpoints.tables.personalProfile.update,
      props.token,
      data,
      savedAlert
    );
    localStorage.setItem("personal", JSON.stringify(data.data));
  }

  function savedAlert() {
    setshowAlertSaved(true);
    setTimeout(() => setshowAlertSaved(false), 5000);
  }

  function addDutyPostion() {
    if (selectDutyPosition < 0) {
      return;
    }
    const alreadyExists = dutyPositions.filter((position: DutyPositions) => {
      return position.originalDutyId == selectDutyPosition;
    });
    if (alreadyExists.length > 0) {
      setShowAlertDutyAdd(true);
      setTimeout(() => setShowAlertDutyAdd(false), 5000);
      return;
    }

    const data: BodyAviatorPositionCreate = {
      data: {
        duty_id: selectDutyPosition,
      },
    };

    CreateRecord(
      Endpoints.tables.aviationDutyPositions.createRecord,
      props.token,
      data,
      getDutyPositionTableInfo
    );
  }

  useEffect(() => {
    if (!isJwtExpired(props.token)) {
      getCurrentData();
      getDutyPositionTableInfo();
      populateDutyPositionList();
    } else {
      props.setToken("");
    }
  }, []);

  function displayApacheSpecificMaterial() {
    if (airframe == 1) {
      return (
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h6">Apache Specific Content</Typography>
          <p className={classes.paragraph}>
            This is apache helicopter specific data that is needed for
            Semi-Annual calculations. Please prorate these values.
          </p>
          <FltInputField
            fieldName="Aircraft Backseat"
            valueSetter={setFrontSeat}
            currentValue={frontSeat}
          />
          <FltInputField
            fieldName="Aircraft Frontseat"
            valueSetter={setBackSeat}
            currentValue={backSeat}
          />
          <FltInputField
            fieldName="Simulator Backseat"
            valueSetter={setLctBack}
            currentValue={lctBack}
          />
          <FltInputField
            fieldName="Simulator Frontseat"
            valueSetter={setLctFront}
            currentValue={lctFront}
          />

          <FltInputField
            fieldName="NVS"
            valueSetter={setNvs}
            currentValue={nvs}
          />
          <FltInputField
            fieldName="NVG"
            valueSetter={setNvg}
            currentValue={nvg}
          />
          <FltInputField
            fieldName="NVD Total"
            valueSetter={setNvdTotal}
            currentValue={nvdTotal}
          />
        </Paper>
      );
    }
  }

  function displayAlerts() {
    if (airframe == 0 || dutyPositions.length == 0) {
      return (
        <Alert severity="error">
          Fields required to fill out.
          <ul>
            {airframe == 0 ? <li>Primary Aircraft</li> : <></>}
            {dutyPositions.length == 0 ? <li>Duty Positions</li> : <></>}
          </ul>
          {isLoading ? <CircularProgress color="primary" /> : <></>}
        </Alert>
      );
    }
  }

  function setCurrentData(data: PersonalJSON) {
    const {
      name,
      email,
      rank,
      FAC,
      birthMonth,
      primary_ac,
      RL,
      TotalHours,
      HoodMin,
      NightMin,
      NVGHRS,
      NVSTOTALHRS,
      PrimarySeatMin,
      AlternateSeatMin,
      NVSHRS,
      SemiAnnStart,
      SemiAnnEnd,
      LCTBack,
      LCTFront,
      LCTtotals,
      SemiMins,
      uas_ac_operator_hours_required,
      uas_hours_required_both_stations,
      uas_payload_operator_hours_required,
      uas_sim_ac_operator_hours_required,
      uas_sim_hours_required_both_stations,
      uas_sim_payload_operator_hours_required,
    } = data;

    setName(name);
    setEmail(email);
    setRank(rank);
    setFac(FAC);
    setbirthMonth(birthMonth);
    setAirframe(primary_ac);
    setReadinessStatus(RL);
    setWeather(HoodMin);
    setNight(NightMin);
    setNvg(NVGHRS);
    setNvs(NVSHRS);
    setNvdTotal(NVSTOTALHRS);
    setFrontSeat(AlternateSeatMin);
    setBackSeat(PrimarySeatMin);
    setSemiAnnStart(FormatDate(SemiAnnStart));
    setSemiAnnEnd(FormatDate(SemiAnnEnd));
    setLctBack(LCTBack);
    setLctFront(LCTFront);
    setLctTotals(LCTtotals);
    setSemiMins(SemiMins);

    setUasAcOperatorHoursRequired(uas_ac_operator_hours_required);
    setUasHoursRequiredBothStations(uas_hours_required_both_stations);
    setUasPayloadOperatorHoursRequired(uas_payload_operator_hours_required);
    setUasSimAcOperatorHoursRequired(uas_sim_ac_operator_hours_required);
    setUasSimHoursRequiredBothStations(uas_sim_hours_required_both_stations);
    setUasSimPayloadOperatorHoursRequired(
      uas_sim_payload_operator_hours_required
    );

    setIsLoading(false);
    if (data.tracking_preferences) {
      setTrackingPreferences((prevState) => ({
        ...prevState,
        ...JSON.parse(data.tracking_preferences),
      }));
    }
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            {displayAlerts()}
            <Typography variant="h6">{name.toUpperCase()} </Typography>
            <p className={classes.paragraph}>
              This is your personal profile page. Here you can edit some of your
              basic information about you logbook. Please verify all the
              information on this page and click the update button.
            </p>
            <FltInputField
              fieldName="Email"
              valueSetter={setEmail}
              currentValue={email}
              fullWidth={true}
            />
            <SimpleSelect
              optionSets={RankNames}
              setOptionValue={setRank}
              optionValue={rank}
              labelTitle={"Rank"}
            />
            <SimpleSelect
              optionSets={FAC}
              setOptionValue={setFac}
              optionValue={fac}
              labelTitle={"FAC Level"}
            />
            <SimpleSelect
              optionSets={Months}
              setOptionValue={setbirthMonth}
              optionValue={birthMonth}
              labelTitle={"Birth Month"}
            />
            <SimpleSelect
              optionSets={Aircraft.map((res) =>
                RenameKey(res, "airframe_name", "text")
              )}
              setOptionValue={setAirframe}
              optionValue={airframe}
              labelTitle={"Primary Aircraft"}
            />
            <SimpleSelect
              optionSets={RLStatus}
              setOptionValue={setReadinessStatus}
              optionValue={readinessStatus}
              labelTitle={"RL Status"}
            />
            <ClickButton
              clickFunction={() => {
                saveUpdateProfile();
              }}
              title="Save/Update Data"
            />{" "}
            <PasswordDrawer token={props.token} />
            {showAlertSaved ? <Alert severity="error">Saved</Alert> : <></>}
          </Paper>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6">
                Semi-annual Start and End Dates
              </Typography>
              <p className={classes.paragraph}>
                This is where you will enter the date range that you would like
                the logbook to start keeping track of your current semi-annual
                period. If you are a new aviator to the unit you will most
                likely be your RL1 start date.
              </p>
              <DatePicker
                title="Start Date"
                dateValue={semiAnnStart}
                setDateValue={setSemiAnnStart}
              />
              <DatePicker
                title="End Date"
                dateValue={semiAnnEnd}
                setDateValue={setSemiAnnEnd}
              />
              <ClickButton
                title="Clear Dates"
                clickFunction={() => {
                  setSemiAnnStart("");
                  setSemiAnnEnd("");
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            {/* Extra Reporting */}
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6">Extra Currency Reporting</Typography>
              <p className={classes.paragraph}>
                Checkmark the boxes below to add extra currency reporting to
                your logbook. We will report to you the last time you completed
                these tasks. And how many days do you have to complete them
                again before you go non-current.
              </p>
              {/* cbrn */}
              <div style={{ display: "flex", gap: "20px" }}>
                <CheckBoxField
                  name="Chemical, Biological, Radiological, and Nuclear (CBRN)"
                  currentValue={trackingPreferences.cbrn?.reportStatus}
                  valueSetter={(value: boolean) => {
                    console.log(value);
                    setTrackingPreferences({
                      ...trackingPreferences,
                      cbrn: {
                        ...trackingPreferences.cbrn,
                        reportStatus: value,
                      },
                    });
                  }}
                />
                {trackingPreferences.cbrn?.reportStatus && (
                  <FltInputField
                    fieldName="Currency Requirement (Days)"
                    onBlur={() => {
                      if (trackingPreferences.cbrn.currencyRequirement == "") {
                        setTrackingPreferences({
                          ...trackingPreferences,
                          cbrn: {
                            ...trackingPreferences.cbrn,
                            currencyRequirement: 0,
                          },
                        });
                      }
                    }}
                    valueSetter={(value: string) => {
                      setTrackingPreferences({
                        ...trackingPreferences,
                        cbrn: {
                          ...trackingPreferences.cbrn,
                          currencyRequirement: value ? parseInt(value) : "",
                        },
                      });
                    }}
                    currentValue={trackingPreferences.cbrn.currencyRequirement}
                  />
                )}
              </div>

              {/* Hoisting */}
              <div style={{ display: "flex", gap: "20px" }}>
                <CheckBoxField
                  name="Hoist"
                  currentValue={trackingPreferences.hoist?.reportStatus}
                  valueSetter={(value: boolean) => {
                    setTrackingPreferences({
                      ...trackingPreferences,
                      hoist: {
                        ...trackingPreferences.hoist,
                        reportStatus: value,
                      },
                    });
                  }}
                />
                {trackingPreferences.hoist?.reportStatus && (
                  <FltInputField
                    fieldName="Hoist Currency Requirement (Days)"
                    onBlur={() => {
                      if (trackingPreferences.hoist.currencyRequirement == "") {
                        setTrackingPreferences({
                          ...trackingPreferences,
                          hoist: {
                            ...trackingPreferences.hoist,
                            currencyRequirement: 0,
                          },
                        });
                      }
                    }}
                    valueSetter={(value: string) => {
                      setTrackingPreferences({
                        ...trackingPreferences,
                        hoist: {
                          ...trackingPreferences.hoist,
                          currencyRequirement: value ? parseInt(value) : "",
                        },
                      });
                    }}
                    currentValue={trackingPreferences.hoist.currencyRequirement}
                  />
                )}
              </div>

              {/* CEFs */}
              <div style={{ display: "flex", gap: "20px" }}>
                <CheckBoxField
                  name="Extended Fuel System (CEFS)"
                  currentValue={trackingPreferences.cefs?.reportStatus}
                  valueSetter={(value: boolean) => {
                    setTrackingPreferences({
                      ...trackingPreferences,
                      cefs: {
                        ...trackingPreferences.cefs,
                        reportStatus: value,
                      },
                    });
                  }}
                />
                {trackingPreferences.cefs?.reportStatus && (
                  <FltInputField
                    fieldName="CEFs Currency Requirement (Days)"
                    valueSetter={(value: string) => {
                      setTrackingPreferences({
                        ...trackingPreferences,
                        cefs: {
                          ...trackingPreferences.cefs,
                          currencyRequirement: value ? parseInt(value) : "",
                        },
                      });
                    }}
                    currentValue={trackingPreferences.cefs.currencyRequirement}
                  />
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Airframe / Simulator Mins</Typography>
            <p className={classes.paragraph}>
              Enter the total hours needed during your current semiannual period
              (to include prorations).
            </p>
            <FltInputField
              fieldName="Aircraft Total"
              valueSetter={setSemiMins}
              currentValue={semiMins}
            />
            <FltInputField
              fieldName="Hood/Wx"
              valueSetter={setWeather}
              currentValue={weather}
            />
            <FltInputField
              fieldName="Night"
              valueSetter={setNight}
              currentValue={night}
            />
            <FltInputField
              fieldName="NVG"
              valueSetter={setNvg}
              currentValue={nvg}
            />
            <FltInputField
              fieldName="Simulator Totals"
              valueSetter={setLctTotals}
              currentValue={lctTotals}
            />

            {/* UAS Fields Only */}
            {isUAVAircraft(airframe) && (
              <>
                <FltInputField
                  fieldName="UAS Operator Hours Required"
                  valueSetter={setUasAcOperatorHoursRequired}
                  currentValue={uasAcOperatorHoursRequired}
                />

                <FltInputField
                  fieldName="UAS Payload Hours Required"
                  valueSetter={setUasPayloadOperatorHoursRequired}
                  currentValue={uasPayloadOperatorHoursRequired}
                />

                <FltInputField
                  fieldName="UAS Hours Required (Both Stations)"
                  valueSetter={setUasHoursRequiredBothStations}
                  currentValue={uasHoursRequiredBothStations}
                />

                <FltInputField
                  fieldName="UAS SIM Hours Required (Both Stations)"
                  currentValue={uasSimHoursRequiredBothStations}
                  valueSetter={setUasSimHoursRequiredBothStations}
                />
                <FltInputField
                  fieldName="UAS SIM Payload Hours Required"
                  currentValue={uasSimPayloadOperatorHoursRequired}
                  valueSetter={setUasSimPayloadOperatorHoursRequired}
                />
                <FltInputField
                  fieldName="UAS SIM Aircraft Operator Hours Required"
                  currentValue={uasSimAcOperatorHoursRequired}
                  valueSetter={setUasSimAcOperatorHoursRequired}
                />
              </>
            )}
          </Paper>
          <Grid item xs={12} sm={12}>
            {displayApacheSpecificMaterial()}
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6">
                Select the Duty Positons You Fly
              </Typography>
              <p className={classes.paragraph}>
                By selecting your duty positions you will be able to custom
                select them when logging flights. There are many positions and
                this will help filter your menu.
              </p>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <SimpleSelect
                    optionSets={dutyPositionsList}
                    setOptionValue={setSelectDutyPosition}
                    optionValue={selectDutyPosition}
                    labelTitle={"Select Duty Position to Add"}
                  />
                  <ClickButton
                    title="Add"
                    clickFunction={() => {
                      addDutyPostion();
                    }}
                  />
                  {showAlertDutyAdd ? (
                    <Alert severity="error">
                      Already added to your personal list
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <DutyTable
                    token={props.token}
                    dutyPositions={dutyPositions}
                    refreshDutyPositions={getDutyPositionTableInfo}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="warning" variant="filled">
          Please Update Duty Positions You Fly Most
        </Alert>
      </Snackbar>
    </div>
  );
}
