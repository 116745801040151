import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

import FlightPage from "./FlightLogbook/FlightPage";
import SimulatorPage from "./SimulatorLogs/SimulatorPage";

import DropdownTest from "./DropdownTest";
import NeoPersonal from "./PersonalData/NeoPersonalPage";
import { Paper } from "@material-ui/core";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SemiAnnualTotals from "./AnnualReports/SemiAnnual";
import NeoTotalPage from "./TotalsPage/NeoTotalPage";
import FlightToggle from "./FlightToggle";
import CompanyAdmin from "./CompanyAdmin/CompanyAdmin";

interface MainProps {
  getCookie: any;
  setCookie: any;
  checkCookie: any;
  token: any;
  setToken: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    borderRadius: "0",
  },
  // tab: {
  //   position: "sticky",
  //   // position: "-webkit-sticky",
  //   bottom: "0"
  // }
}));

export default function ScrollableTabsButtonAuto(props: MainProps) {
  const classes = useStyles();
  // change this to affect the initial tab you see
  const [value, setValue] = React.useState<number>(0);

  return (
    <Router>
      <Switch>
        <Route exact path="/login/profile">
          <Paper className={classes.paper}>
            <NeoPersonal token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>

        <Route exact path="/login/flight">
          <Paper className={classes.paper}>
            <FlightToggle token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
        <Route exact path="/login/primary-aircraft-logbook">
          <Paper className={classes.paper}>
            <FlightPage token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
        <Route exact path="/login/primary-simulator-logbook">
          <Paper className={classes.paper}>
            <SimulatorPage token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
        <Route exact path="/login/dev">
          <Paper className={classes.paper}>
            <DropdownTest token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
        <Route exact path="/login/totals">
          <Paper className={classes.paper}>
            <NeoTotalPage token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
        <Route exact path="/login/semiannual">
          <Paper className={classes.paper}>
            <SemiAnnualTotals token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>

        <Route exact path="/login">
          <Paper className={classes.paper}>
            <NeoPersonal token={props.token} setToken={props.setToken} />
          </Paper>
        </Route>
      </Switch>
      {/* <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className="tab"
      >
        <BottomNavigationAction
          onClick={() => setValue(0)}
          component={Link}
          title="personal"
          to="/login/profile"
          label="Profile"
          icon={<FaceIcon />}
        />
        <BottomNavigationAction
          onClick={() => setValue(1)}
          component={Link}
          title="Primary Flights"
          to="/flight"
          label="Flights"
          icon={<FlightIcon />}
        />
        <BottomNavigationAction
          onClick={() => setValue(2)}
          component={Link}
          title="Simulator Flights"
          to="/login/primary-simulator-logbook"
          label="Simulations"
          icon={<FlightTakeoffIcon />}
        />
        <BottomNavigationAction
          onClick={() => setValue(3)}
          component={Link}
          title="SemiAnnual"
          to="/login/semiannual"
          label="Semi Annual"
          icon={<Speed />}
        />
        <Hidden only={["sm", "xs"]}>
          <BottomNavigationAction
            onClick={() => setValue(4)}
            component={Link}
            title="totals"
            to="/login/totals"
            label="Totals"
            icon={<EqualizerIcon />}
          />
        </Hidden>
      </BottomNavigation> */}
    </Router>
  );
}
