import React, { useState, useEffect } from "react";
import Hidden from "@material-ui/core/Hidden";

import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Fab,
} from "@material-ui/core";
import FlightTable from "./FlightTableView";
import {
  GetRecords,
  LocalStorageInformation,
  PostRequest,
} from "../../SystemSettings/CommonLib";
import { Endpoints, Aircraft } from "../../SystemSettings/SystemSettings";
import ClickButton from "../../Inputs/PageButtons";
import DatePicker from "../../Inputs/DateFields";
import {
  PersonalJSON,
  IFlightSearchDateRequest,
  IFlight,
} from "../../SystemSettings/ProjectInterfaces";
import AddFlightLogModal from "./AddFlightLogModal";
import AddIcon from "@material-ui/icons/Add";
import FlightCards from "./FlightCards";
import PieChart from "../../Inputs/PieChart";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDownloader from "../FlightLogbook/PDFDownloader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);
export interface FlightPageProps {
  token: string;
  setToken: any;
}

export default function ArmyFlightPage(props: FlightPageProps) {
  const chartInfo = `Data represented in the chart is gathered from your search query (get
    all flights, get current semiannual, or search based on date)`;
  const classes = useStyles();
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [endSearchDate, setEndSearchDate] = useState("");
  const [flightData, setFlightData] = useState([]);
  const [isEditFlight, setisEditFlight] = useState(false);
  const [personalProfile, setPersonalProfile] = useState<PersonalJSON>();
  const [showAddFlightModel, setShowAddFlightModel] = useState(false);
  const [startSearchDate, setStartSearchDate] = useState("");
  const [totalTimeData, setTotalTimeData] = useState<any>();
  const [resetTablePageNumber, setResetTablePageNumber] = useState(false);

  useEffect(() => {
    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile informaiton in the local stroage
    if (flightPageInfo.personalProfile) {
      setData(JSON.parse(flightPageInfo.personalProfile));
    } else {
      flightPageInfo.updatingPersonalProfile(setData, props.setToken);
    }
    function setData(json: any) {
      setPersonalProfile(json);
    }
    getAllFlights();
  }, []);
  // Pie Chart Data Creator - runs every time flightdata changes in the table.
  useEffect(() => {
    if (!flightData) {
      return;
    }
    let totalDayHours = 0;
    let totalNightHours = 0;
    let totalNvgHours = 0;
    let totalNvsHours = 0;
    let totalHoodHours = 0;
    let totalWeatherHours = 0;

    flightData.map((record: IFlight) => {
      totalDayHours += +record.fltDay;
      totalNightHours += +record.fltNight;
      totalNvgHours += +record.NVGflt;
      totalNvsHours += +record.fltNVS;
      totalHoodHours += +record.fltHood;
      totalWeatherHours += +record.fltweather;
    });
    function fixRounding(number: number) {
      return Math.floor(number * 10) / 10;
    }
    console.log(totalDayHours);
    setTotalTimeData({
      Day: fixRounding(totalDayHours),
      Night: fixRounding(totalNightHours),
      NVG: fixRounding(totalNvgHours),
      NVS: fixRounding(totalNvsHours),
      Hood: fixRounding(totalHoodHours),
      Wx: fixRounding(totalWeatherHours),
    });
  }, [flightData]);
  // Fetching all Flights

  function getAllFlights() {
    setIsDownloadReady(false);
    GetRecords(
      Endpoints.tables.armyLogbook.getall,
      props.token,
      setFlightData,
      props.setToken
    );
    setResetTablePageNumber(true);
  }
  //Fetching based on start and end date
  function dateSearchFilter() {
    if (!startSearchDate && !endSearchDate) {
      return;
    }
    setIsDownloadReady(false);

    const data: IFlightSearchDateRequest = {
      data: {
        startDate: startSearchDate,
        endDate: endSearchDate,
      },
    };
    PostRequest(
      Endpoints.tables.armyLogbook.searchdate,
      props.token,
      data,
      setFlightData
    );
    setResetTablePageNumber(true);
  }
  // Current Semiannual Period
  // function getCurrentSemiAnnual() {
  //   GetRecords(
  //     Endpoints.tables.flightLog.getcurrent,
  //     props.token,
  //     setFlightData,
  //     props.setToken
  //   );
  //   setResetTablePageNumber(true);
  // }

  const refreshTableGrid = {
    allFlights: getAllFlights,
  };

  function closeAddFlightModel() {
    setShowAddFlightModel(false);
  }

  function openAddFlightModel() {
    setShowAddFlightModel(true);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12}>
          <Typography variant="h4" style={{ padding: "15px" }}>
            FlightLogbook: All Military Airframes
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Filter Options </Typography>

            <p className={classes.paragraph}>
              Choose Dates yourself or choose the presets buttons. Easily swap
              between Current Semiannual or all flights.
            </p>
            <ClickButton
              title="Get All Flights"
              clickFunction={() => getAllFlights()}
              fullWidth={true}
            />

            <p className={classes.paragraph}>
              Choose a Start Date and an End Date to assist with your search.
            </p>
            <DatePicker
              dateValue={startSearchDate}
              title="Start Date"
              setDateValue={setStartSearchDate}
            />
            <DatePicker
              dateValue={endSearchDate}
              title="Start Date"
              setDateValue={setEndSearchDate}
            />
            <ClickButton
              title="Search Based On Date"
              clickFunction={() => dateSearchFilter()}
              fullWidth={true}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6"> Flight Data </Typography>
            {totalTimeData ? (
              <div className="chart-container">
                <PieChart dataToDisplay={totalTimeData} chartInfo={chartInfo} />
              </div>
            ) : (
              <></>
            )}
            <p>{chartInfo}</p>
          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          <Paper className={classes.paper} elevation={3}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Fab size="small" color="secondary" aria-label="add">
                <AddIcon onClick={() => openAddFlightModel()} />
              </Fab>
            </Grid>
            <Typography variant="h6">Flight Logbook </Typography>

            <p className={classes.paragraph}>
              This is the FlightLogbook section where you can add, view, update,
              or delete your flights. Click the view more details about the
              flight.
            </p>
            <Hidden only={["sm", "xs"]}>
              <FlightTable
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                resetTablePageNumber={resetTablePageNumber}
                setResetTablePageNumber={setResetTablePageNumber}
                setToken={props.setToken}
              />
              {isDownloadReady ? (
                <PDFDownloadLink
                  document={<PDFDownloader flightData={flightData} />}
                  fileName="ArmyFlightLogbook-Report.pdf"
                  style={{ textAlign: "center" }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download now!"
                  }
                </PDFDownloadLink>
              ) : (
                <ClickButton
                  clickFunction={() => setIsDownloadReady(true)}
                  title={"Generate PDF"}
                  fullWidth={true}
                />
              )}
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <FlightCards
                token={props.token}
                flightData={flightData}
                refreshTableGrid={refreshTableGrid}
                openFlightModel={openAddFlightModel}
                setFlightData={setFlightData}
                setToken={props.setToken}
              />
            </Hidden>
          </Paper>
        </Grid>
      </Grid>
      <AddFlightLogModal
        token={props.token}
        modelOpen={showAddFlightModel}
        setModelOpen={closeAddFlightModel}
        isEditFlight={false}
        setToken={props.setToken}
        setFlightData={setFlightData}
      />
    </div>
  );
  //find a way to make this go back to the page that they were on when they clicked it,
  //possibly by routing it through a prop that is made on the main page or smth
}

// firebase serve --only hosting
