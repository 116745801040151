import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import PrimaryPhoto from "./images/filter-options.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paragraph: {
      textAlign: "left",
      padding: 0,
    },
    picture: {
      maxWidth: "415px",
    },
    list: {
      width: "100%",
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function FilteringFlights() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Filtering Flights</Typography>

      <p className={classes.paragraph}>
        We created several buttons to give you quick access to filtering flights
        based on date. By default when you enter the flight or simulator page
        you will be getting all flights from the beginning of your logging to
        the end.
      </p>
      <Grid container xs={12} spacing={3}>
        <Photo photo={PrimaryPhoto} />
        <Grid item xs={12} md={6}>
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                primary="1. Get All Flights"
                secondary="When the page first loads you are essentially grabbing all the flights in your logbook. This preset button is useful if you were checking semiannual periods and needed to go back to your entire logbook."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="2. Get Current Semiannual"
                secondary="Clicking this button will populate your flight logbook with flights that have been flown inbetween your start and end dates of your semiannual period. These dates must be filled on on the profile section of your logbook."
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Search Based on Dates"
                secondary="You will need to input a start and end date in the proper boxes. Once you click the button Search Based On Date your logbook will update with the flights that occurred between those dates."
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Notes</Typography>

          <p className={classes.paragraph}>
            Get Current Semiannual button will only work if you have let the
            flight logbook know when your semiannual start and end dates. You
            can find that information in the Profile page.
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Photo = (props: { photo: string }) => {
  const { photo } = props;
  return <img src={photo} alt="" width="350px" />;
};
