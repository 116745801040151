import React from "react";
import {
  IFlight,
  ITrackingPreferences,
} from "../../../SystemSettings/ProjectInterfaces";
import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { getLatestFlights } from "./getLatestFlights";

export interface IExtraReportingProps {
  trackingPreferences: ITrackingPreferences;
  flightRecords: IFlight[];
  pageSource: "flight" | "simulator";
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
    },
  })
);
const ExtraReportingSection = (props: IExtraReportingProps) => {
  const classes = useStyles();

  const { trackingPreferences, flightRecords, pageSource } = props;

  const customTracking = getLatestFlights(
    flightRecords,
    trackingPreferences,
    pageSource
  );

  return (
    <div className={classes.root}>
      <Typography variant="h6"> Extra Reporting Currency </Typography>
      <p>
        This section here is if you filled out profile preferences on extra
        tracking that you would like your logbook to perform.
      </p>
      {customTracking.map((item, index) => (
        <p key={index}>{item.message}</p>
      ))}
    </div>
  );
};

export default ExtraReportingSection;
